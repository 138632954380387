import React, { useState, useEffect } from 'react';
import { ChevronDown, ChevronUp, Edit3, Save, PlusCircle, Trash2 } from 'lucide-react';
import { response } from 'express';

interface TablaDesplegableProps {
  id: string;
  titulo: string;
  datos: { [key: string]: string }[];
  nivel: number;
  onDragStart: (id: string) => void;
  onDragEnter: (id: string) => void;
  onDragEnd: () => void;
  onEliminarTabla: (id: string) => void;
}

const TablaDesplegable: React.FC<TablaDesplegableProps> = ({ id, titulo, datos, nivel, onDragStart, onDragEnter, onDragEnd, onEliminarTabla }) => {
  const [expandido, setExpandido] = useState(false);
  const [modoEdicion, setModoEdicion] = useState(false);
  const [nuevoTitulo, setNuevoTitulo] = useState(titulo);
  const [datosEditados, setDatosEditados] = useState(datos);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [nuevoConcepto, setNuevoConcepto] = useState("");
  const [nuevaNomenclatura, setNuevaNomenclatura] = useState("");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editConcept, setEditConcept] = useState("");
  const [editNomenclature, setEditNomenclature] = useState("");
  const [mensaje, setMensaje] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const handleGuardarTitulo = () => {
    fetch('http://localhost:3000/php/pages/controldocument/glosario/update_title.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ nivel_id:id, nuevoTitulo }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setModoEdicion(false);
          // Mostrar mensaje de éxito
          setSuccessMessage('Título actualizado con éxito.');
          setErrorMessage('');
          
          // Cerrar el mensaje después de 3 segundos
          setTimeout(() => setSuccessMessage(''), 3000);
        } else {
          // Mostrar mensaje de error
          setErrorMessage(`Error al actualizar el título: ${data.message}`);
          setSuccessMessage('');
        }
      })
      .catch(error => {
        console.error('Error en la solicitud:', error);
        setErrorMessage('Error en la solicitud. Por favor, inténtelo de nuevo.');
        setSuccessMessage('');
      });
  };
  


  const handleInputChange = (setter: React.Dispatch<React.SetStateAction<string>>) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setter(e.target.value);
    setMensaje(''); // Resetear el mensaje de error al cambiar el texto
  };
  

  const handleAgregarNuevo = () => {
    if (nuevoConcepto && nuevaNomenclatura) {
      // Enviar solicitud al backend para agregar el nuevo concepto
      fetch('http://localhost:3000/php/pages/controldocument/glosario/add_concept.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ nivel_id: nivel, concepto: nuevoConcepto, nomenclatura: nuevaNomenclatura }),
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
           // alert(nivel);
            // Si la solicitud fue exitosa, actualizar el estado de datosEditados
            const nuevoDato = { [`Nivel`]: nuevoConcepto, Nomenclatura: nuevaNomenclatura };
            setDatosEditados(prevDatos => [...prevDatos, nuevoDato]);
            setNuevoConcepto("");
            setNuevaNomenclatura("");
            setMensaje(`Registro insertado con éxito. ID: ${data.newId}`);
            setTimeout(() => setMensaje(''), 5000); // Eliminar el mensaje después de 5 segundos
          } else {
            // Mostrar el mensaje de error recibido del backend
            setMensaje(`Error al agregar el nuevo concepto: ${data.message}`);
          }
        })
        .catch(error => {
          // Manejar errores de red o problemas con la solicitud
          setMensaje(`Error en la solicitud: ${error.message}`);
        });
    } else {
      // Validación de campos vacíos
      setMensaje("Por favor, completa ambos campos antes de agregar.");
    }
  };
  

  const handleEliminar = (index: number) => {
  //  setDatosEditados(prevDatos => prevDatos.filter((_, i) => i !== index));
  const conceptoAEliminar = datosEditados[index];
  fetch('http://localhost:3000/php/pages/controldocument/glosario/delete_concept.php', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      nivel_id: nivel,
      concepto: conceptoAEliminar[Object.keys(conceptoAEliminar)[0]],
      nomenclatura: conceptoAEliminar.Nomenclatura
    }),
  })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        setDatosEditados(prevDatos => prevDatos.filter((_, i) => i !== index));
      } else {
        console.error('Error al eliminar el concepto:', data.message);
      }
    })
    .catch(error => console.error('Error en la solicitud:', error));
};

  
 /* const handleEditClick = (index: number) => {
    setEditIndex(index);
    const { [`Nivel`]: concepto, Nomenclatura } = datosEditados[index] || {};
    setNuevoConcepto(concepto || "");
    setNuevaNomenclatura(Nomenclatura || "");
  };*/
  const handleEditClick = (index: number) => {
    setEditIndex(index);
    setEditConcept(datosEditados[index][Object.keys(datos[0])[0]]);
    setEditNomenclature(datosEditados[index].Nomenclatura);
    setIsEditModalOpen(true);
  };
  
  const handleSaveEdit = () => {
    if (editIndex !== null) {
      const nuevosDatos = [...datosEditados];
      nuevosDatos[editIndex] = { [Object.keys(datosEditados[0])[0]]: editConcept, Nomenclatura: editNomenclature };

      fetch('http://localhost:3000/php/pages/controldocument/glosario/update_concept.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          nivel_id: nivel,
          old_concept: datosEditados[editIndex][Object.keys(datosEditados[0])[0]],
          old_nomenclature: datosEditados[editIndex].Nomenclatura,
          new_concept: editConcept,
          new_nomenclature: editNomenclature
        }),
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            setDatosEditados(nuevosDatos);
            setSuccessMessage('Concepto actualizado exitosamente');
            setTimeout(() => {
              setSuccessMessage('');
              setIsEditModalOpen(false);
              setEditIndex(null);
              setEditConcept('');
              setEditNomenclature('');
            }, 1500); // Cierra la ventana tras 3 segundos
          } else {
            setErrorMessage(data.message);
          }
        })
        .catch(error => setErrorMessage('Error en la solicitud: ' + error.message));
    }
  };
  
  return (
    <div
      className="mb-4 border rounded-lg overflow-hidden"
      onDragEnter={() => onDragEnter(id)}
      onDragOver={(e) => e.preventDefault()}
      onDrop={() => onDragEnd()}
    >
      <div
        onClick={() => setExpandido(!expandido)}
        className="w-full bg-cyan-300 text-black p-3 flex justify-between items-center hover:bg-cyan-400 transition-colors cursor-pointer"
        draggable
        onDragStart={() => onDragStart(id)}
      >
        {modoEdicion ? (
          <input
            type="text"
            value={nuevoTitulo}
            onChange={(e) => setNuevoTitulo(e.target.value)}
            className="text-xl font-bold bg-white p-2 rounded flex-grow"
            onClick={(e) => e.stopPropagation()}
          />
        ) : (
          <h2 className="text-xl font-bold flex-grow">{nuevoTitulo}</h2>
        )}
        <div className="flex items-center space-x-2">
        {modoEdicion ? (
  <>
    <button
      onClick={(e) => {
        e.stopPropagation();
        handleGuardarTitulo();
      }}
    >
      <Save size={24} />
    </button>
    <button
      onClick={(e) => {
        e.stopPropagation();
        onEliminarTabla(id);  // Call the delete function when clicked
       
      }}
    >
      <Trash2 size={24} className="text-red-500" />
    </button>
   
  </>
) : (
  <button
    onClick={(e) => {
      e.stopPropagation();
      setModoEdicion(true);
    }}
  >
    <Edit3 size={24} />
  </button>
)}

          <button
            onClick={(e) => {
              e.stopPropagation();
              setExpandido(!expandido);
            }}
          >
            {expandido ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
          </button>
          
        </div>
      </div>
      {expandido && (
        <div>
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-gray-200">
                <th className="border p-2 text-left">{`Nivel${nivel+1}`}</th>
                {datosEditados.length > 0 && Object.keys(datosEditados[0]).filter(key => key !== `Nivel`).map((key, index) => (
                  <th key={index} className="border p-2 text-left">
                    {key}
                  </th>
                ))}
                <th className="border p-2 text-left">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {datosEditados.map((fila, index) => (
                <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}>
                  {Object.keys(fila).map((header, idx) => (
                    <td key={idx} className="border p-2">
                      {fila[header]}
                    </td>
                  ))}
                  <td className="border p-2">
                    <button onClick={() => handleEditClick(index)} className="mr-2">
                      <Edit3 size={24} className="text-blue-500" />
                    </button>
                    <button onClick={() => handleEliminar(index)}>
                      <Trash2 size={24} className="text-red-500" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="p-4">
            <h3 className="text-lg font-bold mb-2">Agregar nuevo concepto</h3>
            <div className="flex space-x-2">
              <input
                type="text"
                placeholder="Nuevo concepto"
                value={nuevoConcepto}
                onChange={(e) => {
                  setNuevoConcepto(e.target.value);
                  setMensaje(''); // Limpia el mensaje de error al cambiar el texto
                }}
                className="p-2 border border-gray-300 rounded flex-grow"
              />
              <input
                type="text"
                placeholder="Nueva nomenclatura"
                value={nuevaNomenclatura}
                onChange={(e) => {
                  const input = e.target.value.toUpperCase();
              
                  // Validar la longitud del input basado en el nivel
                  if ((nivel === 1 && input.length <= 1) || 
                      (nivel === 2 && input.length <= 3) || 
                      (nivel === 3 && input.length <= 2)) {
                    setNuevaNomenclatura(input);
                  }
                  
                  setMensaje(''); // Limpia el mensaje de error al cambiar el texto
                }}
                className="p-2 border border-gray-300 rounded flex-grow"
              />
              <button
                onClick={handleAgregarNuevo}
                className="p-2 bg-green-500 text-white rounded flex items-center justify-center"
              >
                <PlusCircle size={24} />
              </button>
            </div>
              {/* Mostrar el mensaje de error o éxito */}
  {mensaje && (
    <div className={`mt-4 p-2 rounded ${mensaje.startsWith('Error') ? 'bg-red-200 text-red-800' : 'bg-green-200 text-green-800'}`}>
      {mensaje}
    </div>
  )}
  
          </div>
        </div>

            )}
               {isEditModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold mb-4">Editar Concepto</h3>
            <div className="mb-4">
              <label className="block mb-2">Concepto</label>
              <input 
                type="text" 
                value={editConcept} 
                onChange={(e) => setEditConcept(e.target.value)} 
                className="p-2 border border-gray-300 rounded w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2">Nomenclatura</label>
              <input 
                type="text" 
                value={editNomenclature} 
                onChange={(e) => setEditNomenclature(e.target.value)} 
                className="p-2 border border-gray-300 rounded w-full"
              />
            </div>
            <div className="flex flex-col items-start space-y-2">
  {/* Contenedor para los botones */}
  <div className="flex space-x-2">
    {/* Botón Guardar */}
    <button
      onClick={handleSaveEdit}
      className="bg-blue-500 text-white p-2 rounded"
    >
      Guardar
    </button>

    {/* Botón Cancelar */}
    <button
      onClick={() => {
        setIsEditModalOpen(false);
        setEditIndex(null);
        setEditConcept('');
        setEditNomenclature('');
      }}
      className="bg-gray-500 text-white p-2 rounded"
    >
      Cancelar
    </button>
  </div>

  {/* Mostrar mensajes de éxito o error justo debajo de los botones */}
  {successMessage && (
    <div className="mt-4 p-2 rounded bg-green-200 text-green-800">
      {successMessage}
    </div>
  )}
  {errorMessage && (
    <div className="mt-4 p-2 rounded bg-red-200 text-red-800">
      {errorMessage}
    </div>
  )}


            </div>
          </div>
        </div>
      )}
    </div>
  );
};
/*
const TablasNomenclaturaProyecto: React.FC = () => {
  const [tabs, setTabs] = useState([
    { id: 'subcarpetas', titulo: 'Subcarpetas', datos: [
      { Nivel: 'Terreno', Nomenclatura: 'T' },
      { Nivel: 'Arquitectura', Nomenclatura: 'A' },
      { Nivel: 'Estructuras', Nomenclatura: 'E' },
      { Nivel: 'Urbanización', Nomenclatura: 'U' },
      { Nivel: 'Ingreso DOM', Nomenclatura: 'D' },
      { Nivel: 'Ingreso SERVIU', Nomenclatura: 'S' },
      { Nivel: 'Licitación', Nomenclatura: 'L' },
      { Nivel: 'Contrato', Nomenclatura: 'C' },
      { Nivel: 'Obra', Nomenclatura: 'O' },
      { Nivel: 'Normativa', Nomenclatura: 'N' },
      { Nivel: 'Finanzas', Nomenclatura: 'F' },
      { Nivel: 'Control de Proyecto', Nomenclatura: 'P' },
    ]},
    { id: 'especialidades', titulo: 'Especialidades', datos: [
      { Nivel: 'Electricidad', Nomenclatura: 'E' },
      { Nivel: 'Fontanería', Nomenclatura: 'F' },
      { Nivel: 'Climatización', Nomenclatura: 'C' },
      { Nivel: 'Seguridad', Nomenclatura: 'S' },
    ]},
    { id: 'tipoDocumento', titulo: 'Tipo de documento', datos: [
      { Nivel: 'Planos', Nomenclatura: 'P' },
      { Nivel: 'Memoria Descriptiva', Nomenclatura: 'MD' },
      { Nivel: 'Especificaciones Técnicas', Nomenclatura: 'ET' },
      { Nivel: 'Presupuestos', Nomenclatura: 'PR' },
      // ... más datos
    ]}
  ]);

  const [draggedId, setDraggedId] = useState<string | null>(null);

  const handleDragStart = (id: string) => {
    setDraggedId(id);
  };

  const handleDragEnter = (id: string) => {
    if (draggedId && draggedId !== id) {
      const draggedIndex = tabs.findIndex(tab => tab.id === draggedId);
      const targetIndex = tabs.findIndex(tab => tab.id === id);
      const updatedTabs = [...tabs];
      [updatedTabs[draggedIndex], updatedTabs[targetIndex]] = [updatedTabs[targetIndex], updatedTabs[draggedIndex]];
      setTabs(updatedTabs);
    }
  };

  const handleDragEnd = () => {
    setDraggedId(null);
  };

  const handleEliminarTabla = (id: string) => {
    setTabs(tabs.filter(tab => tab.id !== id));  // Filtrar la tabla a eliminar
  };
  

  const handleAgregarTabla = () => {
    const nuevaTabla = {
      id: `tabla-${Date.now()}`,
      titulo: 'Nuevo Nivel',
      datos: []
    };
    setTabs([...tabs, nuevaTabla]);
  };

  return (
    <div>
      <button
        onClick={handleAgregarTabla}
        className="mb-4 p-2 bg-blue-500 text-white rounded flex items-center"
      >
        <PlusCircle size={24} className="mr-2" />
        Agregar Nivel
      </button>
      {tabs.map((tab, index) => (
        <TablaDesplegable
          key={tab.id}
          id={tab.id}
          titulo={tab.titulo}
          datos={tab.datos || []}
          nivel={index + 1}
          onDragStart={handleDragStart}
          onDragEnter={handleDragEnter}
          onDragEnd={handleDragEnd}
          onEliminarTabla={handleEliminarTabla}
        />
      ))}
    </div>
  );
};

export default TablasNomenclaturaProyecto;
*/

// TablasNomenclaturaProyecto Component
interface Tab {
  id: string;
  titulo: string;
  datos: { Nivel: string; Nomenclatura: string }[];
}

const TablasNomenclaturaProyecto: React.FC = () => {
  const [tabs, setTabs] = useState<Tab[]>([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  useEffect(() => {
    fetch('http://localhost:3000/php/pages/controldocument/glosario/get_data.php')
      .then(response => response.json())
      .then(data => setTabs(data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const [draggedId, setDraggedId] = useState<string | null>(null);

  const handleDragStart = (id: string) => {
    setDraggedId(id);
  };
  const handleDragEnter = async (id: string) => {
    if (draggedId && draggedId !== id) {
        const draggedIndex = tabs.findIndex(tab => tab.id === draggedId);
        const targetIndex = tabs.findIndex(tab => tab.id === id);

        const updatedTabs = [...tabs];
        [updatedTabs[draggedIndex], updatedTabs[targetIndex]] = [updatedTabs[targetIndex], updatedTabs[draggedIndex]];
        setTabs(updatedTabs);

        // Mostrar las posiciones finales de cada carpeta
        updatedTabs.forEach((tab, index) => {
            console.log(`Index: ${index}, Name: ${tab.titulo}`);
        });

        // Enviar la actualización al backend
        try {
            const response = await fetch('http://localhost:3000/php/pages/controldocument/glosario/update_levels.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(
                    updatedTabs.map((tab, index) => ({
                        nivel_id: index + 1, // El nuevo nivel_id basado en el índice
                        titulo: tab.titulo, // Incluir el título del tab
                    }))
                ),
            });
            const result = await response.json();
            if (result.success) {
                console.log('Base de datos actualizada con éxito');
            } else {
                console.error('Error al actualizar la base de datos:', result.message);
            }
        } catch (error) {
            console.error('Error al enviar la solicitud:', error);
        }
    }
};

  
  




  const handleDragEnd = () => {
    setDraggedId(null);
  };
  const handleEliminarTabla = async (id: string) => {
    // Encuentra el registro a eliminar usando el ID
    const tabToDelete = tabs.find(tab => tab.id === id);
   
    if (tabToDelete) {
        const { id} = tabToDelete;
        
        try {
            const response = await fetch('http://localhost:3000/php/pages/controldocument/glosario/delete_level.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ nivel_id: id}),
            });
            const data = await response.json();
            if (data.success) {
                // Actualizar el estado si la eliminación fue exitosa
                setTabs(tabs.filter(tab => tab.id !== id));
            } else {
                console.error('Error al eliminar la tabla:', data.message);
            }
        } catch (error) {
            console.error('Error en la solicitud:', error);
        }
    } else {
        console.error('No se encontró el registro con el ID proporcionado');
    }
};
  

/*
  const handleAgregarTabla = () => {
   const nuevaTabla = {
      id: `tabla-${Date.now()}`,
      titulo: 'Nuevo Nivel',
      datos: []
    };
    setTabs([...tabs, nuevaTabla]);
  };
*/
const handleAgregarTabla = () => {
  const nuevaTabla = {
    titulo: 'Nuevo Nivel',
    datos: []
  };

  fetch('http://localhost:3000/php/pages/controldocument/glosario/add_level.php', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(nuevaTabla),
  })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        // Si la solicitud fue exitosa, agregar el nuevo nivel al estado con el id retornado
        setTabs(prevTabs => [
          ...prevTabs,
          {
           id: data.newId, // Usar el id retornado por el backend
            titulo: nuevaTabla.titulo,
            datos: nuevaTabla.datos
          }
        ]);

        // Mostrar mensaje de éxito
        setSuccessMessage('Nivel agregado exitosamente.');
        setErrorMessage('');

        // Cerrar el mensaje después de 3 segundos
        setTimeout(() => setSuccessMessage(''), 3000);
      } else {
        // Mostrar mensaje de error
        setErrorMessage(`Error al agregar el nuevo nivel: ${data.message}`);
        setSuccessMessage('');
      }
    })
    .catch(error => {
      console.error('Error en la solicitud:', error);
      setErrorMessage('Error en la solicitud.');
      setSuccessMessage('');
    });
};


  return (
    <div>
     
      {tabs.map((tab, index) => (
        <TablaDesplegable
          key={tab.id}
          id={tab.id}
          titulo={tab.titulo}
          datos={tab.datos}
          nivel={index + 1}
          onDragStart={handleDragStart}
          onDragEnter={handleDragEnter}
          onDragEnd={handleDragEnd}
          onEliminarTabla={handleEliminarTabla}
         
        />
      ))}
       <button
        onClick={handleAgregarTabla}
        className="mb-4 p-2 bg-blue-500 text-white rounded flex items-center"
      >
        <PlusCircle size={24} className="mr-2" />
        Agregar Nivel
      </button>

    
       {/* Mostrar mensajes de éxito o error */}
  {successMessage && (
    <div className="mt-4 p-2 rounded bg-green-200 text-green-800">
      {successMessage}
    </div>
  )}
  {errorMessage && (
    <div className="mt-4 p-2 rounded bg-red-200 text-red-800">
      {errorMessage}
    </div>
  )}
    </div>
  );
};

export default TablasNomenclaturaProyecto;