import React, { useState } from 'react';
import { Card, CardContent, CardHeader } from '../components/ui/card';
import { ScrollArea } from '../components/ui/scroll-area';
import { Separator } from '../components/ui/separator';
import { Button } from '../components/ui/button';
import { ChevronRight } from 'lucide-react';
import authorImage from '../img/author.jpg'; // Importa la imagen
import logoImage from '../img/logo.jpg';
import ContactForm from './ContactForm';
import videoSrc from '../assets/videos/simagi-video.mp4';
import { useNavigate } from 'react-router-dom';


const today = new Date();
const options: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
const formattedDate = today.toLocaleDateString('es-ES', options); // Cambia 'es-ES' al idioma deseado

const SimagiNewsComponent = () => {

  const navigate = useNavigate(); // Hook para la navegación

  const handleLoginRedirect = () => {
    navigate('/login'); // Redirige a la ruta '/login'
  };

  const [showContactForm, setShowContactForm] = useState(false);
  return (
    <div className="max-w-4xl mx-auto p-4 font-serif bg-gray-50">
      <header className="mb-8 text-center bg-white p-6 shadow-md rounded-lg">
        <img src={logoImage} alt="Simagi.cl" className='w-80 img-center'/>  
        <p className="text-sm text-gray-600">{formattedDate}</p>
      </header>

      <article className="bg-white p-8 shadow-lg rounded-lg">
        <h2 className="text-4xl font-bold mb-4 text-gray-700">El Verdadero Culpable de la <span className="uppercase font-extrabold">PERMISOLOGÍA</span>: La Trampa del Excel en la Gestión Empresarial</h2>
        
        <div className="flex items-center text-sm text-gray-600 mb-6">
          <img src={authorImage} alt="Guillermo Canales Pozo" className="w-10 h-10 rounded-full mr-3" />
          <span>Por Guillermo Canales Pozo | 4 minutos de lectura</span>
        </div>

     
        <div className="prose max-w-none">
          <h3 className="text-2xl font-bold mt-8 mb-4 text-teal-600">Desafiando el Status Quo en la Era Digital</h3>
          
          <p className="text-lg leading-relaxed">En pleno auge de la Inteligencia Artificial y la transformación digital, una realidad inquietante persiste en el núcleo de nuestras organizaciones: la dependencia excesiva en herramientas ofimáticas básicas como Excel. Esta dependencia, lejos de ser una solución, se ha convertido en el principal obstáculo para la eficiencia en los procesos de <span className="uppercase font-extrabold">PERMISOLOGÍA GUBERNAMENTAL</span> y <span className="uppercase font-extrabold">GESTIÓN EMPRESARIAL</span> en Chile, frenando nuestro potencial de crecimiento y competitividad global.</p>

          <p className="text-lg leading-relaxed">Imagine este escenario: durante el próximo "coffee break" en su empresa, realice una breve encuesta entre sus colaboradores, desde gerentes hasta administrativos. Pregúnteles: "¿Qué entienden cuando se menciona 'revisar la BASE DE DATOS' o 'actualizar el SISTEMA'?" La respuesta más común probablemente será "abrir Excel". Esta normalización de herramientas básicas como sistemas de gestión revela una peligrosa complacencia que limita nuestra capacidad de innovación y eficiencia.</p>

          <p className="text-lg leading-relaxed">Hemos caído en la trampa de considerar que utilizar Excel, Outlook o PowerPoint es sinónimo de contar con un sistema de gestión. Esta falacia no solo es simplista sino que representa un riesgo real para el progreso de nuestras instituciones y, por extensión, para el futuro económico de nuestro país. Es hora de romper este paradigma y abrazar soluciones que estén a la altura de los desafíos del siglo XXI.</p>
          <Separator className="my-8 bg-coral-300" />
          <div className="text-center">
            <video className="mx-auto" width="600" controls>
              <source src={videoSrc} type="video/mp4" />
                Tu navegador no soporta la etiqueta de video.
            </video>
          </div>

          <Separator className="my-8 bg-coral-300" />

          <h3 className="text-2xl font-bold mt-8 mb-4 text-sky-600">La Crisis Silenciosa: El Costo Oculto de la Ineficiencia</h3>

          <p className="text-lg leading-relaxed">Mientras empresas líderes a nivel mundial alcanzan nuevos horizontes impulsadas por la Inteligencia Artificial y sistemas de gestión avanzados, muchas organizaciones en Chile permanecen ancladas a métodos obsoletos. Seguimos acumulando información de manera rudimentaria en hojas de cálculo y archivos físicos, convirtiendo datos valiosos en recursos inaccesibles y subutilizados. Esta práctica no solo es anacrónica; representa una herida constante en nuestro Producto Interno Bruto, comprometiendo la competitividad y el futuro económico de las próximas generaciones.</p>

          <Card className="my-8 bg-yellow-50 border-yellow-200">
            <CardHeader className="font-bold text-2xl text-gray-700">SIMAGI: Liderando la Revolución en Gestión Integrada</CardHeader>
            <CardContent>
              <p className="text-lg mb-4"><strong>EL SISTEMA MAESTRO DE GESTIÓN INTEGRADO "SIMAGI"</strong> trasciende la noción tradicional de un sistema informático; representa un cambio de paradigma en la forma de abordar la <span className="uppercase font-extrabold">GESTIÓN EMPRESARIAL</span> y la <span className="uppercase font-extrabold">PERMISOLOGÍA</span>. Desarrollado por el INGENIERO CIVIL UTFSM, Guillermo Canales Pozo, SIMAGI se fundamenta en una serie de innovaciones registradas bajo Propiedad Intelectual:</p>
              <ul className="list-disc pl-5 mt-2 space-y-2">
                <li>Procedimiento para Gestionar la Problemática de la <span className="uppercase font-extrabold">PERMISOLOGÍA</span> (Nº 2023-A-12905)</li>
                <li>Procedimiento para la Gestión Integrada de Instrumentos de Planificación Territorial (IPT) (Nº 2024-A-5614)</li>
                <li>SIMAGI (Nº 2023-A-12948)</li>
                <li>Índice de Gestión de la <span className="uppercase font-extrabold">PERMISOLOGÍA</span> (Nº 2024-A-1799)</li>
              </ul>
            </CardContent>
          </Card>

          <h3 className="text-2xl font-bold mt-8 mb-4 text-coral-500">Cómo SIMAGI Transforma la Gestión Empresarial</h3>

          <ScrollArea className="h-96 w-full rounded-md border p-6 bg-teal-50 shadow-inner">
            <ul className="list-disc pl-5 space-y-4">
              <li><strong className="text-sky-600">Planificación Estratégica en Tiempo Real:</strong> SIMAGI cuenta con un módulo especializado para el monitoreo en tiempo real de la planificación estratégica.</li>
              
              <li><strong className="text-sky-600">Gestión Eficiente de Compra de Terreno:</strong> Con SIMAGI establece procedimientos recurrentes para el seguimiento eficiente de las condiciones de promesa y adquisición de terrenos.</li>
              
              <li><strong className="text-sky-600">Evaluación Técnico-Económica Ágil:</strong> SIMAGI incluye una herramienta ágil de evaluación técnico-económica accesible a todos los especialistas de tu organización.</li>
              
              <li><strong className="text-sky-600">Seguimiento de Compromisos Ambientales y de Tránsito:</strong> Con SIMAGI, podrás monitorear en el corto, mediano y largo plazo el cumplimiento de tus compromisos ambientales y de tránsito.</li>
              
              <li><strong className="text-sky-600">Gestión de Objetivos:</strong> Con SIMAGI, sigue en tiempo real el cumplimiento de los objetivos de cada especialista o departamento.</li>
              
              <li><strong className="text-sky-600">Analíticas y Alertas en Tiempo Real:</strong> El sistema proporciona analíticas en tiempo real y recibe alertas automáticas para una colaboración efectiva.</li>
              
              <li><strong className="text-sky-600">Solución Integral de Planificación y Gestión:</strong> SIMAGI ofrece una solución integral con un ecosistema completo de herramientas modulares y escalables.</li>
            </ul>
          </ScrollArea>

          <Separator className="my-8 bg-coral-300" />

          <h3 className="text-2xl font-bold mt-8 mb-4 text-teal-600">REGISTRA, MONITOREA E INVESTIGA CON SIMAGI</h3>

          <p className="text-lg leading-relaxed">Nos encontramos en una encrucijada crítica en la evolución de la gestión empresarial. SIMAGI emerge como la solución robusta y visionaria que necesitamos para enfrentar los desafíos del mañana, transformando la manera en que abordamos la planificación, ejecución y control de proyectos.</p>

          <p className="text-xl font-bold mt-6 text-gray-700">SIMAGI no es solo otro sistema informático; SIMAGI es el puente que integre la Gestión Empresarial y la Gestión Gubernamental con la Permisología en una solución integrada. Con SIMAGI, estudiar la Permisología como un fenómeno emergente se convierte en una tarea sencilla: podrás Registrar, Monitorear e Investigar con facilidad, transformando lo que antes era un obstáculo en una oportunidad para el progreso y la innovación con el objeto de promover una Política y Gestión Pública responsiva. ¿Estás listo para liderar esta revolución en tu organización?</p>

    <Button 
        className="mt-8 bg-teal-500 hover:bg-teal-600 text-white"
        onClick={() => setShowContactForm(true)}
      >
        Descubre el Potencial de SIMAGI <ChevronRight className="ml-2 h-4 w-4" />
      </Button>

      
      {showContactForm && (
        <ContactForm onClose={() => setShowContactForm(false)} />
      )}
        </div>
      </article>
      
    </div>
  );
};

export default SimagiNewsComponent;
