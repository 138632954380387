import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from '../../components/ui/dialog';
import React, { useState, useEffect } from 'react';
import { Input } from '../../components/ui/input';

import { Card, CardHeader, CardContent } from '../ui/card';
import { Button } from '../ui/button';
import { ScrollArea } from '../ui/scroll-area';
import { PlusCircle, FileDown, FileText, Trash2, Sheet, LucideFileType, LucideFileArchive, LucideFileType2, File } from 'lucide-react';
import Modal from '../ui/ModalUI'; // Asegúrate de importar el componente Modal

interface Template {
  id: number;
  name: string;
}

interface TemplateSelectionProps {
  onTemplateSelected: (selection: 
    { type: 'new'; templateName: string; templateDescription: string } | 
    { type: 'template'; templateName: string; templateType: 'SIMAGI' | 'custom' }
  ) => void;
}


const ErrorBox = ({ message }: { message: string }) => (
  <div className="bg-red-100 border mt-3 border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
    <strong className="font-bold mr-2">Error:</strong>
    <span className="block sm:inline">{message}</span>
  </div>
);
const TemplateSelectionInterface: React.FC<TemplateSelectionProps> = ({ onTemplateSelected }) => {
  const [selectedSimagiTemplate, setSelectedSimagiTemplate] = useState<Template | null>(null);
  const [selectedCustomTemplate, setSelectedCustomTemplate] = useState<Template | null>(null);
  const [simagiTemplates, setSimagiTemplates] = useState<Template[]>([]);
  const [customTemplates, setCustomTemplates] = useState<Template[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [templateToDelete, setTemplateToDelete] = useState<Template | null>(null);
   // Correct state variables for SIMAGI template copying
   const [isSaveAsOpen, setIsSaveAsOpen] = useState(false);
   const [newTemplateName, setNewTemplateName] = useState('');
   const [newTemplateDescription, setNewTemplateDescription] = useState('');
   const [error, setError] = useState<string | null>(null);
   const [saveAsMode, setSaveAsMode] = useState<'new' | 'simagi'>('new');


  useEffect(() => {
    const getTemplates = async () => {
      const fetchedSimagiTemplates = await fetchTemplates('SIMAGI');
      setSimagiTemplates(fetchedSimagiTemplates);
      const fetchedCustomTemplates = await fetchTemplates('custom');
      setCustomTemplates(fetchedCustomTemplates);
    };
    getTemplates();
  }, []);

  const handleSaveAsClose = () => {
    setIsSaveAsOpen(false);
    setNewTemplateName('');
    setNewTemplateDescription('');
    setError(null);
  };

  const handleCopySimagiTemplate = async () => {
    try {
      if (!selectedSimagiTemplate) {
        throw new Error('No SIMAGI template selected');
      }

      if (!newTemplateName.trim()) {
        setError('El nombre de la plantilla no puede estar vacío');
        return;
      }

      const copyData = {
        originalName: selectedSimagiTemplate.name,
        newName: newTemplateName,
        newDescription: newTemplateDescription
      };

      const response = await fetch('http://localhost:3000/php/pages/adm_planificacion/copy_simagi_to_custom.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(copyData),
      });

      const result = await response.json();

      if (result.success) {
        console.log('Plantilla SIMAGI copiada como nueva plantilla custom:', result);
        handleSaveAsClose();
        // Refresh the custom templates list
        const fetchedCustomTemplates = await fetchTemplates('custom');
        setCustomTemplates(fetchedCustomTemplates);
        // Load the newly created custom template
        onTemplateSelected({ 
          type: 'template', 
          templateName: newTemplateName,
          templateType: 'custom'
        });
      } else {
        if (result.error === 'Template name already exists') {
          setError('Ya existe este nombre de archivo');
        } else {
          throw new Error(result.error || 'Error al copiar la plantilla SIMAGI como nueva plantilla');
        }
      }
    } catch (error) {
      console.error('Error:', error);
      setError(error instanceof Error ? error.message : 'An unknown error occurred');
    }
  };

  const saveNewTemplate = async (name: string, description: string) => {
    try {
      const response = await fetch('http://localhost:3000/php/pages/adm_planificacion/save_new_template.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, description }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();

      if (result.success) {
        return { success: true };
      } else {
        return { success: false, error: result.error || 'Failed to save new template' };
      }
    } catch (error) {
      console.error('Error saving new template:', error);
      return { success: false, error: 'Error saving new template' };
    }
  };



  const handleSaveAs = async () => {
    try {
      if (!newTemplateName.trim()) {
        setError('El nombre de la plantilla no puede estar vacío');
        return;
      }

      if (saveAsMode === 'new') {
        // Guardar nueva plantilla en la base de datos
        const result = await saveNewTemplate(newTemplateName, newTemplateDescription);
        if (result.success) {
          console.log('Nueva plantilla guardada:', result);
          const fetchedCustomTemplates = await fetchTemplates('custom');
          setCustomTemplates(fetchedCustomTemplates);
          onTemplateSelected({
            type: 'new',
            templateName: newTemplateName,
            templateDescription: newTemplateDescription
          });
        } else {
          throw new Error(result.error || 'Error al guardar la nueva plantilla');
        }
      } else if (saveAsMode === 'simagi' && selectedSimagiTemplate) {
        // Logic for copying SIMAGI template
        const copyData = {
          originalName: selectedSimagiTemplate.name,
          newName: newTemplateName,
          newDescription: newTemplateDescription
        };

        const response = await fetch('http://localhost:3000/php/pages/adm_planificacion/copy_simagi_to_custom.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(copyData),
        });

        const result = await response.json();

        if (result.success) {
          console.log('Plantilla SIMAGI copiada como nueva plantilla custom:', result);
          const fetchedCustomTemplates = await fetchTemplates('custom');
          setCustomTemplates(fetchedCustomTemplates);
          onTemplateSelected({ 
            type: 'template', 
            templateName: newTemplateName,
            templateType: 'custom'
          });
        } else {
          if (result.error === 'Template name already exists') {
            setError('Ya existe este nombre de archivo');
            return;
          } else {
            throw new Error(result.error || 'Error al copiar la plantilla SIMAGI como nueva plantilla');
          }
        }
      }

      handleSaveAsClose();
    } catch (error) {
      console.error('Error:', error);
      setError(error instanceof Error ? error.message : 'An unknown error occurred');
    }
  };

  const fetchTemplates = async (type: 'SIMAGI' | 'custom'): Promise<Template[]> => {
    try {
      const response = await fetch(`http://localhost:3000/php/pages/adm_planificacion/get_plantilla.php?type=2&tipoPlantilla=${type}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (data.error) {
        throw new Error(data.error);
      }
      return data.map((item: any) => ({
        id: parseInt(item.id),
        name: item.nombre,
      }));
    } catch (error) {
      console.error(`Error fetching ${type} templates:`, error);
      return [];
    }
  };

  const handleNewProject = () => {
    setSaveAsMode('new');
    setNewTemplateName('Nueva Plantilla');
    setNewTemplateDescription('');
    setIsSaveAsOpen(true);
  };
  const handleLoadSimagiTemplate = () => {
    if (selectedSimagiTemplate) {
      setSaveAsMode('simagi');
      setNewTemplateName(selectedSimagiTemplate.name + ' (Copia)');
      setNewTemplateDescription('');
      setIsSaveAsOpen(true);
    }
  };

  const handleLoadCustomTemplate = () => {
    if (selectedCustomTemplate) {
      onTemplateSelected({ 
        type: 'template', 
        templateName: selectedCustomTemplate.name,
        templateType: 'custom'
      });
    }
  };

  const openDeleteModal = (template: Template) => {
    setTemplateToDelete(template);
    setIsModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsModalOpen(false);
    setTemplateToDelete(null);
  };

  const handleDeleteTemplate = async () => {
    if (!templateToDelete) return;

    try {
      const response = await fetch('http://localhost:3000/php/pages/adm_planificacion/delete_plantilla.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: templateToDelete.id }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      if (result.success) {
        setCustomTemplates(customTemplates.filter(template => template.id !== templateToDelete.id));
        if (selectedCustomTemplate?.id === templateToDelete.id) {
          setSelectedCustomTemplate(null);
        }
        closeDeleteModal();
      } else {
        throw new Error(result.error || 'Failed to delete template');
      }
    } catch (error) {
      console.error('Error deleting template:', error);
      // Here you might want to show an error message to the user
    }
  };

  return (
    <Card className="w-full mt-6 border-none shadow-lg rounded-lg bg-white">
      <CardContent className="flex flex-col space-y-6 p-6">
        <div className="flex items-center space-x-6">
          <Button
            onClick={handleNewProject}
            className="bg-teal-500 hover:bg-teal-600 transition-colors text-white"
            size="lg"
          >
            <File className="mr-2 h-5 w-5" />
            Nueva plantilla en blanco
          </Button>

          <div className="flex items-center pl-12 space-x-2">
            <Button
              onClick={handleLoadSimagiTemplate}
              className="bg-teal-500 hover:bg-teal-600 transition-colors text-white"
              size="lg"
              disabled={!selectedSimagiTemplate}
            >
              <FileDown className="mr-2 h-5 w-5" />
              Crear desde plantilla SIMAGI
            </Button>
            
            <select
              value={selectedSimagiTemplate?.name ?? ''}
              onChange={(e) => {
                const template = simagiTemplates.find(t => t.name === e.target.value);
                setSelectedSimagiTemplate(template || null);
              }}
              className="border rounded-md p-2"
            >
              <option value="">Seleccionar plantilla SIMAGI</option>
              {simagiTemplates.map((template) => (
                <option key={template.id} value={template.name}>
                  {template.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        
        <div className="w-full">
          <h3 className="text-lg font-semibold mb-2">Mis Plantillas</h3>
          <Card className="border border-gray-200">
            <ScrollArea className="h-48 rounded-md">
              <div className="p-2">
                {customTemplates.map((template) => (
                  <div
                    key={template.id}
                    className={`p-2 cursor-pointer hover:bg-gray-100 rounded flex items-center justify-between ${
                      selectedCustomTemplate?.name === template.name ? 'bg-teal-100 hover:bg-teal-200 transition-colors' : ''
                    }`}
                  >
                    <div 
                      className="flex items-center flex-grow"
                      onClick={() => setSelectedCustomTemplate(template)}
                    >
                      <FileText className="mr-2 h-4 w-4 text-gray-500" />
                      {template.name}
                    </div>
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={(e) => {
                        e.stopPropagation();
                        openDeleteModal(template);
                      }}
                    >
                      <Trash2 className="h-4 w-4 text-red-500" />
                    </Button>
                  </div>
                ))}
              </div>
            </ScrollArea>
          </Card>
          <div className="mt-4">
            <Button
              onClick={handleLoadCustomTemplate}
              className="bg-teal-500 hover:bg-teal-600 transition-colors text-white"
              size="lg"
              disabled={!selectedCustomTemplate}
            >
              <FileDown className="mr-2 h-5 w-5" />
              Cargar Mi Plantilla
            </Button>
          </div>
        </div>
        <Dialog isOpen={isSaveAsOpen} onClose={handleSaveAsClose}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>
                {saveAsMode === 'new' ? 'Crear nueva plantilla' : 'Guardar plantilla como...'}
              </DialogTitle>
            </DialogHeader>
            <Input
              placeholder="Nombre de la nueva plantilla"
              value={newTemplateName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewTemplateName(e.target.value)}
              className='mb-2'
            />
            <Input
              placeholder="Descripción de la nueva plantilla"
              value={newTemplateDescription}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewTemplateDescription(e.target.value)}
            />
            {error && <ErrorBox message={error} />}
            <DialogFooter>
              <Button onClick={handleSaveAsClose} variant="outline">Cancelar</Button>
              <Button onClick={handleSaveAs} className="bg-teal-500 ml-3 border text-white hover:bg-teal-800" variant="default">
                {saveAsMode === 'new' ? 'Crear' : 'Crear nueva plantilla'}
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>

        <Modal
          isOpen={isModalOpen}
          onClose={closeDeleteModal}
          onConfirm={handleDeleteTemplate}
          title="Confirmar eliminación"
          message={`¿Estás seguro de que quieres eliminar la plantilla "${templateToDelete?.name}"? Esta acción no se puede deshacer.`}
        />
      </CardContent>
    </Card>
  );
};

export default TemplateSelectionInterface;