// src/pages/PrivateRoute.tsx
import React from 'react';
import { Navigate } from 'react-router-dom';

interface PrivateRouteProps {
  children: React.ReactElement;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  // Aquí debes comprobar si el usuario está autenticado
  const isAuthenticated = !!localStorage.getItem('authToken'); // O tu lógica de autenticación

  return isAuthenticated ? children : <Navigate to="/mvp" />;
};

export default PrivateRoute;
