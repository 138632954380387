import React from 'react';

interface DataItem {
  date: string;
  item1?: number;
  item2?: number;
  item3?: number;
  item4?: number;
  item5?: number;
  item6?: number;
  item7?: number;
  item8?: number;
}

const ConstructionProgressChart: React.FC = () => {
  const data: DataItem[] = [
    { date: '25 Jun', item1: 181, item2: 124, item3: 98, item4: 66, item5: 32, item6: 0, item7: 62, item8: 42 },
    { date: '8 Jul', item1: 182, item2: 127, item3: 98, item4: 69, item5: 40, item6: 1, item7: 66, item8: 44 },
    { date: '18 Jul', item1: 182, item2: 139, item3: 106, item4: 70, item5: 42, item6: 2, item7: 69, item8: 40 },
    { date: '29 Jul', item1: 182, item2: 144, item3: 109, item4: 69, item5: 43, item6: 3, item7: 70, item8: 42 },
    { date: '8 Ago', item1: 186, item2: 161, item3: 112, item4: 78, item5: 48, item6: 4, item7: 78, item8: 48 },
    { date: '19 Ago', item1: 187, item2: 165, item3: 120, item4: 78, item5: 59, item6: 4, item7: 84, item8: 59 },
  ];

  const items = [
    { name: 'CUBIERTA PLANCHAS TECHO', color: '#8884d8' },
    { name: 'REVESTIMIENTO TABIQUE INTERIOR', color: '#82ca9d' },
    { name: 'CUBREPISOS ALFOMBRA', color: '#ffc658' },
    { name: 'PAPEL MURAL', color: '#ff7300' },
    { name: 'CLOSETS', color: '#0088FE' },
    { name: 'GRIFERIA', color: '#00C49F' },
    { name: 'VENTANAS TERMOPANEL DE PVC', color: '#FFBB28' },
    { name: 'CIELO FALSO', color: '#FF8042' },
  ];

  const width = 800;
  const height = 500;
  const margin = { top: 40, right: 80, bottom: 60, left: 60 };
  const chartWidth = width - margin.left - margin.right;
  const chartHeight = height - margin.top - margin.bottom;

  const xScale = (index: number) => (index / (data.length - 1)) * chartWidth;
  const yScale = (value: number) => chartHeight - (value / 200) * chartHeight;

  return (
    <div className="w-full max-w-5xl mx-auto p-4">
      <h2 className="text-xl font-bold mb-4 text-center">Curva de velocidad - Avance de construcción</h2>
      <svg width={width} height={height}>
        <g transform={`translate(${margin.left},${margin.top})`}>
          {/* X and Y axes */}
          <line x1="0" y1={chartHeight} x2={chartWidth} y2={chartHeight} stroke="black" />
          <line x1="0" y1="0" x2="0" y2={chartHeight} stroke="black" />

          {/* Y-axis labels */}
          {[0, 50, 100, 150, 200].map((value) => (
            <g key={value}>
              <line x1="-5" y1={yScale(value)} x2="0" y2={yScale(value)} stroke="black" />
              <text x="-10" y={yScale(value)} textAnchor="end" alignmentBaseline="middle" fontSize="12">
                {value}
              </text>
            </g>
          ))}

          {/* Data lines */}
          {items.map((item, itemIndex) => (
            <path
              key={item.name}
              d={data.map((d, i) => {
                const yValue = Number(d[`item${itemIndex + 1}` as keyof DataItem] ?? 0);
                return `${i === 0 ? 'M' : 'L'} ${xScale(i)} ${yScale(yValue)}`;
              }).join(' ')}
              fill="none"
              stroke={item.color}
              strokeWidth="2"
            />
          ))}

          {/* X-axis labels */}
          {data.map((d, i) => (
            <text
              key={d.date}
              x={xScale(i)}
              y={chartHeight + 20}
              textAnchor="middle"
              fontSize="12"
            >
              {d.date}
            </text>
          ))}

          {/* X-axis title */}
          <text x={chartWidth / 2} y={chartHeight + 50} textAnchor="middle" fontSize="14" fontWeight="bold">
            Fecha
          </text>

          {/* Y-axis title */}
          <text 
            transform={`rotate(-90, ${-40}, ${chartHeight / 2})`}
            x={-40}
            y={chartHeight / 2}
            textAnchor="middle"
            fontSize="14"
            fontWeight="bold"
          >
            Cantidad
          </text>
        </g>
      </svg>

      {/* Legend */}
      <div className="mt-4 flex flex-wrap justify-center">
        {items.map((item) => (
          <div key={item.name} className="flex items-center mr-4 mb-2">
            <div className="w-4 h-4 mr-2" style={{ backgroundColor: item.color }}></div>
            <span className="text-sm">{item.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ConstructionProgressChart;

// Fix for '--isolatedModules' error
export {};
