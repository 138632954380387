import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Register: React.FC = () => {
  const [username, setUsername] = useState('');
  const [firstname, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');
  const [token, setToken] = useState<string | null>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false); // Estado de carga

  const navigate = useNavigate();

  // Función para generar el nombre de usuario
  const generateUsername = (name: string, lastname: string) => {
    const [firstName] = name.split(' ');
    const LastName = lastname.split(' ')[0]; // Primer apellido
    const isCompound = lastname.includes(' ');
    const usernamePrefix = firstName.charAt(0).toLowerCase();
    const usernameSuffix = isCompound ? lastname.replace(/ /g, '').toLowerCase() : LastName.toLowerCase();
    return `${usernamePrefix}${usernameSuffix}`;
  };

  useEffect(() => {
    // Obtener token y permisos desde la URL
    const urlParams = new URLSearchParams(window.location.search);
    const tokenParam = urlParams.get('token');
    const isAdminParam = urlParams.get('is_admin') === 'true';
    const emailParam = urlParams.get('email');

    if (tokenParam) {
      setToken(tokenParam);
      setIsAdmin(isAdminParam);
    }

    if (emailParam) {
      setEmail(emailParam); // Establece el correo electrónico si se proporciona
    }

    // Generar el nombre de usuario automáticamente cuando el nombre y apellido cambian
    if (firstname && lastname) {
      setUsername(generateUsername(firstname, lastname));
    }
  }, [firstname, lastname]);

  const handleRegister = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Validar que las contraseñas coinciden
    if (password !== confirmPassword) {
      setError('Las contraseñas no coinciden.');
      setSuccess('');
      return;
    }

    console.log('Datos enviados:', { username, firstname, lastname, email, password, token, isAdmin });

    setLoading(true); // Inicia el estado de carga

    try {
      const response = await fetch('http://localhost:3000/php/login/register.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, firstname, lastname, email, password, token, is_admin: isAdmin }),
      });

      const responseText = await response.text();
      console.log('Respuesta del servidor:', responseText);

      if (responseText.trim() === '') {
        setError('La respuesta del servidor está vacía.');
        setSuccess('');
        setLoading(false); // Termina el estado de carga
        return;
      }

      try {
        const data = JSON.parse(responseText);

        if (data.success) {
          setSuccess(data.message || 'Registro exitoso.');
          setError('');
          sessionStorage.setItem('firstName', data.firstname);
          sessionStorage.setItem('isAdmin', data.is_admin.toString());
          if (data.token) {
           localStorage.setItem('authToken', data.token);
          }
          setTimeout(() => navigate('/ui'), 2000); // Redirige después de 2 segundos
        } else {
          setError(data.error || 'Error al registrar el usuario.');
          setSuccess('');
        }
      } catch (jsonError) {
        console.error('Error al parsear JSON:', jsonError);
        setError('Error en la respuesta del servidor: ' + responseText);
        setSuccess('');
      }

    } catch (error) {
      console.error('Register failed:', error);
      setError(`Ocurrió un error durante el registro. ${error}`);
      setSuccess('');
    } finally {
      setLoading(false); // Termina el estado de carga
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <div className="login-logo"></div>
        <form onSubmit={handleRegister}>
          <div className="input-container">
            <i className="input-icon fas fa-at"></i>
            <input
              type="text"
              placeholder="Usuario (automático)"
              value={username}
              readOnly // Hace que el campo sea solo lectura
              className="bg-gray-100 cursor-default login-input"
            />
          </div>
          <div className="input-container">
            <i className="input-icon fas fa-user"></i>
            <input
              type="text"
              placeholder="Nombre"
              value={firstname}
              onChange={(e) => setName(e.target.value)}
              className="login-input"
            />
          </div>
          <div className="input-container">
            <i className="input-icon fas fa-user"></i>
            <input
              type="text"
              placeholder="Apellido"
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
              className="login-input"
            />
          </div>
          <div className="input-container">
            <i className="input-icon fas fa-envelope"></i>
            <input
              type="email"
              placeholder="Correo electrónico"
              value={email}
              readOnly // Hace que el campo sea solo lectura
              className="login-input bg-gray-100 cursor-default "
            />
          </div>
          <div className="input-container">
            <i className="input-icon fas fa-lock"></i>
            <input
              type="password"
              placeholder="Contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="login-input"
            />
          </div>
          <div className="input-container">
            <i className="input-icon fas fa-lock"></i>
            <input
              type="password"
              placeholder="Confirmar contraseña"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="login-input"
            />
          </div>
          <div className="input-container">
            <input
              type="checkbox"
              id="is_admin"
              checked={isAdmin}
              readOnly // Hace que el checkbox sea solo lectura
              className="cursor-default"
            />
            <label htmlFor="is_admin" className="ml-2">Administrador</label>
          </div>
          <button type="submit" className='submit-button mb-5' disabled={loading}>
            {loading ? 'Registrando...' : 'Registrarse'}
          </button>
          {success && <p className="success-message">{success}</p>}
          {error && <p className="error-message">{error}</p>}
        </form>
      </div>
      <div className="triangle"></div>
    </div>
  );
};

export default Register;
