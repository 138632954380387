import React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const WorkflowDiagram = () => {
  const steps = [
    'Carátula Firmada',
    'Excel',
    'VB Supervisor Obra',
    'F30/F30-1 y otros',
    'VB doc obligatoria',
    'Orden de VB Pago',
    'VB Gerencia',
    'Pago Finanzas'
  ];

  const paymentStates = [
    { name: 'EP01', progress: 8 },
    { name: 'EP02', progress: 4 },
    { name: 'EP03', progress: 1 }
  ];

  return (
    <div className="max-w-7xl mx-auto p-6 bg-white rounded-lg ">
      <div className="relative mb-8">
        <ChevronLeft className="absolute left-0 top-1/2 -translate-y-1/2 w-6 h-6 text-blue-600 cursor-pointer" />
        <ChevronRight className="absolute right-0 top-1/2 -translate-y-1/2 w-6 h-6 text-blue-600 cursor-pointer" />
        <div className="flex justify-between items-center px-8 mb-4">
          {steps.map((step, index) => (
            <div key={index} className="flex flex-col items-center w-24">
              <div className="text-blue-700 text-xs text-center h-12 flex items-center justify-center">
                {step}
              </div>
              <div className="bg-blue-500 text-white rounded-full w-6 h-6 flex items-center justify-center text-xs">
                {index + 1}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="space-y-4">
        {paymentStates.map((state, stateIndex) => (
          <div key={state.name} className="flex items-center space-x-4">
            <div className="w-16 bg-blue-700 text-white text-center py-2 rounded">
              {state.name}
            </div>
            <div className="flex-grow bg-blue-100 h-2 rounded-full overflow-hidden">
              <div 
                className="bg-blue-500 h-full rounded-full" 
                style={{ width: `${(state.progress / steps.length) * 100}%` }}
              ></div>
            </div>
            <div className="flex space-x-2">
              {steps.map((_, index) => (
                <div
                  key={index}
                  className={`w-6 h-6 rounded-full flex items-center justify-center text-xs
                    ${index < state.progress ? 'bg-blue-500 text-white' : 'bg-blue-100 text-blue-500'}`}
                >
                  {index + 1}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WorkflowDiagram;
