import React, { useState, useCallback, useEffect } from 'react';
import { PieChart, Pie, Cell, Legend, Tooltip as RechartsTooltip } from 'recharts';

interface RiskData {
  id: string;
  subproceso: string;
  riesgo: string;
  p: number;
  i: number;
  valoracion: string;
  responsable: string;
  fechaControl: string;
  estrategias: string;
  estatus: string;
}

interface TooltipProps {
  content: RiskData[];
  children: React.ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({ content, children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });

  const handleMouseEnter = (e: React.MouseEvent<HTMLDivElement>) => {
    const rect = e.currentTarget.getBoundingClientRect();
    setPosition({
      top: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX
    });
    setIsVisible(true);
  };

  const handleMouseLeave = () => {
    setIsVisible(false);
  };

  return (
    <div 
      className="relative" 
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {isVisible && (
        <div className="fixed bg-white/95 text-gray-800 p-4 rounded-lg shadow-lg z-50 w-[400px] max-h-[400px] overflow-y-auto border border-gray-300 text-sm leading-relaxed"
             style={{top: `${position.top}px`, left: `${position.left}px`}}>
          <h4 className="m-0 mb-4 border-b-2 border-green-500 pb-2 text-green-500">Detalles del Riesgo</h4>
          {content.map((risk, index) => (
            <div key={risk.id} className={`mb-5 pb-5 ${index < content.length - 1 ? 'border-b border-gray-200' : ''}`}>
              <p><strong>ID:</strong> {risk.id}</p>
              <p><strong>Subproceso:</strong> {risk.subproceso}</p>
              <p><strong>Riesgo:</strong> {risk.riesgo}</p>
              <p><strong>Probabilidad:</strong> {risk.p}</p>
              <p><strong>Impacto:</strong> {risk.i}</p>
              <p><strong>Valoración:</strong> {risk.valoracion}</p>
              <p><strong>Responsable:</strong> {risk.responsable}</p>
              <p><strong>Fecha Control:</strong> {risk.fechaControl}</p>
              <p><strong>Estrategias:</strong> {risk.estrategias}</p>
              <p><strong>Estatus:</strong> {risk.estatus}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const RiskMatrix: React.FC = () => {
  const [risks, setRisks] = useState<RiskData[]>([
    { id: 'A1', subproceso: 'COMPRAVENTA', riesgo: 'Retrasos en permisos', p: 5, i: 5, valoracion: 'Muy Alto', responsable: 'OCP', fechaControl: '2024-08-04', estrategias: 'Seguimiento de permisos', estatus: 'Abierto' },
    { id: 'A2', subproceso: 'Subproceso 1', riesgo: 'Cambios legales', p: 5, i: 5, valoracion: 'Muy Alto', responsable: 'Resp. 2', fechaControl: '2024-09-04', estrategias: 'Vigilancia normativa', estatus: 'Cerrado' },
    { id: 'A3', subproceso: 'Subproceso 1', riesgo: 'Problemas financieros', p: 4, i: 5, valoracion: 'Muy Alto', responsable: 'Resp. 3', fechaControl: '2024-09-05', estrategias: 'Diversificar fuentes', estatus: 'Abierto' },
  ]);

  const impactLevels = [1, 2, 3, 4, 5];
  const probabilityLevels = [1, 2, 3, 4, 5];

  const getColor = useCallback((p: number, i: number) => {
    const value = p * i;
    if (value <= 3) return 'bg-green-400';
    if (value <= 6) return 'bg-yellow-300';
    if (value <= 12) return 'bg-orange-400';
    return 'bg-red-500';
  }, []);

  const handleInputChange = useCallback((id: string, field: keyof RiskData, value: string | number) => {
    setRisks(prevRisks => prevRisks.map(risk => 
      risk.id === id ? { ...risk, [field]: value } : risk
    ));
  }, []);

  const addRow = () => {
    const newId = `A${risks.length + 1}`;
    const newRisk: RiskData = {
      id: newId,
      subproceso: '',
      riesgo: '',
      p: 1,
      i: 1,
      valoracion: 'Bajo',
      responsable: '',
      fechaControl: new Date().toISOString().split('T')[0],
      estrategias: '',
      estatus: 'Abierto'
    };
    setRisks([...risks, newRisk]);
  };

  const deleteRow = (id: string) => {
    setRisks(risks.filter(risk => risk.id !== id));
  };

  useEffect(() => {
    setRisks(prevRisks => prevRisks.map(risk => {
      const p = risk.p;
      const i = risk.i;
      let valoracion = 'No definido';
      if (p && i) {
        const riskValue = p * i;
        if (riskValue <= 3) valoracion = 'Bajo';
        else if (riskValue <= 6) valoracion = 'Medio';
        else if (riskValue <= 12) valoracion = 'Alto';
        else valoracion = 'Muy Alto';
      }
      return { ...risk, valoracion };
    }));
  }, [risks]);

  // Cálculo de datos para el gráfico de torta
  const openTasks = risks.filter(risk => risk.estatus === 'Abierto').length;
  const closedTasks = risks.filter(risk => risk.estatus === 'Cerrado').length;
  const pieData = [
    { name: 'Abiertas', value: openTasks },
    { name: 'Cerradas', value: closedTasks },
  ];
  const COLORS = ['#0088FE', '#00C49F'];

  return (
    <div className="font-sans max-w-7xl mx-auto p-5">
      <h2 className="text-2xl font-bold mb-5">Matriz de Riesgo Mejorada</h2>

      <div className="flex mb-5">
        {/* Risk Matrix */}
        <div className="flex-1">
          <div className="flex">
            <div className="w-12 mr-2 flex flex-col justify-between">
              <div className="h-5">P</div>
              {probabilityLevels.slice().reverse().map(prob => (
                <div key={prob} className="h-12 flex items-center justify-center">{prob}</div>
              ))}
            </div>
            <div>
              {probabilityLevels.slice().reverse().map(prob => (
                <div key={prob} className="flex h-12">
                  {impactLevels.map(impact => {
                    const cellRisks = risks.filter(risk => risk.p === prob && risk.i === impact);
                    return (
                      <Tooltip key={`${prob}-${impact}`} content={cellRisks}>
                        <div className={`w-12 h-12 ${getColor(prob, impact)} border border-white flex items-center justify-center text-xs cursor-pointer`}>
                          {cellRisks.map(risk => risk.id).join(', ')}
                        </div>
                      </Tooltip>
                    );
                  })}
                </div>
              ))}
              <div className="flex justify-around mt-2">
                {impactLevels.map(impact => (
                  <div key={impact} className="w-12 text-center">{impact}</div>
                ))}
              </div>
              <div className="text-center mt-1">I</div>
            </div>
          </div>
        </div>

        {/* Pie Chart */}
        <div className="flex-1 flex justify-center items-center">
          <PieChart width={300} height={300}>
            <Pie
              data={pieData}
              cx={150}
              cy={150}
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              paddingAngle={5}
              dataKey="value"
              label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
            >
              {pieData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Legend />
            <RechartsTooltip />
          </PieChart>
        </div>
      </div>

      <h3 className="text-xl font-bold my-5 text-green-600 bg-green-100 p-2 rounded-lg shadow-sm">
        Con campos de Riesgos, Impacto y Estrategias
      </h3>

      {/* Risk Table */}
      <div className="overflow-x-auto">
        <table className="w-full bg-white shadow-md rounded-lg overflow-hidden">
          <thead className="bg-green-500 text-white">
            <tr>
              <th className="p-3 text-left">ID</th>
              <th className="p-3 text-left">Subproceso</th>
              <th className="p-3 text-left">Riesgo</th>
              <th className="p-3 text-left">P</th>
              <th className="p-3 text-left">I</th>
              <th className="p-3 text-left">Valoración</th>
              <th className="p-3 text-left">Responsable</th>
              <th className="p-3 text-left">Fecha Control</th>
              <th className="p-3 text-left">Estrategias</th>
              <th className="p-3 text-left">Estatus</th>
              <th className="p-3 text-left">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {risks.map((risk) => (
              <tr key={risk.id} className={risk.estatus === 'Cerrado' ? 'bg-gray-100' : ''}>
                <td className="p-3 border-t">
                  <input 
                    value={risk.id} 
                    onChange={(e) => handleInputChange(risk.id, 'id', e.target.value)} 
                    className="w-full p-1 border rounded"
                  />
                </td>
                <td className="p-3 border-t">
                  <input 
                    value={risk.subproceso} 
                    onChange={(e) => handleInputChange(risk.id, 'subproceso', e.target.value)} 
                    className="w-full p-1 border rounded"
                  />
                </td>
                <td className="p-3 border-t">
                  <input 
                    value={risk.riesgo} 
                    onChange={(e) => handleInputChange(risk.id, 'riesgo', e.target.value)} 
                    className="w-full p-1 border rounded"
                  />
                </td>
                <td className="p-3 border-t">
                  <input 
                    type="number" 
                    min="1" 
                    max="5" 
                    value={risk.p} 
                    onChange={(e) => handleInputChange(risk.id, 'p', parseInt(e.target.value))} 
                    className="w-12 p-1 border rounded" 
                  />
                </td>
                <td className="p-3 border-t">
                  <input 
                    type="number" 
                    min="1" 
                    max="5" 
                    value={risk.i} 
                    onChange={(e) => handleInputChange(risk.id, 'i', parseInt(e.target.value))} 
                    className="w-12 p-1 border rounded" 
                  />
                </td>
                <td className="p-3 border-t">{risk.valoracion}</td>
                <td className="p-3 border-t">
                  <input 
                    value={risk.responsable} 
                    onChange={(e) => handleInputChange(risk.id, 'responsable', e.target.value)} 
                    className="w-full p-1 border rounded"
                  />
                </td>
                <td className="p-3 border-t">
                  <input 
                    type="date" 
                    value={risk.fechaControl} 
                    onChange={(e) => handleInputChange(risk.id, 'fechaControl', e.target.value)} 
                    className="w-full p-1 border rounded"
                  />
                </td>
                <td className="p-3 border-t">
                  <input 
                    value={risk.estrategias} 
                    onChange={(e) => handleInputChange(risk.id, 'estrategias', e.target.value)} 
                    className="w-full p-1 border rounded"
                  />
                </td>
                <td className="p-3 border-t">
                  <select 
                    value={risk.estatus} 
                    onChange={(e) => handleInputChange(risk.id, 'estatus', e.target.value)} 
                    className="w-full p-1 border rounded"
                  >
                    <option value="Abierto">Abierto</option>
                    <option value="Cerrado">Cerrado</option>
                  </select>
                </td>
                <td className="p-3 border-t">
                  <button 
                    onClick={() => deleteRow(risk.id)}
                    className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
                  >
                    Eliminar
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <button 
        onClick={addRow}
        className="mt-5 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
      >
        Agregar Fila
      </button>
    </div>
  );
};

export default RiskMatrix;
