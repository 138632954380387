import React from 'react';
import { Table, AlertCircle, CheckCircle } from 'lucide-react';

type TaskStatus = 'completed' | 'inProgress' | 'pending' | 'alert';

interface Task {
  name: string;
  status: TaskStatus;
  hasDate?: boolean;
}

interface Project {
  id: string;
  name: string;
  tasks: Task[];
}

const projectData: Project[] = [
  { id: 'A', name: 'Proyecto A', tasks: [
    { name: 'Contratación Servicio', status: 'pending' },
    { name: 'Estudio de Tránsito (IMIV)', status: 'inProgress', hasDate: true },
    { name: 'Ingreso', status: 'pending' },
    { name: 'Proyecto Pavimentación y Aguas Lluvia', status: 'completed', hasDate: true },
    { name: 'Contratación, Desarrollo e Ingreso', status: 'alert' },
    { name: 'Proyecto Agua Potable y Alcantarillado', status: 'inProgress', hasDate: true },
  ]},
  { id: 'B', name: 'Proyecto B', tasks: [
    { name: 'Aprobación', status: 'alert' },
  ]},
  { id: 'C', name: 'Proyecto C', tasks: [
    { name: 'Proyecto Modificación Cauce', status: 'pending', hasDate: true },
    { name: 'Aprobación', status: 'alert' },
  ]},
  { id: 'D', name: 'Proyecto D', tasks: [
    { name: 'Declaración Impacto Ambiental (DIA)', status: 'completed', hasDate: true },
    { name: 'Contratación Servicio', status: 'pending' },
  ]},
  { id: 'E', name: 'Proyecto E', tasks: [
    { name: 'Estudio de Tránsito (IMIV)', status: 'inProgress', hasDate: true },
    { name: 'Ingreso', status: 'alert' },
  ]},
  { id: 'F', name: 'Proyecto F', tasks: [
    { name: 'Proyecto Pavimentación y Aguas Lluvia', status: 'pending', hasDate: true },
    { name: 'Contratación, Desarrollo e Ingreso', status: 'alert' },
    { name: 'Proyecto Modificación Cauce', status: 'inProgress', hasDate: true },
    { name: 'Aprobación', status: 'alert' },
  ]},
  { id: 'G', name: 'Proyecto G', tasks: [
    { name: 'Proyecto Agua Potable y Alcantarillado', status: 'pending', hasDate: true },
    { name: 'Aprobación', status: 'alert' },
  ]},
];

interface StatusIconProps {
  status: TaskStatus;
}

const StatusIcon: React.FC<StatusIconProps> = ({ status }) => {
  switch (status) {
    case 'completed':
      return <CheckCircle className="text-green-500" />;
    case 'inProgress':
      return <div className="w-4 h-4 bg-yellow-400 rounded-full" />;
    case 'pending':
      return <div className="w-4 h-4 border-2 border-gray-300 rounded-full" />;
    case 'alert':
      return <AlertCircle className="text-red-500" />;
    default:
      return null;
  }
};

const ProjectStatusTable: React.FC = () => {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-cyan-100 border-collapse">
        <thead>
          <tr className="bg-cyan-200">
            <th className="border px-4 py-2">Proyecto</th>
            <th className="border px-4 py-2">Proyecto Ingeniería</th>
            <th className="border px-4 py-2">Urbanización</th>
            <th className="border px-4 py-2">Inicio</th>
            <th className="border px-4 py-2">Término</th>
            <th className="border px-4 py-2">Seguimiento</th>
            <th className="border px-4 py-2">Estado</th>
            <th className="border px-4 py-2"></th>
          </tr>
        </thead>
        <tbody>
          {projectData.map((project) => (
            project.tasks.map((task, index) => (
              <tr key={`${project.id}-${index}`} className={index % 2 === 0 ? 'bg-white' : 'bg-cyan-100'}>
                <td className="border px-4 py-2">{project.name}</td>
                <td className="border px-4 py-2">Proyecto Ingeniería</td>
                <td className="border px-4 py-2">{task.name}</td>
                <td className="border px-4 py-2">Urbanización</td>
                <td className="border px-4 py-2">{task.hasDate ? 'Inicio' : ''}</td>
                <td className="border px-4 py-2">{task.hasDate ? 'Término' : ''}</td>
                <td className="border px-4 py-2 text-center">
                  <StatusIcon status={task.status} />
                </td>
                <td className="border px-4 py-2 text-center">
                  {task.status === 'completed' && <CheckCircle className="text-green-500" />}
                  {task.status === 'inProgress' && <div className="w-4 h-4 bg-yellow-400 rounded-full mx-auto" />}
                </td>
                <td className="border px-4 py-2 text-center">
                  <Table className="text-gray-500" />
                </td>
              </tr>
            ))
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProjectStatusTable;
