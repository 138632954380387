import { parse } from 'path';
import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FileQuestion } from 'lucide-react';


interface Process {
  nombreProceso: string;
  codigo: string;
  comuna: string;
  subprocesos?: string[];
  plantilla: string;
}

interface Subproceso {
  id: number;
  subproceso: string;
  color: string;
}

interface PlantillaDB {
  id: number;
  nombre: string;
  descripcion: string | null;
  contenido: string;
  tipo: string;
  fecha_creacion: string;
  fecha_modificacion: string;
}

interface Task {
  name: string;
  date: string;
  progress: number;
}





interface ProjectTimelineProps {
  selectedProcess: string;
  setSelectedProcess: React.Dispatch<React.SetStateAction<string>>;
  processes: Process[];
  subprocessColor: string;
}

interface SubprocessTabProps {
  label: string;
  isActive: boolean;
  onClick: () => void;
  color: string;
  index: number;
  totalTabs: number;
  activeIndex: number;
}

const SubprocessTab: React.FC<SubprocessTabProps> = ({ 
  label, 
  isActive, 
  onClick, 
  color, 
  index, 
  totalTabs
}) => {
  const [scale, setScale] = useState(1);
  const activeScale = 1.19;
  const fixedOffset = 20; // Offset fijo para todas las pestañas

  useEffect(() => {
    if (isActive) {
      setScale(activeScale);
    } else {
      setScale(1);
    }
  }, [isActive]);

  const buttonStyle: React.CSSProperties = {
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    padding: '0 30px 0 30px',
    fontSize: '14px',
    fontWeight: 'medium',
    color: isActive ? '#ffffff' : '#115E59',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    overflow: 'visible',
    transition: 'all 0.3s',
    marginRight: `-${fixedOffset}px`,
    zIndex: isActive ? totalTabs : index,
    whiteSpace: 'nowrap',
  };

  const shapeStyle: React.CSSProperties = {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: color,
    opacity: isActive ? 1 : 0.3,
    transition: 'opacity 0.3s',
    clipPath: `polygon(0 0, calc(100% - ${fixedOffset}px) 0, 100% 50%, calc(100% - ${fixedOffset}px) 100%, 0 100%, ${fixedOffset}px 50%)`,
  };

  return (
    <motion.button
      onClick={onClick}
      className="relative"
      animate={{ 
        scale,
        zIndex: isActive ? totalTabs : index,
      }}
      whileHover={{ scale: scale * 1.02 }}
      whileTap={{ scale: scale * 0.98 }}
      transition={{ duration: 0.2 }}
      style={buttonStyle}
    >
      <div style={shapeStyle} />
      <span className="relative z-10 px-2">{label}</span>
    </motion.button>
  );
};

interface Agrupador {
  nombre: string;
  fecha_inicio: string;
  fecha_termino: string;
  avance: number;
  subproceso: string;
}


const EmptySubprocessMessage: React.FC<{ subprocessName: string }> = ({ subprocessName }) => {
  return (
    <div className="flex flex-col items-center justify-center p-10 bg-white rounded-lg shadow-md">
      <FileQuestion size={60} className="text-teal-500 mb-4" />
      <h3 className="text-2xl font-bold text-teal-700 mb-2">Subproceso sin contenido</h3>
      <p className="text-teal-600 text-center mb-6">
        El subproceso "{subprocessName}" no tiene agrupadores definidos.
      </p>
    </div>
  );
};


const ProjectTimeline: React.FC<ProjectTimelineProps> = ({ selectedProcess, setSelectedProcess, processes, subprocessColor}) => {
  const [selectedNombreProyecto, setSelectedNombreProyecto] = useState('');
  const [selectedCodigoProyecto, setSelectedCodigoProyecto] = useState('');
  const [subprocesos, setSubprocesos] = useState<Subproceso[]>([]);
 // const [processes, setProcesses] = useState<Process[]>([]);
  const [plantillaDB, setPlantillaDB] = useState<PlantillaDB | null>(null);
  const [isProcessLoaded, setIsProcessLoaded] = useState(false);
  const [activeSubprocess, setActiveSubprocess] = useState<number>(0);
  const [processedPlantilla, setProcessedPlantilla] = useState<any>(null);
  const [allAgrupadores, setAllAgrupadores] = useState<Agrupador[]>([]);
  const [currentAgrupadores, setCurrentAgrupadores] = useState<Agrupador[]>([]);


  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return date.toLocaleDateString('es-CL', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).replace(/\//g, '-');
  };




  /*
  useEffect(() => {
    const fetchProcesses = async () => {
      try {
        const response = await fetch('http://localhost:3000/php/pages/proceso/get_processes.php?type=type3');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data: Process[] = await response.json();
        setProcesses(data);
      } catch (error) {
        console.error('Error fetching processes:', error);
      }
    };
    fetchProcesses();
  }, []);*/
  useEffect(() => {
    if (selectedProcess) {
      loadProcessData(selectedProcess);
    }
  }, [selectedProcess]);

  useEffect(() => {
    if (subprocesos.length > 0) {
      setActiveSubprocess(0);
      const firstSubprocess = subprocesos[0];
      const agrupadoresForFirstSubprocess = allAgrupadores.filter(agrupador => agrupador.subproceso === firstSubprocess.subproceso);
      setCurrentAgrupadores(agrupadoresForFirstSubprocess);
    } else {
      setActiveSubprocess(-1);
      setCurrentAgrupadores([]);
    }
  }, [subprocesos, allAgrupadores]);

  const loadProcessData = async (processName: string) => {
    if (processName === "") {
      // Reset all states
      setSelectedCodigoProyecto('');
      setSubprocesos([]);
      setAllAgrupadores([]);
      setCurrentAgrupadores([]);
      setIsProcessLoaded(false);
    } else {
      const selectedProcess = processes.find(p => p.nombreProceso === processName);
      if (selectedProcess) {
        setSelectedCodigoProyecto(selectedProcess.codigo);
        
        try {
          const plantillaNombre = await fetchPlantillaNombre(selectedProcess.codigo);
          if (plantillaNombre) {
            const plantillaContent = await fetchPlantilla(plantillaNombre);
            if (plantillaContent) {
              const processed = processPlantilla(plantillaContent);
              if (processed) {
                const { allAgrupadores, subprocesos } = processed;
                setAllAgrupadores(allAgrupadores);
                const fetchedSubprocesos = await fetchSubprocesos(selectedProcess.codigo);
                setSubprocesos(fetchedSubprocesos);
                if (subprocesos.length > 0) {
                  setCurrentAgrupadores(allAgrupadores.filter(agrupador => agrupador.subproceso === subprocesos[0]));
                } else {
                  setCurrentAgrupadores([]);
                }
                setIsProcessLoaded(true);
                setActiveSubprocess(0);
              } else {
                throw new Error('Error al procesar la plantilla');
              }
            } else {
              throw new Error('No se pudo obtener el contenido de la plantilla');
            }
          } else {
            throw new Error('No se pudo obtener el nombre de la plantilla');
          }
        } catch (error) {
          console.error('Error al cargar el proceso:', error);
          setIsProcessLoaded(false);
          setAllAgrupadores([]);
          setCurrentAgrupadores([]);
          setSubprocesos([]);
        }
      }
    }
  };


  const fetchPlantilla = async (nombrePlantilla: string) => {
    try {
      const response = await fetch(`http://localhost:3000/php/pages/adm_planificacion/get_plantilla.php?type=1&titulo=${encodeURIComponent(nombrePlantilla)}&tipoPlantilla=custom`);
      const data = await response.json();
      
      if (data && !data.error) {
        setPlantillaDB(data);
        return data.contenido;
      } else {
        console.error('Error al obtener la plantilla', data.error);
        setPlantillaDB(null);
        return null;
      }
    } catch (error) {
      console.error('Error de red', error);
      setPlantillaDB(null);
      return null;
    }
  };
  const processPlantilla = (plantillaContenido: any): { allAgrupadores: Agrupador[], subprocesos: string[] } | null => {
    let parsedContent;
    
    if (typeof plantillaContenido === 'string') {
      try {
        parsedContent = JSON.parse(plantillaContenido);
      } catch (error) {
        console.error('Error parsing plantillaContenido JSON:', error);
        return null;
      }
    } else {
      parsedContent = plantillaContenido;
    }

    const allAgrupadores: Agrupador[] = [];
    const subprocesos: string[] = [];

    parsedContent.subprocesos.forEach((subproceso: any) => {
      if (subproceso.nombre) {
        subprocesos.push(subproceso.nombre);
      }
      
      subproceso.agrupadores.forEach((agrupador: any) => {
        if (agrupador.enabled) {
          // Calculate average progress and date range for the agrupador
          const enabledTasks = agrupador.tareas.filter((tarea: any) => tarea.enabled);
          const totalProgress = enabledTasks.reduce((sum: number, tarea: any) => sum + tarea.avance, 0);
          const averageProgress = enabledTasks.length > 0 ? totalProgress / enabledTasks.length : 0;

          const startDates = enabledTasks.map((tarea: any) => new Date(tarea.fecha_inicio));
          const endDates = enabledTasks.map((tarea: any) => new Date(tarea.fecha_termino));
          const earliestStart = new Date(Math.min(...startDates));
          const latestEnd = new Date(Math.max(...endDates));

         allAgrupadores.push({
            nombre: agrupador.nombre,
            fecha_inicio: formatDate(earliestStart.toISOString()),
            fecha_termino: formatDate(latestEnd.toISOString()),
            avance: Math.round(averageProgress),
            subproceso: subproceso.nombre || ''
          });
        }
      });
    });

    return { allAgrupadores, subprocesos };
  };



  const fetchPlantillaNombre = async (codigoProyecto: string): Promise<string | null> => {
    try {
      const response = await fetch(`http://localhost:3000/php/pages/adm_planificacion/get_plantilla_nombre.php?codigo=${codigoProyecto}`);
      const data = await response.json();
      if (data && data.nombre_plantilla) {
        return data.nombre_plantilla;
      } else {
        console.error('Error al obtener el nombre de la plantilla', data);
        return null;
      }
    } catch (error) {
      console.error('Error de red', error);
      return null;
    }
  };

  const fetchSubprocesos = async (codigo: string) => {
    try {
      const response = await fetch(`http://localhost:3000/php/pages/transmittal/get_subprocesos.php?codigo=${codigo}`);
      const data = await response.json();
      if (data && typeof data === 'object' && Array.isArray(data.subprocesos)) {
        return data.subprocesos;
      } else {
        console.error('Error al obtener los subprocesos', data);
        return [];
      }
    } catch (error) {
      console.error('Error de red', error);
      return [];
    }
  };
  

  const handleSubprocessChange = (index: number) => {
    setActiveSubprocess(index);
    const selectedSubprocess = subprocesos[index];
    const agrupadoresForSubprocess = allAgrupadores.filter(agrupador => agrupador.subproceso === selectedSubprocess.subproceso);
    setCurrentAgrupadores(agrupadoresForSubprocess);
  };

  const lightenColor = (color: string, amount: number): string => {
    if (!color) return '#CCCCCC'; // Color por defecto si no se proporciona
    const num = parseInt(color.replace("#", ""), 16);
    const r = Math.min(255, Math.max(0, (num >> 16) + amount));
    const g = Math.min(255, Math.max(0, ((num >> 8) & 0x00FF) + amount));
    const b = Math.min(255, Math.max(0, (num & 0x0000FF) + amount));
    return `#${(1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1)}`;
  };
  
  const darkenColor = (color: string, amount: number): string => {
    return lightenColor(color || '#CCCCCC', -amount);
  };

  const rowVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: { 
      opacity: 1, 
      x: 0,
      transition: { duration: 0.3 }
    },
    exit: { 
      opacity: 0, 
      x: 20, 
      transition: { duration: 0.2 }
    }
  };

  const tableVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { 
        duration: 0.5,
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    },
    exit: { 
      opacity: 0, 
      y: -50, 
      transition: { 
        duration: 0.3,
        when: "afterChildren",
        staggerChildren: 0.05,
        staggerDirection: -1
      }
    }
  };

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className="bg-white w-full rounded-lg p-3 mx-auto"
    >
      {isProcessLoaded && (
        <div className="flex mb-8 mt-3">
          {subprocesos.map((subprocess, index) => (
            <SubprocessTab
                          key={index}
                          label={subprocess.subproceso}
                          isActive={activeSubprocess === index}
                          onClick={() => handleSubprocessChange(index)}
                          color={subprocess.color}
                          index={index}
                          totalTabs={subprocesos.length}
                          activeIndex={activeSubprocess}
                        />
          ))}
        </div>
      )
      
      }
        <AnimatePresence mode="wait">
        {isProcessLoaded && currentAgrupadores.length > 0 ? (
          <motion.div 
            key={activeSubprocess}
            variants={tableVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            className="overflow-x-auto shadow-lg"
          >
            <table className="min-w-full divide-y divide-gray-200">
              <thead style={{ 
                backgroundColor: darkenColor(
                  subprocesos[activeSubprocess]?.color || '#CCCCCC', 
                  10
                ) 
              }}>
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">ID</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Agrupador</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Fecha Inicio</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Fecha Término</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Progreso</th>
                </tr>
              </thead>
              <AnimatePresence>
                <motion.tbody className="bg-white divide-y divide-gray-200">
                  {currentAgrupadores.map((agrupador, index) => (
                    <motion.tr 
                      key={`${agrupador.subproceso}-${index}`}
                      variants={rowVariants}
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                    >
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{index + 1}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{agrupador.nombre}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{agrupador.fecha_inicio}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{agrupador.fecha_termino}</td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="w-full bg-gray-200 rounded-full h-2.5 mr-2">
                            <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${agrupador.avance}%` }}></div>
                          </div>
                          <span className="text-sm text-gray-500">{agrupador.avance}%</span>
                        </div>
                      </td>
                    </motion.tr>
                  ))}
                </motion.tbody>
              </AnimatePresence>
            </table>
            </motion.div>
            ) : (
              <EmptySubprocessMessage subprocessName={subprocesos[activeSubprocess]?.subproceso || ""} />
            )}
      </AnimatePresence>
    </motion.div>
  );
};
export default ProjectTimeline;