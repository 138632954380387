import React from 'react';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

export const Input: React.FC<InputProps> = ({ label, className = '', ...props }) => {
  return (
    <div className={`flex flex-col ${className}`}>
      {label && <label className="mb-2 text-sm font-medium text-gray-700">{label}</label>}
      <input 
        className="border rounded-md p-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500" 
        {...props} 
      />
    </div>
  );
};
