import React, { useState, useEffect, useMemo } from 'react';
import { ChevronLeft, ChevronRight, Paperclip, Eye, Trash, Edit } from 'lucide-react';

interface Subproceso {
  id: number;
  nombre: string;
}

interface Document {
  id: number;
  project: string;
  projectName: string;
  projectCode: string;
  document: string;
  title: string;
  revision: string;
  version: number;
  date: string;
  issuedBy: string;
  comment: string;
  path: string;
  flagged: boolean;
  subVersions: any[];
}

interface Transmittal {
  numero: number;
  codigo_proyecto: string;
  subprocesos: string[];
  comuna: string;
  emisor: string;
  destinatarios: string;
  cc: string;
  asunto: string;
  referencia: string;
  descripcion: string;
  subproceso: string;
  detalle_transmittal: string;
  documentos_seleccionados: string; // JSON en bruto
  estado: string;
  comentarios: string;
  codigo_transmittal: string;
  fecha: string;
  ruta: string;
}

const FormularioTransmittal: React.FC = () => {
  const [transmittals, setTransmittals] = useState<Transmittal[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
    const [subprocesos, setSubprocesos] = useState<Subproceso[]>([]);

  useEffect(() => {
    const fetchTransmittals = async () => {
      try {
        const response = await fetch('http://localhost:3000/php/pages/transmittal/get_transmittals.php');
        if (!response.ok) throw new Error('Network response was not ok');
        const data: Transmittal[] = await response.json();
        //alert(JSON.stringify(data));
        setTransmittals(data);
      } catch (error) {
        console.error('Failed to fetch transmittals:', error);
      }
    };

    fetchTransmittals();
  }, []);

  const totalPages = useMemo(() => Math.ceil(transmittals.length / rowsPerPage), [transmittals, rowsPerPage]);
  const paginatedTransmittals = useMemo(() => {
    if (!Array.isArray(transmittals)) {
      return [];
    }
    const startIndex = (currentPage - 1) * rowsPerPage;
    return transmittals.slice(startIndex, startIndex + rowsPerPage);
  }, [transmittals, currentPage, rowsPerPage]);
  
  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };





  const handleView = (ruta: string) => {
    window.open(`${ruta}`, '_blank'); // Abre el archivo en una nueva pestaña
  };
  



  const handleEdit = (transmittal: Transmittal) => {
    // Implement edit logic here
    console.log('Edit', transmittal);
  };

  const handleDelete = (transmittal: Transmittal) => {
    // Implement delete logic here
    console.log('Delete', transmittal);
  };


  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to first page on rows per page change
  };

  const extractDocuments = (json: string) => {
    try {
      const documents: Document[] = JSON.parse(json);
      return documents.map(doc => doc.path).join(', ');
    } catch (error) {
      console.error('Error parsing JSON:', error);
      return 'Error al procesar documentos';
    }
  };

  const openModal = (files: string[]) => {
    setSelectedFiles(files);
   
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedFiles([]);
  };

  const getEstadoClass = (estado: string) => {
    switch (estado.toLowerCase()) {
      case 'pendiente':
        return 'bg-red-100 text-red-800'; // Rojo
      case 'aprobado':
        return 'bg-green-100 text-green-800'; // Verde
      case 'en tramite':
        return 'bg-yellow-100 text-yellow-800'; // Amarillo
      default:
        return 'bg-gray-100 text-gray-800'; // Color por defecto
    }
  };

  return (
    <div className="font-sans  w-full mx-auto bg-white">
      <div className="bg-white p-6 rounded-lg">
        <h2 className="text-xl font-semibold mb-4 text-cyan-600">Historial de Transmittals</h2>
        <div className="mb-4 flex justify-between items-center">
          <div>
            <label htmlFor="rowsPerPage" className="mr-2">Filas por página:</label>
            <select
              id="rowsPerPage"
              value={rowsPerPage}
              onChange={handleRowsPerPageChange}
              className="border rounded p-1"
            >
              {[5, 10, 20, 50].map(option => (
                <option key={option} value={option}>{option}</option>
              ))}
            </select>
          </div>
          <div>
            Página {currentPage} de {totalPages}
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="w-full border-collapse table-auto">
            <thead>
              <tr className="bg-cyan-100">
                <th className="border p-2 text-left whitespace-nowrap">#</th>
                <th className="border p-2 text-left whitespace-nowrap">Código Transmittal</th>
                
                <th className="border p-2 text-left whitespace-nowrap">Asunto</th>
               { /*<th className="border p-2 text-left whitespace-nowrap">Descripción</th>*/}
                <th className="border p-2 text-left whitespace-nowrap">Subproceso</th>
                <th className="border p-2 text-left whitespace-nowrap">Propósito TRL</th>
                <th className="border p-2 text-left whitespace-nowrap">Docs.</th>
                <th className="border p-2 text-left whitespace-nowrap">Fecha</th>
                {/*<th className="border p-2 text-left whitespace-nowrap">Comentarios</th>*/}
                {/*<th className="border p-2 text-left whitespace-nowrap">Estado</th>*/}
                <th className="border p-2 text-left whitespace-nowrap">Acción</th>
              </tr>
            </thead>
            <tbody>
              {paginatedTransmittals.map(t => (
                <tr key={t.numero} className="hover:bg-gray-50">
                  <td className="border p-2 whitespace-nowrap">{t.numero}</td>
                  <td className="border p-2 whitespace-nowrap">{t.codigo_transmittal}</td>
                  
                  <td className="border p-2 whitespace-nowrap">{t.asunto}</td>
                  {/*<td className="border p-2 whitespace-nowrap">{t.descripcion}</td>*/}
                  <td className="border p-2 whitespace-nowrap">{t.subproceso}</td>
                  <td className="border p-2 whitespace-nowrap">
                    {Object.entries(JSON.parse(t.detalle_transmittal))
                      .filter(([, value]) => value === true)
                      .map(([key]) => (
                        <label key={key}>&nbsp;&nbsp;
                          <input type="checkbox" checked readOnly /> {key.replace(/\\u00f3n/g, 'ón')}
                        </label>
                      ))}
                  </td>
                  <td className="border p-2 whitespace-nowrap">
                    <button onClick={() => openModal(JSON.parse(t.documentos_seleccionados).map((doc: Document) => doc.path))}>
                      <Paperclip className="w-5 h-5" />
                    </button>
                  </td>
                  <td className="border p-2 whitespace-nowrap">{t.fecha}</td>
                  {/*<td className="border p-2 whitespace-nowrap">{t.comentarios}</td>*/}
                  {/*<td className={`border p-2 whitespace-nowrap ${getEstadoClass(t.estado)}`}>
                    {t.estado}
                  </td>*/}
                  <td className="border p-2 whitespace-nowrap flex space-x-2">
                    <button onClick={() => handleView(t.ruta)} className="text-cyan-500 hover:text-cyan-700">
                      <Eye size={20} />
                    </button>
                    <button onClick={() => handleEdit(t)} className="text-yellow-500 hover:text-yellow-700">
                      <Edit size={20} />
                    </button>
                    <button onClick={() => handleDelete(t)} className="text-red-500 hover:text-red-700">
                      <Trash size={20} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="mt-4 flex justify-center items-center space-x-2">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="p-2 border rounded hover:bg-gray-100 disabled:opacity-50"
          >
            <ChevronLeft size={20} />
          </button>
          {[...Array(totalPages)].map((_, index) => {
            const page = index + 1;
            return (
              <button
                key={page}
                onClick={() => handlePageChange(page)}
                className={`p-2 border rounded ${page === currentPage ? 'bg-cyan-500 text-white' : 'hover:bg-gray-100'}`}
              >
                {page}
              </button>
            );
          })}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="p-2 border rounded hover:bg-gray-100 disabled:opacity-50"
          >
            <ChevronRight size={20} />
          </button>
        </div>
      </div>
      {modalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-4 rounded-lg shadow-lg max-w-lg w-full">
            <h2 className="text-xl font-semibold mb-4">Documentos Adjuntos</h2>
            <ul>
            {selectedFiles.map((file, index) => (
  <li key={index} className="mb-2">
    <button onClick={() => handleView(file)} className="text-blue-500 underline">
      {file}
    </button>
  </li>
))}


            </ul>
            <button onClick={closeModal} className="mt-4 bg-cyan-500 text-white px-4 py-2 rounded-md hover:bg-cyan-600 transition duration-300">Cerrar</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormularioTransmittal;
