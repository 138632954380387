import React, { useState, useEffect, useRef, useCallback, ChangeEvent } from 'react';
import { Card, CardHeader, CardContent } from '../../components/ui/card';
import { Button } from '../../components/ui/button';
import { Input } from '../../components/ui/input';
import GanttChartView from './ganttChartView';
import { CustomAlert } from '../../components/ui/customAlert';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from '../../components/ui/dialog';
import TemplateSelectionInterface from './TemplateSelectionInterface';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import { ChevronRight, ChevronDown, Plus, Trash2, ArrowUp, ArrowDown, Minimize, Maximize, ArrowLeftCircle, Save, BarChart2, GanttChart, GanttChartIcon, LucideGanttChart, GanttChartSquareIcon } from 'lucide-react';
import toast, { Toaster } from 'react-hot-toast';

// Interfaces
interface SelectOption {
  value: string;
  label: string;
}

interface Template {
  id: string;
  name: string;
}

interface Task {
  id: number;
  name: string;
  responsible: string;
  progress: number;
  start: string;
  end: string;
  descriptor: string;
  organism: string;
  dependsOn: { groupId: number; taskId: number }[];
  enabled: boolean; // Nueva propiedad

}

interface Group {
  name: string;
  expanded: boolean;
  subprocess: string;
  agrupador: string;
  tasks: Task[];
  enabled: boolean; // Nueva propiedad
  descriptor: 'GESTIÓN' | 'PERMISOLOGÍA'; 
  organismo: string;

}

interface TasksState {
  name: string;
  groups: Group[];
}

interface EditingField {
  type: 'title' | 'group' | 'task' | null;
  groupIndex: number | null;
  taskIndex: number | null;
  field: string | null;
}


interface LabelMessageProps {
  type: 'success' | 'error';
  message: string;
}

interface Process {
  nombreProceso: string;
  codigo: string;
  comuna: string;
  subprocesos?: string[]; // Añadimos esta propiedad
}

interface Subproceso {
  id: number;
  subproceso: string;
  color?: string; // Agregamos el campo color

}

const LabelMessage: React.FC<LabelMessageProps> = ({ type, message }) => {
  const [progress, setProgress] = useState(100);
  
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress <= 0) {
          clearInterval(timer);
          return 0;
        }
        return prevProgress - 1;
      });
    }, 31); // Ajusta este valor para cambiar la velocidad de la animación

    return () => clearInterval(timer);
  }, []);

  const bgColor = type === 'success' ? 'bg-green-500' : 'bg-red-500';
  const borderColor = type === 'success' ? 'border-green-600' : 'border-red-600';
  const textColor = 'text-white';

  return (
    <div className={`${bgColor} ${borderColor} ${textColor} px-4 py-3 rounded relative mb-4 border-l-4 overflow-hidden`} role="alert">
      <span className="block sm:inline">{message}</span>
      <div 
        className={`absolute bottom-0 left-0 h-1 ${type === 'success' ? 'bg-green-700' : 'bg-red-700'}`} 
        style={{ width: `${progress}%`, transition: 'width 0.1s linear' }}
      ></div>
    </div>
  );
};



const AdvancedGanttChart: React.FC = () => {
  const [tasks, setTasks] = useState<TasksState>({
    name: "Plantilla 1",
    groups: []
  });
  const [editingField, setEditingField] = useState<EditingField>({ type: null, groupIndex: null, taskIndex: null, field: null });
  const [editValue, setEditValue] = useState("");
  const [isGloballyExpanded, setIsGloballyExpanded] = useState(true);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isSaveAsOpen, setIsSaveAsOpen] = useState(false);
  const [templateName, setTemplateName] = useState('');
  const [templateDescription, setTemplateDescription] = useState('');
  const [showSelection, setShowSelection] = useState(true);
  const [labelMessage, setLabelMessage] = useState<{ type: 'success' | 'error', message: string } | null>(null);
  const [saveAsError, setSaveAsError] = useState<string | null>(null);
const [isOverwriteConfirmOpen, setIsOverwriteConfirmOpen] = useState(false);
const [selectedProcess, setSelectedProcess] = useState('');
const [processes, setProcesses] = useState<Process[]>([]);
const [selectedCodigoProyecto, setSelectedCodigoProyecto] = useState('');
const [selectedNombreProyecto, setSelectedNombreProyecto] = useState('');
const [subprocesos, setSubprocesos] = useState<Subproceso[]>([]);
const [subprocessColors, setSubprocessColors] = useState<{[key: string]: string}>({});

const [suggestedSubprocesses, setSuggestedSubprocesses] = useState<{ [key: string]: string }>({});
const [isSimagiTemplate, setIsSimagiTemplate] = useState(false);
const [isProcessLoaded, setIsProcessLoaded] = useState(false);
const [plantillaSubprocesos, setPlantillaSubprocesos] = useState<string[]>([]);
const [showGanttModal, setShowGanttModal] = useState(false);





useEffect(() => {
  setSelectedCodigoProyecto('');
    setSelectedNombreProyecto('');
    setSubprocesos([]);
  const fetchProcesses = async () => {
    try {
      const response = await fetch('http://localhost:3000/php/pages/proceso/get_processes.php?type=type3');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data: Process[] = await response.json();
      
      setProcesses(data);
    } catch (error) {
      console.error('Error fetching processes:', error);
    }
  };
  fetchProcesses();
}, []);



 // Array de colores predefinidos distintos
 const distinctColors = [
  '#D32F2F', // Reemplazo de Light Red (Rojo oscuro)
  '#FF8C00', // Reemplazo de Rojo anaranjado (Naranja oscuro)
  '#FFD700', // Oro
  '#45B7D1', // Light Blue
  '#FFA07A', // Light Salmon
  '#98D8C8', // Verde mar claro
  '#7E57C2', // Purple
  '#FFD54F', // Light Yellow
  '#4DB6AC', // Teal claro
  '#9CCC65', // Verde claro
  '#5C6BC0', // Blue
  '#FFF176', // Yellow
  '#FF1493', // Rosa fuerte
  '#F8BBD0', // Light Pink
  '#DCE775', // Lime Green
  '#7986CB', // Indigo
  '#FFB74D', // Naranja
  '#4FC3F7', // Sky Blue
  '#A1887F', // Brown
  '#BA68C8', // Purple
  '#FFD180', // Amber
  '#64B5F6'  // Light Blue
];

// Función para obtener un color distinto
const getDistinctColor = (index: number) => {
  return distinctColors[index % distinctColors.length];
};

// Función para asignar colores únicos a subprocesos
const assignSubprocessColors = useCallback(() => {
  const colors: {[key: string]: string} = {};
  subprocesos.forEach((subproceso, index) => {
    if (!colors[subproceso.subproceso]) {
      colors[subproceso.subproceso] = subproceso.color || getDistinctColor(index);
    }
  });
  setSubprocessColors(colors);
}, [subprocesos]);

useEffect(() => {
  assignSubprocessColors();
}, [subprocesos, assignSubprocessColors]);


const fetchSubprocesos = async (codigo: string) => {
  try {
    const response = await fetch(`http://localhost:3000/php/pages/transmittal/get_subprocesos.php?codigo=${codigo}`);
    const data = await response.json();
   
    if (data && typeof data === 'object' && Array.isArray(data.subprocesos)) {
      const subprocesosWithColors = data.subprocesos.map((subproceso: Subproceso, index: number) => ({
        ...subproceso,
        color: subproceso.color || getDistinctColor(index)
      }));
      setSubprocesos(subprocesosWithColors);
    } else {
      console.error('Error al obtener los subprocesos', data);
      setSubprocesos([]);
    }
  } catch (error) {
    console.error('Error de red', error);
    setSubprocesos([]);
  }
};





const handleProcessChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  const selectedValue = event.target.value;
  if (selectedValue === "") {
    // "Asignar proceso" was selected
    setSelectedCodigoProyecto('');
    setSelectedNombreProyecto('');
    setSubprocesos([]);
  } else {
    const selectedProcess = processes.find(p => p.nombreProceso === selectedValue);
    if (selectedProcess) {
      setSelectedCodigoProyecto(selectedProcess.codigo);
      setSelectedNombreProyecto(selectedProcess.nombreProceso);
      
      // Cargar subprocesos
      fetchSubprocesos(selectedProcess.codigo);
    }
  }
};

const handleSubprocessChange = (groupIndex: number, e: React.ChangeEvent<HTMLSelectElement>) => {
  const selectedSubprocess = e.target.value;
  setTasks(prevTasks => ({
    ...prevTasks,
    groups: prevTasks.groups.map((group, index) => 
      index === groupIndex ? { ...group, subprocess: selectedSubprocess } : group
    )
  }));
};

const renderSubprocessSelect = (group: Group, groupIndex: number) => {
  const suggestedSubprocess = suggestedSubprocesses[group.agrupador] || "";
  
  const getTextColor = (backgroundColor: string): string => {
    // Convert the hex color to RGB
    const r = parseInt(backgroundColor.slice(1, 3), 16);
    const g = parseInt(backgroundColor.slice(3, 5), 16);
    const b = parseInt(backgroundColor.slice(5, 7), 16);

    // Calculate the luminosity
    const luma = 0.299 * r + 0.587 * g + 0.114 * b;

    // Return the text color based on luminosity
    return luma < 128 ? 'white' : 'black';
};

const getSubprocessDescriptor = (group: Group): string | null => {
  if (group.tasks.length === 0) return null;
  const firstTaskDescriptor = group.tasks[0].descriptor;
  return group.tasks.every(task => task.descriptor === firstTaskDescriptor) ? firstTaskDescriptor : null;
};
const subprocessDescriptor = getSubprocessDescriptor(group);
const subprocessColor = subprocessColors[group.subprocess] || "";
const textColor: string = getTextColor(subprocessColor);

   
return (
  <div className="flex flex-col p-2 rounded-lg" style={{backgroundColor: subprocessColor}}>
        <span className={`font-bold flex mr-2 text-${textColor}`}>Subproceso:</span>
        <select
          value={group.subprocess}
          onChange={(e) => handleSubprocessChange(groupIndex, e)}
          className={`border rounded-md p-2 ${!group.enabled ? 'bg-gray-200 text-gray-500' : 'bg-white'}`}
          disabled={!group.enabled}
        >
          <option value="">Sin subproceso</option>
          {subprocesos.map((subprocess) => (
            <option key={subprocess.id} value={subprocess.subproceso}>
              {subprocess.subproceso}
            </option>
          ))}
        </select>
        {suggestedSubprocess && !group.subprocess && (
          <span className="text-sm text-gray-500 mt-1">
            Sugerencia: {suggestedSubprocess}
          </span>
        )}
      </div>
    );
  };

  const showLabelMessage = (type: 'success' | 'error', message: string, duration = 3500) => {
    setLabelMessage({ type, message });
    setTimeout(() => {
      setLabelMessage(null);
    }, duration);
  };


  const [selectedProject, setSelectedProject] = useState<string>('');



  const updateProcessTemplate = async (processId: string, templateName: string) => {
    try {
      const response = await fetch('http://localhost:3000/php/pages/proceso/update_process_template.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ processId, templateName }),
      });
  
      if (!response.ok) {
        throw new Error('Error al actualizar la plantilla del proceso');
      }
  
      const result = await response.json();
      console.log('Plantilla del proceso actualizada:', result);
      showLabelMessage('success', 'Plantilla del proceso actualizada exitosamente');
    } catch (error) {
      console.error('Error:', error);
      showLabelMessage('error', 'Error al actualizar la plantilla del proceso');
    }
  };
 

  const convertTemplateToTasksState = (jsonData: any): TasksState => {
    const content = typeof jsonData.contenido === 'string' 
      ? JSON.parse(jsonData.contenido) 
      : jsonData.contenido;
    
     // alert(JSON.stringify(content))
    console.log('Contenido de la plantilla en convertTemplateToTasksState:', content);
  
    const newSuggestedSubprocesses: { [key: string]: string } = {};
    const newPlantillaSubprocesos: string[] = [];
    
    const groups = content.subprocesos.flatMap((subproceso: any, index: number) => 
      subproceso.agrupadores.map((agrupador: any): Group => {
        // Check if the subprocess exists in the fetched subprocesses
        const subprocessExists = subprocesos.some(sp => sp.subproceso === subproceso.nombre);
        
        // Add to plantillaSubprocesos regardless of existence
        newPlantillaSubprocesos[index] = subproceso.nombre;
        
        // If it doesn't exist, add it to suggested subprocesses
        if (!subprocessExists) {
          newSuggestedSubprocesses[agrupador.nombre] = subproceso.nombre;
        }
      
        return {
          name: agrupador.nombre,
          expanded: true,
          subprocess: subprocessExists ? subproceso.nombre : "", // Set to empty if not in fetched subprocesses
          agrupador: agrupador.nombre,
          enabled: agrupador.enabled !== undefined ? agrupador.enabled : false,
          descriptor: agrupador.descriptor || 'GESTIÓN', // Asegúrate de cargar el descriptor
          organismo: '',
          tasks: agrupador.tareas.map((tarea: any): Task => ({
            id: tarea.id,
            name: tarea.nombre,
            responsible: tarea.responsable || '',
            progress: tarea.avance || 0,
            start: tarea.fecha_inicio,
            end: tarea.fecha_termino,
            dependsOn: tarea.dependencia ? [{ groupId: 0, taskId: tarea.dependencia }] : [],
            descriptor: tarea.descriptor,
            organism: tarea.organismo,
            enabled:  tarea.enabled !== undefined ? tarea.enabled : false,
          }))
        };
      })
    );
  
    console.log('Grupos convertidos:', groups);
    console.log('Nuevos subprocesos sugeridos:', newSuggestedSubprocesses);
    console.log('Nuevos subprocesos de la plantilla:', newPlantillaSubprocesos);
  
    // Update states
    setSuggestedSubprocesses(newSuggestedSubprocesses);
    setPlantillaSubprocesos(newPlantillaSubprocesos);
    
    return {
      name: content.nombre,
      groups: groups
    };
  };


  const handleProjectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const projectId = e.target.value;
    setSelectedProject(projectId);
    // Aquí podrías cargar los subprocesos correspondientes al proyecto seleccionado
  };
 

  const renderOrganismRow = (group: Group, groupIndex: number) => {
    const organism = group.tasks.length > 0 ? group.tasks[0].organism : '';
    const descriptor = group.tasks.length > 0 ? group.tasks[0].descriptor : '';
    const subprocessColor = subprocessColors[group.subprocess] || "#CCCCCC";
   // alert(descriptor)
    return (
      <div className=" ml-5 px-4 text-sm text-white">
          <div style={{ backgroundColor: darkenColor(subprocessColor, 60) }} className="text-white inline-flex items-center rounded-full px-3  text-sm font-semibold ml-2">
          <span className="mr-2  text-white">{descriptor === "PERMISOLOGÍA" ? "Organismo de Tramitación:" : "Gestión con:"}</span>
          {renderEditableField('group', groupIndex, null, 'organism', organism)}
        </div>
      </div>
    );
  };



  const updateTemplateName = async (newName: string) => {
    try {
      const response = await fetch('http://localhost:3000/php/pages/adm_planificacion/update_template_name.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          nombreOriginal: tasks.name,
          nuevoNombre: newName,
          proceso: selectedCodigoProyecto
        }),
      });
  
      if (!response.ok) {
        throw new Error('Error al actualizar el nombre de la plantilla');
      }
  
      const result = await response.json();
      if (result.success) {
        console.log('Nombre de la plantilla actualizado:', result);
        showLabelMessage('success', 'Nombre de la plantilla actualizado exitosamente');
      } else {
        throw new Error(result.error || 'Error al actualizar el nombre de la plantilla');
      }
    } catch (error) {
      console.error('Error:', error);
      showLabelMessage('error', 'Error al actualizar el nombre de la plantilla');
    }
  };

  const fetchPlantilla = async (templateName: string, templateType: 'SIMAGI' | 'custom') => {
    try {
      const response = await fetch(`http://localhost:3000/php/pages/adm_planificacion/get_plantilla.php?type=1&titulo=${encodeURIComponent(templateName)}&tipoPlantilla=${templateType}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (data.error) {
        throw new Error(data.error);
      }
      const content = typeof data.contenido === 'string' ? JSON.parse(data.contenido) : data.contenido;
      const processCode = content.proceso;

      if (processCode) {
        await updateSelectedProcess(processCode);
      }
      const convertedTasks = convertTemplateToTasksState(data);
      setTasks(convertedTasks);

      // Extraer y guardar los subprocesos de la plantilla
      const plantillaSubprocesos = content.subprocesos.map((subproceso: any) => subproceso.nombre);
      setPlantillaSubprocesos(plantillaSubprocesos);

    } catch (error) {
      console.error('Error fetching plantilla:', error);
    }
  };

    // Añadir un efecto para actualizar los subprocesos de los grupos cuando se carga la plantilla
    useEffect(() => {
      if (plantillaSubprocesos.length > 0 && subprocesos.length > 0) {
        setTasks(prevTasks => ({
          ...prevTasks,
          groups: prevTasks.groups.map((group, index) => {
            const plantillaSubproceso = plantillaSubprocesos[index] || "";
            const matchingSubprocess = subprocesos.find(sp => sp.subproceso === plantillaSubproceso);
            return {
              ...group,
              subprocess: matchingSubprocess ? matchingSubprocess.subproceso : ""
            };
          })
        }));
      }
    }, [plantillaSubprocesos, subprocesos]);


  const updateSelectedProcess = useCallback(async (processCode: string) => {
    const selectedProcess = processes.find(p => p.codigo === processCode);
    if (selectedProcess) {
      setSelectedCodigoProyecto(selectedProcess.codigo);
      setSelectedNombreProyecto(selectedProcess.nombreProceso);
      await fetchSubprocesos(selectedProcess.codigo);
      setIsProcessLoaded(true);
    }
  }, [processes]);


  const handleTemplateSelected = (selection: 
    { type: 'new' } | 
    { type: 'template'; templateName: string; templateType: 'SIMAGI' | 'custom' }
  ) => {
    if (selection.type === 'new') {
      setTasks({
        name: "Nueva plantilla",
        groups: []
      });
    } else if (selection.type === 'template') {
      setTasks({
        name: selection.templateName,
        groups: []
      });
      fetchPlantilla(selection.templateName, selection.templateType);
    }
    setShowSelection(false);
  };

  const calculateDuration = (start: string, end: string): number => {
    const startDate = new Date(start + 'T00:00:00Z');
    const endDate = new Date(end + 'T00:00:00Z');
    const diffTime = Math.abs(endDate.getTime() - startDate.getTime());
    return diffTime / (1000 * 60 * 60 * 24 * 7);
  };

  const formatDuration = (duration: number): string => {
    return duration < 1 ? duration.toFixed(2) : Math.round(duration).toString();
  };

  const calculateNewEndDate = (start: string, durationWeeks: number): string => {
    const startDate = new Date(start + 'T00:00:00Z');
    const endDate = new Date(startDate.getTime() + durationWeeks * 7 * 24 * 60 * 60 * 1000);
    return endDate.toISOString().split('T')[0];
  };

  const updateGroup = (groupIndex: number, field: string, value: any) => {
    setTasks(prevTasks => ({
      ...prevTasks,
      groups: prevTasks.groups.map((group, index) => 
        index === groupIndex ? { ...group, [field]: value } : group
      )
    }));
  };

  const updateTask = (groupIndex: number, taskIndex: number, field: string, value: any) => {
    setTasks(prevTasks => ({
      ...prevTasks,
      groups: prevTasks.groups.map((group, gIndex) => 
        gIndex === groupIndex
          ? {
              ...group,
              tasks: group.tasks.map((task, tIndex) => 
                tIndex === taskIndex ? { ...task, [field]: value } : task
              )
            }
          : group
      )
    }));
  };

  const toggleGroup = (index: number) => {
    updateGroup(index, 'expanded', !tasks.groups[index].expanded);
  };

  const updateDependenciesOnMove = (groupIndex: number, oldIndex: number, newIndex: number) => {
    setTasks(prevTasks => {
      const newTasks = JSON.parse(JSON.stringify(prevTasks));
      const group = newTasks.groups[groupIndex];
      const movedTaskId = group.tasks[oldIndex].id;

      group.tasks.forEach((task: Task) => {
        task.dependsOn = task.dependsOn.map(dep => {
          if (dep.groupId === groupIndex) {
            if (dep.taskId === movedTaskId) {
              return { ...dep, taskId: group.tasks[newIndex].id };
            } else if (oldIndex < newIndex) {
              if (dep.taskId > group.tasks[oldIndex].id && dep.taskId <= group.tasks[newIndex].id) {
                return { ...dep, taskId: dep.taskId - 1 };
              }
            } else if (oldIndex > newIndex) {
              if (dep.taskId >= group.tasks[newIndex].id && dep.taskId < group.tasks[oldIndex].id) {
                return { ...dep, taskId: dep.taskId + 1 };
              }
            }
          }
          return dep;
        });
      });

      return newTasks;
    });
  };

  const handleSave = (type: 'title' | 'group' | 'task', groupIndex: number | null, taskIndex: number | null, field: string, value: any) => {
    if (type === 'title') {
      setTasks(prevTasks => ({ ...prevTasks, name: value }));
      updateTemplateName(value);
      
    }
    if (type === 'group' && groupIndex !== null) {
      if (field === 'organism') {
        // Actualizar el organismo para todas las tareas del grupo
        setTasks(prevTasks => ({
          ...prevTasks,
          groups: prevTasks.groups.map((group, index) => 
            index === groupIndex
              ? {
                  ...group,
                  tasks: group.tasks.map(task => ({ ...task, organism: value }))
                }
              : group
          )
        }));
      } else {
        updateGroup(groupIndex, field, value);
      }
    } else if (type === 'task' && groupIndex !== null && taskIndex !== null) {
      if (field === 'start' || field === 'end') {
        handleDateChange(groupIndex, taskIndex, field as 'start' | 'end', value);
      } else if (field === 'duration') {
        const newDuration = parseInt(value);
        if (!isNaN(newDuration)) {
          const task = tasks.groups[groupIndex].tasks[taskIndex];
          const newEnd = calculateNewEndDate(task.start, newDuration);
          handleDateChange(groupIndex, taskIndex, 'end', newEnd);
        }
      } else if (field === 'dependsOn') {
        let newDependencies: { groupIndex: number; taskId: number }[];
        
        if (typeof value === 'string') {
          newDependencies = value.split(',')
            .map(id => id.trim())
            .filter(id => id !== '')
            .map(id => ({ groupIndex, taskId: parseInt(id) }))
            .filter(dep => !isNaN(dep.taskId));
        } else if (Array.isArray(value)) {
          newDependencies = value.map(taskId => ({ groupIndex, taskId: parseInt(taskId) }));
        } else {
          newDependencies = [];
        }
        newDependencies = newDependencies.filter(dep => dep.taskId !== tasks.groups[groupIndex].tasks[taskIndex].id);
        
        let latestEndDate: string | null = null;
        newDependencies.forEach(({ taskId }) => {
          const depTask = findTaskById(groupIndex, taskId);
          if (depTask && (!latestEndDate || new Date(depTask.end) > new Date(latestEndDate))) {
            latestEndDate = depTask.end;
          }
        });
        
        if (latestEndDate) {
          const newStart = addDaysToDate(latestEndDate, 1);
          handleDateChange(groupIndex, taskIndex, 'start', newStart);
        }
        
        updateTask(groupIndex, taskIndex, field, newDependencies);
      } else {
        updateTask(groupIndex, taskIndex, field, value);
      }
    }
    setEditingField({ type: null, groupIndex: null, taskIndex: null, field: null });
    setEditValue("");
  };
  

  const handleOverwriteConfirm = () => {
    setIsOverwriteConfirmOpen(false);
    handleSaveAs(true);
  };
  
  const handleOverwriteCancel = () => {
    setIsOverwriteConfirmOpen(false);
  };


  const handleDescriptorChange = (groupIndex: number, newDescriptor: 'GESTIÓN' | 'PERMISOLOGÍA') => {
    setTasks(prevTasks => ({
      ...prevTasks,
      groups: prevTasks.groups.map((group, index) => 
        index === groupIndex
          ? {
              ...group,
              descriptor: newDescriptor,
              tasks: group.tasks.map(task => ({ ...task, descriptor: newDescriptor }))
            }
          : group
      )
    }));
  };
  

 // Update the handleSaveToBackend function
 const handleSaveToBackend = async () => {
  try {
    const plantillaData = {
      nombre: tasks.name,
      descripcion: "Plantilla actualizada",
      contenido: JSON.stringify({
        nombre: tasks.name,
        proceso: selectedCodigoProyecto,
        subprocesos: tasks.groups.map(group => ({
          nombre: group.subprocess,
          agrupadores: [{
            nombre: group.agrupador,
            enabled: group.enabled,
            descriptor: group.descriptor,
            organismo: group.organismo,
            tareas: group.tasks.map(task => ({
              id: task.id,
              nombre: task.name,
              responsable: task.responsible,
              avance: task.progress,
              fecha_inicio: task.start,
              fecha_termino: task.end,
              descriptor: task.descriptor,
              organismo: task.organism,
              dependencia: task.dependsOn.length > 0 ? task.dependsOn[0].taskId : null,
              enabled: task.enabled
            }))
          }]
        }))
      }),
      tipo: 'custom',
      proceso: selectedCodigoProyecto
    };

    //  alert(JSON.stringify(plantillaData))
      console.log(JSON.stringify(plantillaData))
      const response = await fetch('http://localhost:3000/php/pages/adm_planificacion/update_plantilla.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(plantillaData),
      });
  
      if (!response.ok) {
        throw new Error('Error al actualizar la plantilla');
      }
  
      const result = await response.json();

      console.log('Plantilla actualizada:', result);
      showLabelMessage('success', 'Plantilla guardada exitosamente');
    } catch (error) {
      console.error('Error:', error);
      showLabelMessage('error', 'Error al guardar la plantilla');
    }
  };
  

  const findTaskById = (groupIndex: number, taskId: number): Task | null => {
    const group = tasks.groups[groupIndex];
    if (group) {
      return group.tasks.find(task => task.id === taskId) || null;
    }
    return null;
  };

  const addDaysToDate = (dateString: string, daysToAdd: number): string => {
    const date = new Date(dateString);
    date.setDate(date.getDate() + daysToAdd);
    return date.toISOString().split('T')[0];
  };

  const updateDependentTasks = (updatedGroups: Group[], changedGroupIndex: number, changedTaskId: number, shift: number) => {
    const updateTask = (group: Group, task: Task, taskIndex: number) => {
      if (task.dependsOn.some(dep => dep.groupId === changedGroupIndex && dep.taskId === changedTaskId)) {
        const newStartDate = new Date(task.start);
        newStartDate.setDate(newStartDate.getDate() + shift);
        const newStart = newStartDate.toISOString().split('T')[0];
        const duration = calculateDuration(task.start, task.end);
        const newEnd = calculateNewEndDate(newStart, duration);
        
        task.start = newStart;
        task.end = newEnd;

        updateDependentTasks(updatedGroups, changedGroupIndex, task.id, shift);
      }
    };

    const group = updatedGroups[changedGroupIndex];
    if (group) {
      group.tasks.forEach((task, taskIndex) => updateTask(group, task, taskIndex));
    }
  };




  

  const renderEditableField = (type: 'title' | 'group' | 'task', groupIndex: number | null, taskIndex: number | null, field: string, value: any) => {
    
    
    const isEditing = editingField.type === type && 
                      editingField.groupIndex === groupIndex && 
                      editingField.taskIndex === taskIndex && 
                      editingField.field === field;

                      const group = groupIndex !== null ? tasks.groups[groupIndex] : null;
                      const task = taskIndex !== null && group ? group.tasks[taskIndex] : null;
                      const isDisabled = (type === 'group' && !group?.enabled) || (type === 'task' && (!group?.enabled || !task?.enabled));
                  
                      const startEditing = () => {
                        if (isDisabled) return;
      
                        let initialValue = value;
                        if (type === 'task' && groupIndex !== null && taskIndex !== null) {
                          if (field === 'start' || field === 'end') {
                            initialValue = formatDateForEdit(value);
                          } else if (field === 'dependsOn') {
                            if (Array.isArray(value)) {
                              initialValue = value.map(dep => dep.taskId).join(', ');
                            } else if (typeof value === 'string') {
                              initialValue = value;
                            } else {
                              initialValue = '';
                            }
                          } else if (field === 'duration') {
                            const group = tasks.groups[groupIndex];
                            if (group && group.tasks[taskIndex]) {
                              const task = group.tasks[taskIndex];
                              initialValue = formatDuration(calculateDuration(task.start, task.end));
                            }
                          }
                        }
                        setEditingField({ type, groupIndex, taskIndex, field });
                        setEditValue(initialValue);
                  
                        setTimeout(() => {
                          if (inputRef.current) {
                            inputRef.current.select();
                          }
                        }, 0);
                      };
                  
                      if (isEditing) {
                        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                          setEditValue(e.target.value);
                        };
                    
                        const saveValue = () => {
                          
                          handleSave(type, groupIndex, taskIndex, field, editValue);

                        };
                    
                        return (
                          <input
                            ref={inputRef}
                            type={field === 'start' || field === 'end' ? 'date' : 'text'}
                            value={editValue}
                            onChange={handleChange}
                            onBlur={saveValue}
                            onKeyPress={(e) => {
                              if (e.key === 'Enter') {
                                saveValue();
                              }
                            }}
                            className="border-none font-bold text-center bg-white text-black rounded"
                            style={{
                              width: `${Math.max(editValue.length * 11, 50)}px`,
                              maxWidth: '100%',
                            }}
                            autoFocus
                          />
                        );
                      }
                    
                      let displayValue = value;
                      if (type === 'task' && groupIndex !== null && taskIndex !== null) {
                        if (field === 'start' || field === 'end') {
                          displayValue = formatDateForDisplay(value);
                        } else if (field === 'dependsOn') {
                          if (Array.isArray(value)) {
                            displayValue = value.map(dep => dep.taskId).join(', ');
                            
                          } else if (typeof value === 'string') {
                            displayValue = value;
                            
                          } else {
                            displayValue = '';
                          }
                        } else if (field === 'duration') {
                          const group = tasks.groups[groupIndex];
                          if (group && group.tasks[taskIndex]) {
                            const task = group.tasks[taskIndex];
                            const duration = calculateDuration(task.start, task.end);
                            displayValue = `${formatDuration(duration)} semanas`;
                          } else {
                            displayValue = 'N/A';
                          }
                        }
                      }
                      const isEmpty = !displayValue || displayValue.trim() === '';

                      return (
                        <span
                          className={`cursor-pointer hover:bg-gray-100 hover:text-black p-1 rounded ${
                            isDisabled ? 'cursor-not-allowed text-gray-500' : ''
                          } ${isEmpty ? 'min-w-[50px]' : ''} inline-flex items-center h-[20px]`}
                          onClick={startEditing}
                        >
                          {isEmpty ? '\u00A0' : displayValue}
                        </span>
                      );
                    };
                  
 
                    const filterEnabledTasksAndGroups = (tasksState: TasksState): TasksState => {
                      const filteredGroups = tasksState.groups
                        .filter(group => group.enabled)
                        .map(group => {
                          const subprocessColor = subprocessColors[group.subprocess] || getSubprocessColor(group.subprocess);
                          return {
                            ...group,
                            color: subprocessColor,
                            tasks: group.tasks
                              .filter(task => task.enabled)
                              .map(task => ({
                                ...task,
                                color: darkenColor(subprocessColor, 60)
                              }))
                          };
                        })
                        .filter(group => group.tasks.length > 0);
                    
                      // Si no hay grupos o tareas habilitadas, creamos una tarea ficticia
                      if (filteredGroups.length === 0) {
                        const today = new Date().toISOString().split('T')[0];
                        const defaultColor = "#CCCCCC";
                        filteredGroups.push({
                          name: "No hay tareas habilitadas",
                          expanded: true,
                          subprocess: "",
                          agrupador: "Sin agrupador",
                          enabled: true,
                          descriptor: 'GESTIÓN',
                          organismo: '',
                          color: defaultColor,
                          tasks: [{
                            id: 0,
                            name: "Sin tareas",
                            responsible: "",
                            progress: 0,
                            start: today,
                            end: today,
                            descriptor: "",
                            organism: "",
                            dependsOn: [],
                            enabled: true,
                            color: darkenColor(defaultColor, 60)
                          }]
                        });
                      }
                    
                      return {
                        ...tasksState,
                        groups: filteredGroups
                      };
                    };


                    const addNewGroup = () => {
                      setTasks(prevTasks => {
                        const newGroupId = prevTasks.groups.length + 1;
                        return {
                          ...prevTasks,
                          groups: [
                            ...prevTasks.groups,
                            {
                              id: newGroupId,
                              name: "Nuevo Grupo",
                              expanded: true,
                              subprocess: "Nuevo Subproceso",
                              agrupador: "Nuevo Agrupador",
                              tasks: [],
                              enabled: true,
                              descriptor: 'GESTIÓN',
                              organismo: ''
                            }
                          ]
                        };
                      });
                    };
                  
                    const addNewTask = (groupIndex: number) => {
                      if (!tasks.groups[groupIndex].enabled) {
                        console.warn("No se puede añadir una tarea a un grupo deshabilitado");
                        return;
                      }
                      setTasks(prevTasks => {
                        const group = prevTasks.groups[groupIndex];
                        const newTaskId = group.tasks.length + 1;
                        const newTask: Task = {
                          id: newTaskId,
                          name: "Nueva Tarea",
                          responsible: "Responsable",
                          progress: 0,
                          start: new Date().toISOString().split('T')[0],
                          end: new Date().toISOString().split('T')[0],
                          organism: '',
                          descriptor: '',
                          dependsOn: [],
                          enabled: true
                        };
                        
                        return {
                          ...prevTasks,
                          groups: prevTasks.groups.map((g, index) => 
                            index === groupIndex
                              ? { ...g, tasks: [...g.tasks, newTask] }
                              : g
                          )
                        };
                      });
                    };
                  
                    const deleteGroup = (groupIndex: number) => {
                      setTasks(prevTasks => {
                        const newGroups = prevTasks.groups.filter((_, index) => index !== groupIndex);
                        // Reassign IDs to remaining groups
                        return {
                          ...prevTasks,
                          groups: newGroups.map((group, index) => ({
                            ...group,
                            id: index + 1
                          }))
                        };
                      });
                    };
                  
                    const deleteTask = (groupIndex: number, taskIndex: number) => {
                      if (!tasks.groups[groupIndex].enabled) {
                        console.warn("No se puede eliminar una tarea en un grupo deshabilitado");
                        return;
                      }
                      setTasks(prevTasks => {
                        const newGroups = prevTasks.groups.map((group, gIndex) => {
                          if (gIndex === groupIndex) {
                            const newTasks = group.tasks.filter((_, tIndex) => tIndex !== taskIndex);
                            // Reassign IDs to remaining tasks
                            return {
                              ...group,
                              tasks: newTasks.map((task, index) => ({
                                ...task,
                                id: index + 1
                              }))
                            };
                          }
                          return group;
                        });
                        return {
                          ...prevTasks,
                          groups: newGroups
                        };
                      });
                    };
                  
                    const moveGroup = (groupIndex: number, direction: 'up' | 'down') => {
                      setTasks(prevTasks => {
                        const newGroups = [...prevTasks.groups];
                        if (direction === 'up' && groupIndex > 0) {
                          [newGroups[groupIndex], newGroups[groupIndex - 1]] = [newGroups[groupIndex - 1], newGroups[groupIndex]];
                        } else if (direction === 'down' && groupIndex < newGroups.length - 1) {
                          [newGroups[groupIndex], newGroups[groupIndex + 1]] = [newGroups[groupIndex + 1], newGroups[groupIndex]];
                        }
                        // Reassign IDs to all groups
                        return {
                          ...prevTasks,
                          groups: newGroups.map((group, index) => ({
                            ...group,
                            id: index + 1
                          }))
                        };
                      });
                    };
                  
                   // Función mejorada para mover tareas
                    const moveTask = (groupIndex: number, taskIndex: number, direction: 'up' | 'down') => {
                      console.log(`Attempting to move task in group ${groupIndex}, task ${taskIndex} ${direction}`);
                      
                      setTasks(prevTasks => {
                        const newGroups = [...prevTasks.groups];
                        const group = newGroups[groupIndex];
                        
                        if (!group.enabled) {
                          console.warn("No se puede mover una tarea en un grupo deshabilitado");
                          return prevTasks;
                        }

                        const newIndex = direction === 'up' ? taskIndex - 1 : taskIndex + 1;

                        if (newIndex < 0 || newIndex >= group.tasks.length) {
                          console.warn(`Invalid move: task ${taskIndex} cannot be moved ${direction}`);
                          return prevTasks;
                        }

                        // Crear una copia de las tareas del grupo
                        let newTasks = [...group.tasks];

                        // Intercambiar las tareas
                        [newTasks[taskIndex], newTasks[newIndex]] = [newTasks[newIndex], newTasks[taskIndex]];

                        // Actualizar las dependencias
                        newTasks = updateDependenciesAfterMove(newTasks, taskIndex, newIndex);

                        // Actualizar los IDs de las tareas
                        newTasks = newTasks.map((task, index) => ({
                          ...task,
                          id: index + 1
                        }));

                        // Actualizar el grupo con las nuevas tareas
                        newGroups[groupIndex] = {
                          ...group,
                          tasks: newTasks
                        };

                        console.log(`Task moved successfully. New tasks order:`, newTasks.map(t => t.id));

                        return {
                          ...prevTasks,
                          groups: newGroups
                        };
                      });
                    };
                  
                    const updateDependenciesAfterMove = (tasks: Task[], oldIndex: number, newIndex: number): Task[] => {
                      return tasks.map(task => ({
                        ...task,
                        dependsOn: task.dependsOn.map(dep => {
                          if (dep.taskId === oldIndex + 1) {
                            return { ...dep, taskId: newIndex + 1 };
                          } else if (oldIndex < newIndex) {
                            if (dep.taskId > oldIndex + 1 && dep.taskId <= newIndex + 1) {
                              return { ...dep, taskId: dep.taskId - 1 };
                            }
                          } else if (oldIndex > newIndex) {
                            if (dep.taskId >= newIndex + 1 && dep.taskId < oldIndex + 1) {
                              return { ...dep, taskId: dep.taskId + 1 };
                            }
                          }
                          return dep;
                        })
                      }));
                    };
                    
                    const formatDateForDisplay = (dateString: string) => {
                      if (!dateString) return '';
                      const date = new Date(dateString + 'T00:00:00Z');
                      if (isNaN(date.getTime())) return dateString;
                      return date.toLocaleDateString('es-ES', { 
                        day: '2-digit', 
                        month: '2-digit', 
                        year: 'numeric',
                        timeZone: 'UTC'
                      });
                    };
                  
                    const formatDateForEdit = (dateString: string) => {
                      if (!dateString) return '';
                      let date = new Date(dateString + 'T00:00:00Z');
                      
                      if (isNaN(date.getTime())) {
                        const parts = dateString.split('-');
                        if (parts.length === 3) {
                          date = new Date(Date.UTC(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0])));
                        }
                      }
                      
                      if (isNaN(date.getTime())) return '';
                      
                      return date.toISOString().split('T')[0];
                    };
                  
                    const toggleGlobalExpansion = () => {
                      setIsGloballyExpanded(!isGloballyExpanded);
                      setTasks(prevTasks => ({
                        ...prevTasks,
                        groups: prevTasks.groups.map(group => ({
                          ...group,
                          expanded: !isGloballyExpanded
                        }))
                      }));
                    };
                  
                    const handleDateChange = (groupIndex: number, taskIndex: number, field: 'start' | 'end', newDate: string) => {
                      setTasks(prevTasks => {
                        const newTasks = JSON.parse(JSON.stringify(prevTasks));
                        const task = newTasks.groups[groupIndex].tasks[taskIndex];
                        const oldDate = new Date(task[field]);
                        const updatedDate = new Date(newDate);
                        const shift = Math.floor((updatedDate.getTime() - oldDate.getTime()) / (1000 * 60 * 60 * 24));
                  
                        if (field === 'start') {
                          const duration = calculateDuration(task.start, task.end);
                          task.start = newDate;
                          task.end = calculateNewEndDate(newDate, duration);
                        } else {
                          task.end = newDate;
                        }
                  
                        updateDependentTasks(newTasks.groups, groupIndex, task.id, shift);
                  
                        return newTasks;
                      });
                    };
                  
                    const handleReturnToSelection = () => {
                      setShowSelection(true);
                          setSelectedCodigoProyecto('');
                          setSelectedNombreProyecto('');
                          setSubprocesos([]);
                      setTasks({
                        name: tasks.name,
                        groups: []
                      });
                    };



                    const lightenColor = (color: string, amount: number): string => {
                      const num = parseInt(color.replace("#", ""), 16);
                      const r = Math.min(255, Math.max(0, (num >> 16) + amount));
                      const g = Math.min(255, Math.max(0, ((num >> 8) & 0x00FF) + amount));
                      const b = Math.min(255, Math.max(0, (num & 0x0000FF) + amount));
                      return `#${(1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1)}`;
                    };
                    
                    // Función para oscurecer colores
                    const darkenColor = (color: string, amount: number): string => {
                      return lightenColor(color, -amount);
                    };
                    
                  
                    const getSubprocessColor = (subprocess: string) => {
                      const predefinedColors = [
                        '#ADD8E6', '#90EE90', '#FFDAB9', '#E6E6FA', '#FFFACD', '#F5DEB3', '#D8BFD8', '#87CEFA', 
                        '#D3FFCE', '#F0E68C', '#E0FFFF', '#FAFAD2', '#FFB6C1', '#FFE4B5', '#98FB98', '#E0B0FF', 
                        '#FFCCCB', '#FFD700', '#B0E0E6', '#FFDEAD', '#FF69B4', '#FFE4E1', '#F0FFF0', '#E6E6FA'
                      ];
                  
                      let hash = 0;
                      for (let i = 0; i < subprocess.length; i++) {
                        hash = subprocess.charCodeAt(i) + ((hash << 5) - hash);
                      }
                  
                      return predefinedColors[Math.abs(hash) % predefinedColors.length];
                    };

                    
                    const handleSaveAsOpen = () => setIsSaveAsOpen(true);
                    const handleSaveAsClose = () => {
                      setIsSaveAsOpen(false);
                      setTemplateName('');
                      setTemplateDescription('');
                      setSaveAsError(null);
                      setIsOverwriteConfirmOpen(false);
                    };

                    const handleSaveAs = async (overwrite: boolean = false) => {
                      try {
                        const plantillaData = {
                          nombre: templateName,
                          descripcion: templateDescription,
                          contenido: JSON.stringify({
                            nombre: templateName,
                            subprocesos: tasks.groups.map(group => {
                              const matchingSubprocess = subprocesos.find(sp => sp.subproceso === group.subprocess);
                              return {
                                nombre: matchingSubprocess ? matchingSubprocess.subproceso : "",
                                agrupadores: [{
                                  nombre: group.agrupador,
                                  enabled: group.enabled,
                                  tareas: group.tasks.map(task => ({
                                    id: task.id,
                                    nombre: task.name,
                                    responsable: task.responsible,
                                    avance: task.progress,
                                    fecha_inicio: task.start,
                                    fecha_termino: task.end,
                                    descriptor: task.descriptor,
                                    organismo: task.organism,
                                    dependencia: task.dependsOn.length > 0 ? task.dependsOn[0].taskId : null,
                                    enabled: task.enabled
                                  }))
                                }]
                              };
                            })
                          }),
                          tipo: 'custom',
                          proceso: selectedCodigoProyecto,// Añadimos el proceso seleccionado
                          overwrite: overwrite
                        };
                    
                        console.log('Sending data:', plantillaData);
                    
                        const response = await fetch('http://localhost:3000/php/pages/adm_planificacion/insert_plantilla.php', {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                          },
                          body: JSON.stringify(plantillaData),
                        });
                    
                        const result = await response.json();
                        console.log('Received result:', result);
                    
                        if (result.success) {
                          console.log('Plantilla guardada:', result);
                          handleSaveAsClose();
                          showLabelMessage('success', result.message || 'Plantilla guardada exitosamente');
                        } else if (result.error === 'Ya existe una plantilla con este nombre' && !overwrite) {
                          setIsOverwriteConfirmOpen(true);
                        } else {
                          throw new Error(result.error || 'Error al guardar la plantilla');
                        }
                      } catch (error) {
                        console.error('Error:', error);
                        setSaveAsError('Error al guardar la plantilla');
                      }
                    };
                    
    
            
                    const getSubprocessDescriptor = (group: Group): string | null => {
                      if (group.tasks.length === 0) return null;
                      const firstTaskDescriptor = group.tasks[0].descriptor;
                      return group.tasks.every(task => task.descriptor === firstTaskDescriptor) ? firstTaskDescriptor : null;
                    };
                    
const handleSavePlantilla = async () => {
  try {
    // Primero, actualiza el estado en el frontend
   
    handleSave('title', null, null, 'name', tasks.name);
    
    // Luego, guarda en el backend
    await handleSaveToBackend();

    // Después de guardar la plantilla exitosamente, actualiza el proceso
    if (selectedCodigoProyecto && tasks.name) {
      await updateProcessTemplate(selectedCodigoProyecto, tasks.name);
    }

    console.log('Plantilla guardada en el frontend y backend');
    showLabelMessage('success', 'Plantilla guardada y asociada al proceso exitosamente');
  } catch (error) {
    console.error('Error:', error);
    showLabelMessage('error', 'Error al guardar la plantilla o asociarla al proceso');
  }
};
                    const handleClearPlantilla = () => {
                      setTasks({
                        name: tasks.name,
                        groups: []
                      });
                    };
                  
                    const isPlantillaEmpty = () => {
                      return tasks.groups.length === 0 || tasks.groups.every(group => group.tasks.length === 0);
                    };


                    const toggleGroupEnabled = (groupIndex: number) => {
                      setTasks(prevTasks => ({
                        ...prevTasks,
                        groups: prevTasks.groups.map((group, index) => 
                          index === groupIndex 
                            ? { 
                                ...group, 
                                enabled: !group.enabled, 
                                tasks: group.tasks.map(task => ({ ...task, enabled: !group.enabled }))
                              }
                            : group
                        )
                      }));
                    };
                  
                    const toggleTaskEnabled = (groupIndex: number, taskIndex: number) => {
                      setTasks(prevTasks => ({
                        ...prevTasks,
                        groups: prevTasks.groups.map((group, gIndex) => 
                          gIndex === groupIndex
                            ? {
                                ...group,
                                tasks: group.tasks.map((task, tIndex) => 
                                  tIndex === taskIndex ? { ...task, enabled: !task.enabled } : task
                                )
                              }
                            : group
                        )
                      }));
                    };
                  

                    const handleGroupCheckbox = (groupIndex: number, checked: boolean) => {
                      setTasks(prevTasks => ({
                        ...prevTasks,
                        groups: prevTasks.groups.map((group, index) => 
                          index === groupIndex
                            ? {
                                ...group,
                                tasks: group.tasks.map(task => ({ ...task, enabled: checked }))
                              }
                            : group
                        )
                      }));
                    };
                  

                    
                    const renderTableHeaders = (group: Group) => {
                      if (group.tasks.length === 0) return null;
                  
                      const allTasksEnabled = group.tasks.every(task => task.enabled);
                      const subprocessColor = subprocessColors[group.subprocess] || "#8C8C8C";
                  
                      return (
                        <thead style={{ backgroundColor: lightenColor(subprocessColor, 80) }}>
                          <tr>
                            <th className="p-2 text-center w-10"></th>
                            <th className="p-2 text-center w-10">#</th>
                            <th className="p-2 w-1/4 min-w-[200px]">Nombre de las tareas</th>
                            <th className="p-2 text-center">Fecha Inicio</th>
                            <th className="p-2 text-center">Fecha de término</th>
                            <th className="p-2 text-center">Duración</th>
                            <th className="p-2 text-center">Dependencias</th>
                            <th className="p-2 text-center">Acciones</th>
                          </tr>
                        </thead>
                      );
                    };
                  

                    const StickyGanttButton = () => (
                      <button 
                        onClick={() => setShowGanttModal(true)}
                        className={`fixed bottom-4 right-4 w-12 h-12 text-white rounded-full shadow-lg flex items-center justify-center transition-colors duration-300 z-50`}
                        style={{
                          backgroundColor: darkenColor("#49D4D7", 60)
                        }}
                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = darkenColor("#49D4D7", 60)}
                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "#49D4D7"}
                      >
                        <GanttChartIcon className="w-6 h-6" />
                      </button>
                    );
                    
                  
                   // console.log(JSON.stringify(filterEnabledTasksAndGroups(tasks)));
                    const GanttModal = () => (
                      <Dialog className='' isOpen={showGanttModal} onClose={() => setShowGanttModal(false)} width="1200px" height="700px">
                        <DialogContent className="w-full">
                          <DialogHeader>
                            <DialogTitle>Carta Gantt</DialogTitle>
                          </DialogHeader>
                          <div className="">
                          <GanttChartView tasks={filterEnabledTasksAndGroups(tasks)} />
                          </div>
                          <DialogFooter>
                            <Button className="bg-teal-500 text-white hover:bg-teal-800" onClick={() => setShowGanttModal(false)}>Cerrar</Button>
                          </DialogFooter>
                        </DialogContent>
                      </Dialog>
                    );


                    if (showSelection) {
                      return <TemplateSelectionInterface onTemplateSelected={handleTemplateSelected} />;
                    }
                  
                    return (
                      <Card className="w-full max-w-7xl border-none mx-auto overflow-x-auto">
                        <CardHeader className="flex justify-between items-center w-full">
                          <div className="items-center mb-3 w-full">
                            
                            <Button 
                              onClick={handleReturnToSelection} 
                              variant="default" 
                              size="sm"
                              className="mr-2"
                            >
                              <ArrowLeftCircle className="w-4 h-4 mr-2" /> Volver a Selección
                            </Button>
                            <div className="w-full flex items-center bg-custom-header mt-3">
                              <div className="flex items-center justify-center w-full">
                                <h1 className="text-xl font-bold text-center text-white py-3 px-4 mt-2 rounded inline-block">
                                  {renderEditableField('title', null, null, 'name', tasks.name)}
                           
                                </h1>
                              </div>
                            </div>
                            <div className="w-full mt-5">
                            <span className="font-bold mr-2">Proceso asignado:</span>
                            <select
  value={selectedNombreProyecto}
  onChange={handleProcessChange}
  className="w-full p-2 text-gray-700 border border-gray-300 rounded-md shadow-sm focus:outline-none"
>
  <option value="">Asignar proceso</option>
  {processes.length > 0 ? (
    processes.map((process, index) => (
      <option key={index} value={process.nombreProceso}>
        {process.nombreProceso}
      </option>
    ))
  ) : (
    <option value="" disabled>
      No hay procesos disponibles
    </option>
  )}
</select>
    </div>
                          </div>
                       
                        </CardHeader>
                        <CardContent>
                    
                          <div className="flex items-center justify-between mb-4">
                            <Button onClick={addNewGroup} variant="outline" size="sm">
                              <Plus className="w-4 h-4 mr-2" /> Añadir Nuevo Grupo
                            </Button>
                            <Button onClick={toggleGlobalExpansion} variant="outline" size="sm">
                              {isGloballyExpanded ? <Minimize className="w-4 h-4 mr-2" /> : <Maximize className="w-4 h-4 mr-2" />}
                              {isGloballyExpanded ? "Colapsar Todo" : "Expandir Todo"}
                            </Button>
                          
                          </div>
                  
                          {tasks.groups.map((group, groupIndex) => {
      const subprocessColor = subprocessColors[group.subprocess] || "#ADADAD";
      // Función mejorada para aclarar colores



    
      return (
        <div key={groupIndex} className={`mt-10 mb-4 border rounded ${!group.enabled ? 'opacity-50' : ''}`}>
    <div className="relative pr-3" style={{ backgroundColor: lightenColor(subprocessColor, 60) }}>
            
            <div className="flex items-center">
              <div className="py-2 pr-4 flex items-center rounded-lg" style={{ backgroundColor: subprocessColor }}>
                   <input
                                      type="checkbox"
                                      checked={group.enabled}
                                      onChange={() => toggleGroupEnabled(groupIndex)}
                                      className="mr-2 ml-4"
                                    />
                                      <Button 
                                        onClick={() => toggleGroup(groupIndex)}
                                        variant="ghost"
                                        size="sm"
                                        className="mr-2"
                                      >
                                        {group.expanded ? <ChevronDown className="w-4 h-4" /> : <ChevronRight className="w-4 h-4" />}
                                      </Button>
                                      <div className="flex items-center">
                                        
                      
                                      {renderSubprocessSelect(group, groupIndex)}
                      
                    </div>
                                        {/*renderEditableField('group', groupIndex, null, 'subprocess', group.subprocess)*/}
                                     
                                      
                                    </div>
                                    
                                    <div className="flex-grow flex items-center ml-4 ">
                                      <div className="flex-wrap items-center">
                               
                                        <Button 
                                          onClick={() => toggleGroup(groupIndex)}
                                          variant="ghost"
                                          size="sm"
                                          className="mr-2"
                                        >
                                          {group.expanded ? <ChevronDown className="w-4 h-4" /> : <ChevronRight className="w-4 h-4" />}
                                        </Button>
                                        <span className="font-bold mr-2">Agrupador:</span>
                                        {renderEditableField('group', groupIndex, null, 'agrupador', group.agrupador)}
                                        {renderOrganismRow(group, groupIndex)}
  
                    </div>
            
                  </div>
                  
                  <div className="flex items-center space-x-1 ml-4">
                  <select
    value={group.descriptor}
    onChange={(e) => handleDescriptorChange(groupIndex, e.target.value as 'GESTIÓN' | 'PERMISOLOGÍA')}
    className={`ml-2 mr-3 px-2 py-1 rounded-full text-xs font-semibold text-white`}
    style={{ backgroundColor: darkenColor(subprocessColor, 60) }}

    >
    <option value="GESTIÓN">GESTIÓN</option>
    <option value="PERMISOLOGÍA">PERMISOLOGÍA</option>
  </select>

                    <Button
                      onClick={() => moveGroup(groupIndex, 'up')}
                      variant="outline"
                      size="sm"
                      disabled={groupIndex === 0}
                      className="p-1"
                    >
                      <ArrowUp className="w-4 h-4" />
                    </Button>
                    <Button
                      onClick={() => moveGroup(groupIndex, 'down')}
                      variant="outline"
                      size="sm"
                      disabled={groupIndex === tasks.groups.length - 1}
                      className="p-1"
                    >
                      <ArrowDown className="w-4 h-4" />
                    </Button>
                    <Button
                      onClick={() => deleteGroup(groupIndex)}
                      variant="outline"
                      size="sm"
                      className="text-red-500 p-1"
                    >
                      <Trash2 className="w-4 h-4" />
                    </Button>
                  
                  </div>
                  
                </div>
                
              </div>
              
              {group.expanded && (
                <div className="p-2 shadow-lg">
                  {group.tasks.length > 0 ? (
                    <table className="w-full table-fixed">
                      {renderTableHeaders(group)}
                      <tbody>
                        {group.tasks.map((task, taskIndex) => (
                        <tr key={taskIndex} className={`border-b ${!task.enabled ? 'opacity-50' : ''}`}>
                        <td className="p-2 text-center">
                        
                        <input
                          type="checkbox"
                          checked={task.enabled}
                          onChange={() => toggleTaskEnabled(groupIndex, taskIndex)}
                          disabled={!group.enabled}
                          className={`mr-2 ${!group.enabled ? 'cursor-not-allowed' : ''}`}
                        />
                        </td>
                          <td className="p-2 text-center">{taskIndex + 1}</td>
                          <td className="p-2 text-center">
                            {renderEditableField('task', groupIndex, taskIndex, 'name', task.name)}
                          </td>
                        { /* <td className="p-2">
                            {renderEditableField('task', groupIndex, taskIndex, 'responsible', task.responsible)}
                          </td> */}
                          
                       {/*   <td className="p-2 text-center">
                            {renderEditableField('task', groupIndex, taskIndex, 'progress', task.progress)}
                          </td>*/}
                          <td className="p-2 text-center">
                            {renderEditableField('task', groupIndex, taskIndex, 'start', task.start)}
                          </td>
                          <td className="p-2 text-center">
                            {renderEditableField('task', groupIndex, taskIndex, 'end', task.end)}
                          </td>
                          <td className="p-2 text-center">
                            {renderEditableField('task', groupIndex, taskIndex, 'duration', null)}
                          </td>
                          <td className="p-2 text-center">
                            {renderEditableField('task', groupIndex, taskIndex, 'dependsOn', task.dependsOn)}
                          </td>
                          <td className="p-2">
                            <div className="flex justify-center items-center space-x-1">
                              <Button
                                onClick={() => moveTask(groupIndex, taskIndex, 'up')}
                                variant="outline"
                                size="sm"
                                disabled={taskIndex === 0}
                                className="p-1"
                              >
                                <ArrowUp className="w-4 h-4" />
                              </Button>
                              <Button
                                onClick={() => moveTask(groupIndex, taskIndex, 'down')}
                                variant="outline"
                                size="sm"
                                disabled={taskIndex === group.tasks.length - 1}
                                className="p-1"
                              >
                                <ArrowDown className="w-4 h-4" />
                              </Button>
                              <Button
                                onClick={() => deleteTask(groupIndex, taskIndex)}
                                variant="outline"
                                size="sm"
                                className="text-red-500 p-1"
                              >
                                <Trash2 className="w-4 h-4" />
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                   </tbody>
                    </table>
                  ) : (
                    <p className="text-center text-gray-500 py-4">No hay tareas en este agrupador.</p>
                  )}
                  <Button
                    onClick={() => addNewTask(groupIndex)}
                    variant="outline"
                    size="sm"
                    className="mt-2"
                  >
                    <Plus className="w-4 h-4 mr-2" />  Añadir nueva tarea
                  </Button>
                </div>
              )}
            </div>
          );
        })}
      </CardContent>
     
      {labelMessage && (
        <div className="mb-4">
          <LabelMessage type={labelMessage.type} message={labelMessage.message} />
        </div>
      )}
      <div className="flex justify-center space-x-4 mt-4">
      

        <Button
          onClick={handleSavePlantilla}
          variant="default"
          size="lg"
          className="bg-teal-500 hover:bg-teal-700 text-white"
          disabled={isPlantillaEmpty()}
        >
          <Save className="w-4 h-4 mr-2" /> Guardar Plantilla
        </Button>
        <Button
          onClick={handleSaveAsOpen}
          variant="outline"
          size="lg"
          className="bg-blue-500 hover:bg-blue-700 text-white"
        >
          <Save className="w-4 h-4 mr-2" /> Guardar como
        </Button>
      {/*  <Button
          onClick={handleClearPlantilla}
          variant="outline"
          size="lg"
          className="bg-red-500 text-white"
        >
          <Trash2 className="w-4 h-4 mr-2" /> Limpiar Plantilla
        </Button>*/}
      </div>
        
      <Dialog isOpen={isSaveAsOpen} onClose={handleSaveAsClose}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Guardar plantilla como</DialogTitle>
          </DialogHeader>
          {saveAsError && (
  <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
    <span className="block sm:inline">{saveAsError}</span>
  </div>
)}
          <Input
            placeholder="Nombre de la plantilla"
            value={templateName}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setTemplateName(e.target.value)}
            className='mb-2'
          />
          <Input
            placeholder="Descripción de la plantilla"
            value={templateDescription}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setTemplateDescription(e.target.value)}
          />
          
          <DialogFooter>
            <Button onClick={handleSaveAsClose} variant="outline">Cancelar</Button>
            <Button onClick={() => handleSaveAs(false)} className="bg-teal-500 ml-3 border text-white hover:bg-teal-800" variant="default">Guardar</Button>

          </DialogFooter>
        </DialogContent>
      </Dialog>
      <Dialog isOpen={isOverwriteConfirmOpen} onClose={handleOverwriteCancel}>
  <DialogContent>
    <DialogHeader>
      <DialogTitle>Confirmar sobrescritura</DialogTitle>
    </DialogHeader>
    <p>Ya existe una plantilla con este nombre. ¿Desea sobrescribirla?</p>
    <DialogFooter>
      <Button onClick={handleOverwriteCancel} variant="outline">Cancelar</Button>
      <Button onClick={handleOverwriteConfirm} className="bg-red-500 ml-3 border text-white hover:bg-red-800" variant="default">Sobrescribir</Button>
    </DialogFooter>
  </DialogContent>
</Dialog>
<StickyGanttButton />
<GanttModal />
    </Card>
      
  
 
  );
  
};

export default AdvancedGanttChart;