import React, { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

interface TableRowProps {
  item: string;
  tiempoTranscurrido: string;
  plazoTitular: number;
  plazoServicio: number;
}

const TableRow: React.FC<TableRowProps> = ({ item, tiempoTranscurrido, plazoTitular, plazoServicio }) => (
  <tr className="border-b border-gray-200">
    <td className="py-2 px-4">{tiempoTranscurrido}</td>
    <td className="py-2 px-4">{item}</td>
    <td className="py-2 px-4">{plazoTitular}</td>
    <td className="py-2 px-4">{plazoServicio}</td>
  </tr>
);

interface EditableTitleProps {
  title: string;
  onTitleChange: (newTitle: string) => void;
}

const EditableTitle: React.FC<EditableTitleProps> = ({ title, onTitleChange }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState(title);

  const handleBlur = () => {
    setIsEditing(false);
    onTitleChange(editedTitle);
  };

  return isEditing ? (
    <input
      type="text"
      value={editedTitle}
      onChange={(e) => setEditedTitle(e.target.value)}
      onBlur={handleBlur}
      onKeyPress={(e) => e.key === 'Enter' && handleBlur()}
      className="font-bold text-lg mb-2 border-b-2 border-blue-500 focus:outline-none"
      autoFocus
    />
  ) : (
    <h3 className="font-bold text-lg mb-2 cursor-pointer" onClick={() => setIsEditing(true)}>
      {title}
    </h3>
  );
};

interface DataItem {
  name: string;
  tiempoTranscurrido: string;
  plazoTitular: number;
  plazoServicio: number;
}

interface CollapsibleSectionProps {
  title: string;
  data: DataItem[];
  onTitleChange: (newTitle: string) => void;
}

const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({ title, data, onTitleChange }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className="mb-4">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full bg-yellow-200 text-left py-2 px-4 font-semibold focus:outline-none"
      >
        <EditableTitle title={title} onTitleChange={onTitleChange} />
      </button>
      {isOpen && (
        <table className="w-full text-sm border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-100">
              <th className="border border-gray-300 py-2 px-4 text-left">Tiempo Transcurrido</th>
              <th className="border border-gray-300 py-2 px-4 text-left">Agrupador</th>
              <th className="border border-gray-300 py-2 px-4 text-left">Plazo Titular (%)</th>
              <th className="border border-gray-300 py-2 px-4 text-left">Plazo Servicio (%)</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <TableRow
                key={index}
                item={item.name}
                tiempoTranscurrido={item.tiempoTranscurrido}
                plazoTitular={item.plazoTitular}
                plazoServicio={item.plazoServicio}
              />
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

interface DataChartProps {
  data: DataItem[];
  title: string;
  onTitleChange: (newTitle: string) => void;
}

const DataChart: React.FC<DataChartProps> = ({ data, title, onTitleChange }) => (
  <div className="mb-4">
    <EditableTitle title={title} onTitleChange={onTitleChange} />
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        layout="vertical"
        data={data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" domain={[0, 100]} />
        <YAxis dataKey="name" type="category" width={150} />
        <Tooltip />
        <Legend />
        <Bar dataKey="plazoTitular" stackId="a" fill="#4e79a7" name="Plazo Titular (%)" />
        <Bar dataKey="plazoServicio" stackId="a" fill="#f28e2c" name="Plazo Servicio (%)" />
      </BarChart>
    </ResponsiveContainer>
  </div>
);

const RegistrationProtocol: React.FC = () => {
  const [agrupador1Title, setAgrupador1Title] = useState("Agrupador");
  const [agrupador2Title, setAgrupador2Title] = useState("Agrupador");

  const agrupador1Data: DataItem[] = [
    { name: "Rectificación de deslindes", tiempoTranscurrido: "2 días", plazoTitular: 78, plazoServicio: 22 },
    { name: "Modificación de deslindes", tiempoTranscurrido: "5 días", plazoTitular: 95, plazoServicio: 5 },
    { name: "Subdivisión", tiempoTranscurrido: "3 días", plazoTitular: 59, plazoServicio: 41 },
    { name: "Fusión", tiempoTranscurrido: "1 día", plazoTitular: 100, plazoServicio: 0 },
    { name: "Rol", tiempoTranscurrido: "4 días", plazoTitular: 33, plazoServicio: 67 },
  ];

  const agrupador2Data: DataItem[] = [
    { name: "DIA / EIA", tiempoTranscurrido: "10 días", plazoTitular: 10, plazoServicio: 90 },
    { name: "CPA", tiempoTranscurrido: "7 días", plazoTitular: 28, plazoServicio: 72 },
    { name: "IMIV", tiempoTranscurrido: "6 días", plazoTitular: 11, plazoServicio: 89 },
    { name: "Factibilidad", tiempoTranscurrido: "2 días", plazoTitular: 96, plazoServicio: 4 },
    { name: "ATO", tiempoTranscurrido: "8 días", plazoTitular: 86, plazoServicio: 14 },
  ];

  return (
    <div className="bg-white p-6 rounded-lg shadow-lg">

      <div className="flex flex-col lg:flex-row">
        <div className="w-full lg:w-1/2 pr-0 lg:pr-4 mb-4 lg:mb-0">
          <CollapsibleSection
            title={agrupador1Title}
            data={agrupador1Data}
            onTitleChange={setAgrupador1Title}
          />
        </div>
        <div className="w-full lg:w-1/2">
          <DataChart
            data={agrupador1Data}
            title={agrupador1Title}
            onTitleChange={setAgrupador1Title}
          />
        </div>
      </div>

      <div className="flex flex-col lg:flex-row">
        <div className="w-full lg:w-1/2 pr-0 lg:pr-4 mb-4 lg:mb-0">
          <CollapsibleSection
            title={agrupador2Title}
            data={agrupador2Data}
            onTitleChange={setAgrupador2Title}
          />
        </div>
        <div className="w-full lg:w-1/2">
          <DataChart
            data={agrupador2Data}
            title={agrupador2Title}
            onTitleChange={setAgrupador2Title}
          />
        </div>
      </div>
    </div>
  );
};

export default RegistrationProtocol;