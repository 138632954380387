import React, { useState, useEffect, useRef } from 'react';
import { ChevronDown, ChevronUp, Minimize, Maximize, FileX, Plus, FileQuestion, X, Upload } from 'lucide-react';
import { CommonComponentProps } from '../../types/common';
import { motion, AnimatePresence } from 'framer-motion';
import GanttChartView from './ganttChartView';
import { group } from 'console';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

interface Task {
  id: number;
  description: string;
  responsible: string;
  progress: number;
  start: string;
  end: string;
  color?: string;
  followUp: boolean;
  status: TaskStatus;
  descriptor: 'GESTIÓN' | 'PERMISOLOGÍA';
  followUpDate: string;
  semaphoreStatus: SemaphoreStatus;
  dependencia: number | null;
  name: string;
  organism: string;
  dependsOn: { groupId: number; taskId: number }[];
  enabled: boolean;
}

interface TasksState {
  name: string;
  groups: Group[];
}
interface TaskInput {
  id: number;
  name: string;
  description: string;
  followUp: boolean;
  status: TaskStatus;
  responsible: string;
  progress: number;
  descriptor: string;
  end: string;
  dependsOn: { groupId: number; taskId: number }[];
  color?: string;
}
interface Group {
  id: number;
  nombre: string;
  expanded: boolean;
  subprocess: string;
  agrupador: string;
  tasks: TaskInput[];
  descriptor: 'GESTIÓN' | 'PERMISOLOGÍA';
  organismo: string;
  enabled: boolean;
  color: string;
  tareas: {
    id: number;
    nombre: string;
    responsable: string;
    avance: number;
    fecha_inicio: string;
    fecha_termino: string;
    color: string;
    descriptor: 'GESTIÓN' | 'PERMISOLOGÍA';
    organismo: string;
    enabled: boolean;
    seguimiento: string;
    dependencia: number | null;
    fechaSeguimiento: string;
  }[];
}

interface TasksState {
  name: string;
  groups: Group[];
}

interface GlobalProgressSliderProps {
  tasks: TaskInput[];
  onTaskProgressChange: (taskId: number, progress: number) => void;
}


const GlobalProgressSlider: React.FC<GlobalProgressSliderProps> = ({ tasks, onTaskProgressChange }) => {
  const [globalProgress, setGlobalProgress] = useState(0);

  const handleProgressChange = (value: number | number[]) => {
    if (typeof value === 'number') {
      setGlobalProgress(value);
      tasks.forEach(task => onTaskProgressChange(task.id, value));
    }
  };
  return (
    <div className="flex items-center space-x-2 mb-4">
      <span>Progreso General:</span>
      <Slider
        value={globalProgress}
        onChange={handleProgressChange}
        min={0}
        max={100}
        step={1}
        railStyle={{
          height: 8,
          backgroundColor: '#e0e0e0',
        }}
        handleStyle={{
          width: 16,
          height: 16,
          marginLeft: -8,
          marginTop: -4,
          backgroundColor: '#0044ff',
          border: 'none',
        }}
        trackStyle={{
          backgroundColor: '#0044ff',
        }}
        style={{
          width: '100%',
        }}
        aria-label="Progreso general"
      />
      <span>{globalProgress}%</span>
    </div>
  );
};

interface SubprocessTabProps {
  label: string;
  isActive: boolean;
  onClick: () => void;
  color: string;
  index: number;
  totalTabs: number;
  activeIndex: number;
}



const SubprocessTab: React.FC<SubprocessTabProps> = ({ 
  label, 
  isActive, 
  onClick, 
  color, 
  index, 
  totalTabs
}) => {
  const [scale, setScale] = useState(1);
  const activeScale = 1.185;
  const fixedOffset = 20; // Offset fijo para todas las pestañas

  useEffect(() => {
    if (isActive) {
      setScale(activeScale);
    } else {
      setScale(1);
    }
  }, [isActive]);

  const buttonStyle: React.CSSProperties = {
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    padding: '0 30px 0 30px',
    fontSize: '14px',
    fontWeight: 'medium',
    color: isActive ? '#ffffff' : '#115E59',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    overflow: 'visible',
    transition: 'all 0.3s',
    marginRight: `-${fixedOffset}px`,
    zIndex: isActive ? totalTabs : index,
    whiteSpace: 'nowrap',
  };

  const shapeStyle: React.CSSProperties = {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: color,
    opacity: isActive ? 1 : 0.3,
    transition: 'opacity 0.3s',
    clipPath: `polygon(0 0, calc(100% - ${fixedOffset}px) 0, 100% 50%, calc(100% - ${fixedOffset}px) 100%, 0 100%, ${fixedOffset}px 50%)`,
  };

  return (
    <motion.button
      onClick={onClick}
      className="relative"
      animate={{ 
        scale,
        zIndex: isActive ? totalTabs : index,
      }}
      whileHover={{ scale: scale * 1.02 }}
      whileTap={{ scale: scale * 0.98 }}
      transition={{ duration: 0.2 }}
      style={buttonStyle}
    >
      <div style={shapeStyle} />
      <span className="relative z-10 px-2">{label}</span>
    </motion.button>
  );
};

interface DateInputProps {
  value: string;
  onChange: (value: string) => void;
}

const DateInput: React.FC<DateInputProps> = ({ value, onChange }) => (
  <input
    type="date"
    value={value}
    onChange={(e) => onChange(e.target.value)}
    className="w-full border rounded px-2 py-1 text-sm"
  />
);
interface NoPlantillaMessageProps {
  navigateToSubComponent: (main: string, sub: string) => void;
}

const NoPlantillaMessage: React.FC<NoPlantillaMessageProps> = ({ navigateToSubComponent }) => {
  const handleEditarPlantillas = () => {
    navigateToSubComponent('ADMINISTRACIÓN', 'Editar plantillas');
  };

  return (
    <div className="flex flex-col items-center justify-center p-10">
      <FileX size={60} className="text-teal-500 mb-4" />
      <h3 className="text-2xl font-bold text-teal-700 mb-2">No hay plantilla disponible</h3>
      <p className="text-teal-600 text-center mb-6">Parece que aún no se ha asignado una plantilla para este proceso.</p>
      <button
        onClick={handleEditarPlantillas}
        className="flex items-center bg-teal-500 text-white px-4 py-2 rounded-full hover:bg-teal-600 transition-all duration-300 transform hover:scale-105 active:scale-95"
      >
        <Plus size={20} className="mr-2" />
        Asignar nueva Plantilla
      </button>
    </div>
  );
};


interface SmoothCollapseProps {
  isOpen: boolean;
  children: React.ReactNode;
}



const SmoothCollapse: React.FC<SmoothCollapseProps> = ({ isOpen, children }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number | string>(0);

  useEffect(() => {
    if (isOpen) {
      const contentHeight = contentRef.current?.scrollHeight;
      setHeight(contentHeight || 'auto');
    } else {
      setHeight(0);
    }
  }, [isOpen]);

  const containerVariants = {
    open: { 
      height: height,
      opacity: 1,
      transition: { 
        height: { type: "spring", stiffness: 100, damping: 20, duration: 0.3 },
        opacity: { duration: 0.2 }
      }
    },
    collapsed: { 
      height: 0,
      opacity: 0,
      transition: { 
        height: { type: "spring", stiffness: 100, damping: 20, duration: 0.3 },
        opacity: { duration: 1.2 }
      }
    }
  };

  return (
    <AnimatePresence initial={false}>
      <motion.div
        variants={containerVariants}
        initial="collapsed"
        animate={isOpen ? "open" : "collapsed"}
        exit="collapsed"
        style={{ overflow: 'hidden' }}
      >
        <div ref={contentRef}>
          {children}
        </div>
      </motion.div>
    </AnimatePresence>
  );
};



const EmptySubprocessMessage: React.FC<{ subprocessName: string }> = ({ subprocessName }) => {
  return (
    <div className="flex flex-col items-center justify-center p-10 bg-white rounded-lg shadow-md">
      <FileQuestion size={60} className="text-teal-500 mb-4" />
      <h3 className="text-2xl font-bold text-teal-700 mb-2">Subproceso sin contenido</h3>
      <p className="text-teal-600 text-center mb-6">
        El subproceso "{subprocessName}" no tiene agrupadores ni tareas definidas.
      </p>
    
    </div>
  );
};



const NoProcesoSeleccionadoMessage: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center p-10">
      <FileQuestion size={60} className="text-teal-500 mb-4" />
      <h3 className="text-2xl font-bold text-teal-700 mb-2">No se ha seleccionado ningún proceso</h3>
      <p className="text-teal-600 text-center mb-6">Por favor, seleccione un proceso para comenzar.</p>
    </div>
  );
};

// Update the TaskInput interface
interface TaskInput {
  id: number;
  name: string;
  description: string;
  followUp: boolean;
  semaphoreStatus: string;
  dependencia: number | null;
  followUpDate: string;
  status: TaskStatus;
  responsible: string;
  progress: number;
  descriptor: string;
  start: string; // Add this line
  end: string;
  dependsOn: { groupId: number; taskId: number }[];
  color?: string;
  enabled: boolean; // Add this line
  organism: string; // Add this line if needed
}

// Update the Group interface
interface Group {
  id: number;
  nombre: string;
  expanded: boolean;
  subprocess: string;
  agrupador: string;
  tasks: TaskInput[]; // Change this from Task[] to TaskInput[]
  descriptor: 'GESTIÓN' | 'PERMISOLOGÍA';
  organismo: string;
  enabled: boolean;
  color: string;
  tareas: {
    id: number;
    nombre: string;
    responsable: string;
    avance: number;
    fecha_inicio: string;
    fecha_termino: string;
    color: string;
    descriptor: 'GESTIÓN' | 'PERMISOLOGÍA';
    organismo: string;
    enabled: boolean;
    seguimiento: string;
    dependencia: number | null;
    fechaSeguimiento: string;
  }[];
}

interface ProjectSectionProps {
  title: string;
  sectionType: 'GESTIÓN' | 'PERMISOLOGÍA';
  processingBody: string;
  isCollapsed: boolean;
  tasks: Task[];
  onChangeSectionType: (newType: 'GESTIÓN' | 'PERMISOLOGÍA') => void;
  onChangeProcessingBody: (newBody: string) => void;
  onChangeTask: (taskIndex: number, updatedTask: Task) => void;
  onToggleCollapse: () => void;
  subprocessColor: string;
}


enum SemaphoreStatus {
  Red = "red",
  Orange = "orange",
  Yellow = "yellow",
  Green = "green",
  Gray = "gray"
}



interface GanttTask {
  id: number;
  name: string;
  responsible: string;
  progress: number;
  start: string;
  end: string;
  descriptor: 'GESTIÓN' | 'PERMISOLOGÍA';
  organism: string;
  dependsOn: { groupId: number; taskId: number }[];
  enabled: boolean;
  color: string;
}

interface GanttGroup {
  name: string;
  expanded: boolean;
  subprocess: string;
  agrupador: string;
  tasks: GanttTask[];
  descriptor: 'GESTIÓN' | 'PERMISOLOGÍA';
  organismo: string;
  enabled: boolean;
  color: string;
}

interface GanttTasksState {
  name: string;
  groups: GanttGroup[];
}

interface ProjectSection {
  title: string;
  sectionType: string;
  processingBody: string;
  isCollapsed: boolean;
  tasks: Task[];
  onChangeSectionType: () => void;
  onChangeProcessingBody: () => void;
  onChangeTask: () => void;
  onToggleCollapse: () => void;
}

interface TaskRowProps {
  task: Task;
  onChangeTask: (updatedTask: Task) => void;
  rowColor: string;
  inputColor: string;
  allTasks: Task[]; // This should now be an array of Task objects


}




const TaskRow: React.FC<TaskRowProps> = ({ task, onChangeTask, rowColor, inputColor, allTasks }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  
  const handleRowClick = () => {
    setIsModalOpen(true);
  };


  const semaphoreColor = {
    [SemaphoreStatus.Red]: 'bg-[#EF4444]',
    [SemaphoreStatus.Orange]: 'bg-[#FB923C]',
    [SemaphoreStatus.Yellow]: 'bg-[#FDE047]',
    [SemaphoreStatus.Green]: 'bg-[#4ADE80]',
    [SemaphoreStatus.Gray]: 'bg-[#E5E7EB]'
  }[task.semaphoreStatus];



  const isFollowUpPending = () => {
    if (!task.followUpDate) return false;
    const today = new Date();
    const followUpDate = new Date(task.followUpDate);
    return followUpDate >= today;
  };

  const followUpColor = isFollowUpPending() ? 'bg-red-500' : 'bg-gray-200';



  const formatDate = (dateString: string): string => {
    const [year, month, day] = dateString.split('-').map(Number); // Asumiendo que el formato es 'YYYY-MM-DD'
    const date = new Date(year, month - 1, day); // Meses en JavaScript van de 0 (Enero) a 11 (Diciembre)
    return date.toLocaleDateString('es-CL', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).replace(/\//g, '-');
  };
  


  useEffect(() => {

    const fetchUsers = async () => {
      try {
        const response = await fetch('http://localhost:3000/php/pages/users/get_users.php');
        if (!response.ok) throw new Error('Error al obtener los usuarios.');
        
        const data: User[] = await response.json();
        setUsers(data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  const formatDependencies = (dependencyId: number | null): string => {
    if (dependencyId === null) {
      return '';
    }
    const dependencyTask = allTasks.find(t => t.id === dependencyId);
    return dependencyTask ? dependencyTask.description : dependencyId.toString();
  };

  return(
    <> 
      <motion.tr
        style={{ 
          backgroundColor: isHovered ? 'rgba(0, 0, 0, 0.05)' : rowColor,
          cursor: 'pointer'
        }}
        whileHover={{ scale: 1.01 }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={handleRowClick}
      >
    <td className="px-4 py-2 text-sm">{task.description}</td>
        <td className="px-4 py-2 text-sm">{task.responsible}</td>
        <td className="px-4 py-2 text-sm">{formatDate(task.start)}</td>
        <td className="px-4 py-2 text-sm">{formatDate(task.end)}</td>
        <td className="px-4 py-2">
          <div className="relative w-full h-4 bg-gray-200 rounded">
            <div
              className="h-full rounded"
              style={{ width: `${task.progress}%`, backgroundColor:inputColor }}
            ></div>
          </div>
        </td>
        <td className="px-4 py-2 text-center text-sm">{formatDependencies(task.dependencia)}</td>


        <td className="px-4 py-2 text-center">
        <div className={`w-6 h-6 rounded-full ${followUpColor} mx-auto`}></div>
        </td>
        <td className="px-4 py-2 text-center">
          <div className={`w-6 h-6 rounded-full ${semaphoreColor} mx-auto`}></div>
        </td>


   </motion.tr>

        <TaskModal 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)} 
        task={task}
        onChangeTask={onChangeTask}
        
        />
        </>
)
};

interface TaskModalProps {
  isOpen: boolean;
  onClose: () => void;
  task: Task;
  onChangeTask: (updatedTask: Task) => void;
}

const TaskModal: React.FC<TaskModalProps> = ({ isOpen, onClose, task, onChangeTask }) => {
  const modalVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { 
      opacity: 1, 
      scale: 1,
      transition: { type: 'spring', stiffness: 500, damping: 25 }
    },
    exit: { 
      opacity: 0, 
      scale: 0.8,
      transition: { duration: 0.2 }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    onChangeTask({ ...task, [name]: value });
  };

 const [users, setUsers] = useState<User[]>([]);
  
  
  useEffect(() => {

    const fetchUsers = async () => {
      try {
        const response = await fetch('http://localhost:3000/php/pages/users/get_users.php');
        if (!response.ok) throw new Error('Error al obtener los usuarios.');
        
        const data: User[] = await response.json();
        setUsers(data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  return (
    <AnimatePresence>
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <motion.div
            className="bg-white rounded-lg w-full max-w-2xl"
            variants={modalVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <div className="bg-[#40E0D0] text-white p-4 flex justify-between items-center rounded-t-lg">
              <h2 className="text-xl font-bold">SEGUIMIENTO</h2>
              <button onClick={onClose} className="text-white hover:text-gray-200">
                <X size={24} />
              </button>
            </div>
            <form className="p-6">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">Tipo Tarea</label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500">
                      <Plus size={18} className="text-red-500" />
                    </span>
                    <input
                      type="text"
                      name="tipo"
                      value='Tipo'
                      onChange={handleInputChange}
                      className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                    />
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Tarea</label>
                  <input
                    type="text"
                    name="descripcion"
                    value={task.description}
                    onChange={handleInputChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Creado por:</label>
                  <input
                    type="text"
                    name="creadoPor"
                    value={task.responsible}
                    onChange={handleInputChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div>
                {/*  <label className="block text-sm font-medium text-gray-700">Tarea</label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <select
                      name="tarea"
                      value={'Tarea'}
                      onChange={handleInputChange}
                      className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                    >
                      <option>Seleccione...</option>
                      <option>{task.responsible}</option>
                    </select>
                    <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500">
                      <Plus size={18} className="text-[#40E0D0]" />
                    </span>
                    <span className="ml-2 inline-flex items-center px-3 rounded-md border border-gray-300 bg-gray-50 text-gray-500">
                      <X size={18} className="text-red-500" />
                    </span>
                  </div>*/}
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Porcentaje de Avance</label>
                  
  <div className="flex items-center space-x-2">
    <input
      type="number"
      name="porcentajeAvance"
      value={task.progress}
      onChange={(e) => {
        const value = Math.max(0, Math.min(100, Number(e.target.value))); // Limitar entre 0 y 100
        onChangeTask({ ...task, progress: value });
      }}
      className="mt-1 border focus:ring-indigo-500 focus:border-indigo-500 block w-10 shadow-sm sm:text-sm border-gray-300 rounded-md"
    />%
    <input
      type="range"
      min="0"
      max="100"
      value={task.progress}
      onChange={(e) => onChangeTask({ ...task, progress: Number(e.target.value) })}
      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
    />
  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Fecha de Seguimiento</label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                  <input
                      type="date"
                      name="followUpDate"
                      value={task.followUpDate}
                      onChange={handleInputChange}
                      className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-l-md sm:text-sm border-gray-300"
                    />
                    <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500">
                      <X size={18} className="text-red-500" />
                    </span>
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Fecha Esperada de Inicio</label>
                  <input
                    type="date"
                    name="fechaInicio"
                    value={task.start}
                    onChange={handleInputChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Fecha Esperada de Término</label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input
                      type="date"
                      name="fechaTermino"
                      value={task.end}
                      onChange={handleInputChange}
                      className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-l-md sm:text-sm border-gray-300"
                    />
                    <button
                      type="button"
                      className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-[#40E0D0] text-white"
                    >
                      CAMBIAR PLAZO
                    </button>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700">Documento</label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <input
                    type="text"
                    name="documento"
                    value={'Documento'}
                    onChange={handleInputChange}
                    placeholder="Adjuntar documento"
                    className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-l-md sm:text-sm border-gray-300"
                  />
                  <span className="inline-flex items-center px-3 border border-l-0 border-gray-300 bg-gray-50 text-gray-500">
                    Browse
                  </span>
                  <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500">
                    <X size={18} className="text-red-500" />
                  </span>
                  <span className="ml-2 inline-flex items-center px-3 rounded-md border border-gray-300 bg-gray-50 text-gray-500">
                    <Upload size={18} className="text-[#40E0D0]" />
                  </span>
                </div>
              </div>
              <div className="mt-4">
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-[#FFA07A] hover:bg-[#FF8C69] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FFA07A]"
                >
                  Historial de comentarios
                </button>
              </div>
            </form>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};





interface SectionHeaderProps {
  title: string;
  sectionType: 'GESTIÓN' | 'PERMISOLOGÍA';
  processingBody: string;
  onChangeSectionType: (newType: 'GESTIÓN' | 'PERMISOLOGÍA') => void;
  onChangeProcessingBody: (newBody: string) => void;
  isCollapsed: boolean;
  onToggleCollapse: () => void;
  backgroundColor: string;

}

const SectionHeader: React.FC<SectionHeaderProps> = ({ 
  title, 
  sectionType, 
  processingBody, 
  onChangeSectionType, 
  onChangeProcessingBody, 
  isCollapsed, 
  onToggleCollapse,
  backgroundColor 

}) => {

  const darkenColor = (color: string, amount: number): string => {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) - amount)).toString(16)).substr(-2));
  };

  const darkerColor = darkenColor(backgroundColor, 30); // 30 es la cantidad de oscurecimiento, ajusta según sea necesario


  return(
  <div style={{ backgroundColor }} className="text-white px-4 py-2">
    <div className="flex justify-between items-center mb-2">
      <div className="flex items-center">
        <button onClick={onToggleCollapse} className="mr-2">
          {isCollapsed ? <ChevronDown size={20} /> : <ChevronUp size={20} />}
        </button>
        <h3 className="text-sm font-bold">{title}</h3>
      </div>
      <div className="relative">
      <select
            value={sectionType}
            onChange={(e) => onChangeSectionType(e.target.value as 'GESTIÓN' | 'PERMISOLOGÍA')}
            className="text-white px-2 py-1 rounded appearance-none pr-8 text-sm"
            style={{ backgroundColor: darkerColor }}
          >
            <option value="GESTIÓN">GESTIÓN</option>
            <option value="PERMISOLOGÍA">PERMISOLOGÍA</option>
          </select>
        <ChevronDown className="absolute right-2 top-1/2 transform -translate-y-1/2 text-white" size={14} />
      </div>
    </div>
    {!isCollapsed && (
      <div className="flex items-center">
        <span className="text-sm mr-2">
          {sectionType === "PERMISOLOGÍA" ? "Organismo de Tramitación:" : "Gestión con:"}
        </span>
        <input
          type="text"
          value={processingBody}
          onChange={(e) => onChangeProcessingBody(e.target.value)}
          className=" text-white px-2 py-1 text-white rounded text-sm flex-grow"
          style={{ backgroundColor: darkerColor }}

          placeholder={sectionType === "PERMISOLOGÍA" ? "Ingrese organismo" : "Ingrese gestor"}
        />
      </div>
    )}
  </div>
);
};


const ProjectSection: React.FC<ProjectSectionProps> = ({ 
  title, 
  sectionType, 
  processingBody, 
  tasks, 
  onChangeSectionType, 
  onChangeProcessingBody, 
  onChangeTask, 
  isCollapsed, 
  subprocessColor,
  onToggleCollapse 
}) =>{
  
  
const calculateSemaphoreStatus = (start: string, end: string): SemaphoreStatus => {
  const now = new Date();
  const startDate = new Date(start);
  const endDate = new Date(end);
  const totalDuration = endDate.getTime() - startDate.getTime();
  const elapsedTime = now.getTime() - startDate.getTime();
  const remainingTime = endDate.getTime() - now.getTime();

  if (now > endDate) {
    return SemaphoreStatus.Red; // Vencida
  } else if (remainingTime <= 2 * 24 * 60 * 60 * 1000) { // 2 días o menos
    return SemaphoreStatus.Orange; // Casi por vencer
  } else if (remainingTime <= 5 * 24 * 60 * 60 * 1000) { // 5 días o menos
    return SemaphoreStatus.Yellow; // Por vencer
  } else if (now >= startDate) {
    return SemaphoreStatus.Green; // Iniciada (a tiempo)
  } else {
    return SemaphoreStatus.Gray; // No iniciada, pero dentro del plazo
  }
};


  const updateTaskStatus = (tasks: Task[]): Task[] => {
    return tasks.map(task => ({
      ...task,
      semaphoreStatus: calculateSemaphoreStatus(task.start, task.end)
    }));
  };

  useEffect(() => {
    const updatedTasks = updateTaskStatus(tasks);
    if (JSON.stringify(updatedTasks) !== JSON.stringify(tasks)) {
      onChangeTask(0, updatedTasks[0]); // Trigger a re-render with the updated tasks
    }
  }, [tasks]);


  const darkenColor = (color: string, amount: number): string => {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) - amount)).toString(16)).substr(-2));
  };

  const darkerColor = darkenColor(subprocessColor, 20); // 20 es la cantidad de oscurecimiento, ajusta según sea necesario

  const lightenColor = (color: string, amount: number): string => {
    return '#' + color.replace(/^#/, '').replace(/../g, color => 
      ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2)
    );
  };
  
  // Uso
  const lighterColor = lightenColor(subprocessColor, 115);

  return (
  <div className="mb-6 shadow-lg">
    <SectionHeader
      title={title}
      sectionType={sectionType}
      processingBody={processingBody}
      onChangeSectionType={onChangeSectionType}
      onChangeProcessingBody={onChangeProcessingBody}
      isCollapsed={isCollapsed}
      onToggleCollapse={onToggleCollapse}
      backgroundColor={subprocessColor}
    />
         <SmoothCollapse isOpen={!isCollapsed}>


      <div className="overflow-x-auto overflow-y-hidden bg-white">
        <table className="w-full">
          <thead>
            <tr style={{ backgroundColor: lighterColor }}>
              <th className="px-4 py-2 text-left text-xs font-medium text-teal-800">Descripción</th>
              <th className="px-4 py-2 text-left text-xs font-medium text-teal-800">Responsable</th>
              <th className="px-4 py-2 text-left text-xs font-medium text-teal-800">Inicio</th>
              <th className="px-4 py-2 text-left text-xs font-medium text-teal-800">Término</th>
              <th className="px-4 py-2 text-center text-xs font-medium text-teal-800">Avance (%)</th>
              <th className="px-4 py-2 text-center text-xs font-medium text-teal-800">Dependencias</th>
              <th className="px-4 py-2 text-center text-xs font-medium text-teal-800">Seguimiento</th>
              <th className="px-4 py-2 text-center text-xs font-medium text-teal-800">Estado</th>
            </tr>
          </thead>
          <tbody>
            {tasks.map((task, index) => (
                <TaskRow
                key={index}
                task={task}
                onChangeTask={(updatedTask) => onChangeTask(index, updatedTask)}
                rowColor={index % 2 === 0 ? `${subprocessColor}10` : 'white'} 
                inputColor={darkerColor}
                allTasks={tasks}

              />
            ))}
          </tbody>
        </table>
      </div>
      </SmoothCollapse>

  </div>
);
};

enum TaskStatus {
  None = "none",
  Green = "green",
  Yellow = "yellow",
  Red = "red",
}

interface Process {
  nombreProceso: string;
  codigo: string;
  comuna: string;
  subprocesos?: string[]; // Añadimos esta propiedad
  plantilla: string; // Nombre de la plantilla asociada al proceso

}

interface Subproceso {
  id: number;
  subproceso: string;
  color: string;
}


interface PlantillaDB {
  id: number;
  nombre: string;
  descripcion: string | null;
  contenido: string; // This will be a JSON string
  tipo: string;
  fecha_creacion: string;
  fecha_modificacion: string;
}

interface Plantilla {
  nombre: string;
  proceso: string;
  subprocesos: Subproceso[];
}


interface Props extends Partial<CommonComponentProps> {
  selectedProcess: string;
  setSelectedProcess: React.Dispatch<React.SetStateAction<string>>;
  processes: Process[];
}

interface User {
  id: number;
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  is_admin: boolean;
  departamento: string;
  is_active: boolean;
}


const GanttGeneral: React.FC<Props> = ({ 
  selectedProcess,
  setSelectedProcess,
  processes,
  navigateToSubComponent,
  ...props
}) => {

const [activeSubprocess, setActiveSubprocess] = useState<number>(0);
const [selectedNombreProyecto, setSelectedNombreProyecto] = useState('');
const [selectedCodigoProyecto, setSelectedCodigoProyecto] = useState('');
const [subprocesos, setSubprocesos] = useState<Subproceso[]>([]);
const [isProcessLoaded, setIsProcessLoaded] = useState(false);
const [plantillaDB, setPlantillaDB] = useState<PlantillaDB | null>(null);
const [processedPlantilla, setProcessedPlantilla] = useState<any>(null);
const [projectSections, setProjectSections] = useState<ProjectSectionProps[]>([]);
const [allCollapsed, setAllCollapsed] = useState<boolean>(false);
const [hasPlantilla, setHasPlantilla] = useState<boolean>(true);
const [hasSubprocessData, setHasSubprocessData] = useState<boolean>(true);
const [tasks, setTasks] = useState<Task[]>([]);


const [subprocessColors, setSubprocessColors] = useState<{[key: string]: string}>({});

const calculateSemaphoreStatus = (start: string, end: string): SemaphoreStatus => {
  const now = new Date();
  const startDate = new Date(start);
  const endDate = new Date(end);
  const totalDuration = endDate.getTime() - startDate.getTime();
  const elapsedTime = now.getTime() - startDate.getTime();
  const remainingTime = endDate.getTime() - now.getTime();

  if (now > endDate) {
    return SemaphoreStatus.Red; // Vencida
  } else if (remainingTime <= 3 * 24 * 60 * 60 * 1000) { // 2 días o menos
    return SemaphoreStatus.Orange; // Casi por vencer
  } else if (remainingTime <= 7 * 24 * 60 * 60 * 1000) { // 5 días o menos
    return SemaphoreStatus.Yellow; // Por vencer
  } else if (now >= startDate) {
    return SemaphoreStatus.Green; // Iniciada (a tiempo)
  } else {
    return SemaphoreStatus.Gray; // No iniciada, pero dentro del plazo
  }
};

const handleTaskProgressChange = (taskId: number, progress: number) => {
  setProjectSections(prevSections => 
    prevSections.map(section => ({
      ...section,
      tasks: section.tasks.map(task => 
        task.id === taskId ? { ...task, progress } : task
      )
    }))
  );

  // Actualizar también el estado global de las tareas
  setTasks(prevTasks => 
    prevTasks.map(task => 
      task.id === taskId ? { ...task, progress } : task
    )
  );
};


const fetchPlantillaNombre = async (codigoProyecto: string): Promise<string | null> => {
  try {
    const response = await fetch(`http://localhost:3000/php/pages/adm_planificacion/get_plantilla_nombre.php?codigo=${codigoProyecto}`);
    const data = await response.json();
    if (data && data.nombre_plantilla) {
      return data.nombre_plantilla;
    } else {
      console.error('Error al obtener el nombre de la plantilla', data);
      setHasPlantilla(false);
      return null;
    }
  } catch (error) {
    console.error('Error de red', error);
    setHasPlantilla(false);
    return null;
  }
};
const fetchPlantilla = async (nombrePlantilla: string) => {
  try {
    setPlantillaDB(null);

    if (!nombrePlantilla) {
      console.warn('Nombre de plantilla vacío. No hay plantilla disponible.');
      setHasPlantilla(false);
      return;
    }

    const response = await fetch(`http://localhost:3000/php/pages/adm_planificacion/get_plantilla.php?type=1&titulo=${encodeURIComponent(nombrePlantilla)}&tipoPlantilla=custom`);
    const data = await response.json();
    if (data && !data.error) {
      setPlantillaDB(data);
      
      processPlantilla(JSON.stringify(data.contenido));
      setHasPlantilla(true);
    } else {
      console.warn('Plantilla no encontrada o error al obtenerla.');
      setHasPlantilla(false);
    }
  } catch (error) {
    console.error('Error de red', error);
    setHasPlantilla(false);
  }
};


const loadEmptyPlantilla = (nombrePlantilla: string) => {
  const plantillaVacia = {
    id: 0,
    nombre: nombrePlantilla || "Plantilla sin nombre",
    descripcion: "Plantilla vacía",
    contenido: JSON.stringify({
      subprocesos: [
        {
          nombre: "Subproceso 1",
          agrupadores: [
            {
              nombre: "Agrupador 1",
              organismo: "",
              enabled: true,
              tareas: []
            }
          ]
        }
      ]
    }),
    tipo: "custom",
    fecha_creacion: new Date().toISOString(),
    fecha_modificacion: new Date().toISOString()
  };
  setPlantillaDB(plantillaVacia);
  processPlantilla(plantillaVacia.contenido);
};



const processPlantilla = (plantillaContenido: any) => {
  let contenido;
  if (typeof plantillaContenido === 'string') {
    try {
      contenido = JSON.parse(plantillaContenido);
      
    } catch (error) {
      console.error('Error parsing plantillaContenido JSON:', error);
      contenido = { subprocesos: [] };
    }
  } else {
    contenido = plantillaContenido;
  }

  if (!contenido || !Array.isArray(contenido.subprocesos)) {
    console.error('Invalid plantilla structure');
    contenido = { subprocesos: [] };
  }



  const processed = contenido.subprocesos.reduce((acc: any, subproceso: any) => {
    if (typeof subproceso.nombre !== 'string' || !Array.isArray(subproceso.agrupadores)) {
      console.error('Invalid subproceso structure:', subproceso);
      return acc;
    }

    if (!acc[subproceso.nombre]) {
      acc[subproceso.nombre] = [];
    }

    acc[subproceso.nombre] = acc[subproceso.nombre].concat(subproceso.agrupadores);
    
    return acc;
  }, {});

  setProcessedPlantilla(processed);

  if (Object.keys(processed).length > 0) {
    const firstSubprocessName = Object.keys(processed)[0];
    const firstSubprocessColor = subprocesos.find(sp => sp.subproceso === firstSubprocessName)?.color || '#000000';
    loadSubprocessData(firstSubprocessName, processed[firstSubprocessName], firstSubprocessColor);
  } else {
    setProjectSections([]);
  }
};




const loadSubprocessData = (subprocessName: string, agrupadores: Group[], subprocessColor: string) => {
  if (!agrupadores || agrupadores.length === 0) {
    setProjectSections([]);
    setHasSubprocessData(false);
    setTasks([]);

    return;
  }

  const newProjectSections: ProjectSectionProps[] = agrupadores
    .filter(agrupador => agrupador.enabled)
    .map((agrupador: Group) => {
      

      const enabledTareas = agrupador.tareas.filter(tarea => tarea.enabled);
      const sectionType = enabledTareas.length > 0 ? enabledTareas[0].descriptor.toUpperCase() as 'GESTIÓN' | 'PERMISOLOGÍA' : 'GESTIÓN';
      const processingBody = enabledTareas.length > 0 ? enabledTareas[0].organismo : '';

      return {
        title: agrupador.nombre,
        sectionType: sectionType,
        processingBody: processingBody,
        isCollapsed: false,
        tasks: enabledTareas.map((tarea): Task => ({ 
          id: tarea.id,
          description: tarea.nombre,
          responsible: tarea.responsable,
          progress: tarea.avance,
          start: tarea.fecha_inicio,
          end: tarea.fecha_termino,
          color: tarea.color,
          followUp: false,
          status: TaskStatus.None,
          descriptor: tarea.descriptor as 'GESTIÓN' | 'PERMISOLOGÍA',
          followUpDate: tarea.fechaSeguimiento,
          semaphoreStatus: calculateSemaphoreStatus(tarea.fecha_inicio, tarea.fecha_termino),
          dependencia: tarea.dependencia,
          name: tarea.nombre,
          organism: tarea.organismo,
          dependsOn: tarea.dependencia ? [{ groupId: agrupador.id, taskId: tarea.dependencia }] : [],
          enabled: tarea.enabled
        })),
        onChangeSectionType: () => {},
        onChangeProcessingBody: () => {},
        onChangeTask: () => {},
        onToggleCollapse: () => {},
        subprocessColor: subprocessColor,
      };
      
    })
    .filter((section) => section.tasks.length > 0); // Only include sections with tasks

  setProjectSections(newProjectSections);
  setHasSubprocessData(true);
  // Agregar las tareas al estado tasks
  const allTasks = newProjectSections.flatMap(section => section.tasks);
  setTasks(allTasks);

};

const handleSubprocessChange = (index: number) => {
  setActiveSubprocess(index);
  const selectedSubprocess = subprocesos[index];
  if (processedPlantilla && processedPlantilla[selectedSubprocess.subproceso]) {
    const agrupadores = processedPlantilla[selectedSubprocess.subproceso];
    loadSubprocessData(selectedSubprocess.subproceso, agrupadores, selectedSubprocess.color);
    setHasSubprocessData(true);

  } else {
    console.warn(`No se encontraron datos para el subproceso: ${selectedSubprocess.subproceso}`);
    setHasSubprocessData(false);
  }
};

  
const handleChangeSectionType = (sectionIndex: number, newType: 'GESTIÓN' | 'PERMISOLOGÍA') => {
  const updatedSections = [...projectSections];
  updatedSections[sectionIndex] = {
    ...updatedSections[sectionIndex],
    sectionType: newType
  };
  setProjectSections(updatedSections);
};

const handleChangeProcessingBody = (sectionIndex: number, newBody: string) => {
  const updatedSections = [...projectSections];
  updatedSections[sectionIndex] = {
    ...updatedSections[sectionIndex],
    processingBody: newBody
  };
  setProjectSections(updatedSections);
};

const handleChangeTask = (sectionIndex: number, taskIndex: number, updatedTask: Task) => {
  const updatedSections = [...projectSections];
  const updatedTasks = [...updatedSections[sectionIndex].tasks];
  updatedTasks[taskIndex] = updatedTask;
  updatedSections[sectionIndex] = {
    ...updatedSections[sectionIndex],
    tasks: updatedTasks
  };
  setProjectSections(updatedSections);
};

  const handleToggleCollapse = (sectionIndex: number) => {
    const updatedSections = [...projectSections];
    updatedSections[sectionIndex].isCollapsed = !updatedSections[sectionIndex].isCollapsed;
    setProjectSections(updatedSections);
  };

  const handleToggleAllCollapse = () => {
    const newCollapsedState = !allCollapsed;
    setAllCollapsed(newCollapsedState);
    const updatedSections = projectSections.map(section => ({
      ...section,
      isCollapsed: newCollapsedState
    }));
    setProjectSections(updatedSections);
  };
  
  const resetState = () => {
    setSelectedCodigoProyecto('');
    setSubprocesos([]);
    setIsProcessLoaded(false);
    setProjectSections([]);
  };

  
  useEffect(() => {
    if (subprocesos.length > 0 && processedPlantilla) {
      const firstSubprocess = subprocesos[0];
      if (processedPlantilla[firstSubprocess.subproceso]) {
        const agrupadores = processedPlantilla[firstSubprocess.subproceso];
        loadSubprocessData(firstSubprocess.subproceso, agrupadores, firstSubprocess.color);
        setActiveSubprocess(0);
      } else {
        // If there are no groupers for the first subprocess, set an empty state
        setProjectSections([]);
      }
    }
  }, [subprocesos, processedPlantilla]);
  

  
  useEffect(() => {
    if (selectedProcess) {
      
   
        setSelectedProcess(selectedProcess);
     loadProcessData(selectedProcess);
    } else {
      resetState();
    }
  }, [selectedProcess]);


  if (!selectedProcess) {
    return <NoProcesoSeleccionadoMessage />;
  }

  


  const loadProcessData = async (processName: string) => {
    
    const selectedProcessObj = processes.find(p => p.nombreProceso === processName);
    
    if (selectedProcessObj) {
      setSelectedNombreProyecto(selectedProcessObj.nombreProceso);
      setSelectedCodigoProyecto(selectedProcessObj.codigo);
      await fetchSubprocesos(selectedProcessObj.codigo);
      const plantillaNombre = await fetchPlantillaNombre(selectedProcessObj.codigo);
      if (plantillaNombre) {
        await fetchPlantilla(plantillaNombre);
        setIsProcessLoaded(true);
      } else {
        console.error('No se pudo obtener el nombre de la plantilla');
      
        setHasPlantilla(false);
      }
    }
  };


/*

  useEffect(() => {
    setSelectedCodigoProyecto('');
      setSubprocesos([]);
  
  
      const fetchProcesses = async () => {
      try {
        const response = await fetch('http://localhost:3000/php/pages/proceso/get_processes.php?type=type3');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data: Process[] = await response.json();
        
        setProcesses(data);
      } catch (error) {
        console.error('Error fetching processes:', error);
      }
    };
    fetchProcesses();
  }, []);
  
  */




  const fetchSubprocesos = async (codigo: string) => {
    try {
      const response = await fetch(`http://localhost:3000/php/pages/transmittal/get_subprocesos.php?codigo=${codigo}`);
      const data = await response.json();
      
      if (data && typeof data === 'object' && Array.isArray(data.subprocesos)) {
        setSubprocesos(data.subprocesos.map((subproceso: Subproceso) => ({
          ...subproceso,
          color: subproceso.color || getDefaultColor(subproceso.id) // Usamos el color del backend o generamos uno por defecto
        })));
      } else {
        console.error('Error al obtener los subprocesos', data);
        setSubprocesos([]);
      }
    } catch (error) {
      console.error('Error de red', error);
      setSubprocesos([]);
    }
  };
  
  // Función auxiliar para generar un color por defecto si no se proporciona uno
  const getDefaultColor = (id: number): string => {
    const colors = ['#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8', '#F7DC6F', '#BB8FCE', '#5DADE2', '#45B39D', '#EC7063'];
    return colors[id % colors.length];
  };

  /*
  const handleProcessChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    alert(selectedValue);
    if (selectedValue === "") {
      // "Asignar proceso" was selected
      setSelectedCodigoProyecto('');
      setSubprocesos([]);
      setIsProcessLoaded(false)
      setProjectSections([]);
    } else {
      setSelectedProcess?.(selectedValue); // Use optional chaining
      const selectedProcess = processes.find(p => p.nombreProceso === selectedValue);
      if (selectedProcess) {
        
        setSelectedCodigoProyecto(selectedProcess.codigo);
        
        // Cargar subprocesos
        fetchSubprocesos(selectedProcess.codigo);
        setIsProcessLoaded(true);

         // Fetch plantilla name
         const plantillaNombre = await fetchPlantillaNombre(selectedProcess.codigo);
         if (plantillaNombre) {
          await fetchPlantilla(plantillaNombre); 
          setIsProcessLoaded(true);


         } else {
           console.error('No se pudo obtener el nombre de la plantilla');
           setIsProcessLoaded(false);
         }

      }
      
    }
  };
*/


const handleProcessChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
  const selectedValue = event.target.value;
  if (selectedValue === "") {
    // "Asignar proceso" was selected
    setSelectedCodigoProyecto('');
    setSelectedNombreProyecto('');
    setSubprocesos([]);
    setIsProcessLoaded(false)
  } else {
    const selectedProcess = processes.find(p => p.nombreProceso === selectedValue);
    if (selectedProcess) {
      //alert(selectedProcess)
      setSelectedCodigoProyecto(selectedProcess.codigo);
      setSelectedNombreProyecto(selectedProcess.nombreProceso);
      
      // Cargar subprocesos
      fetchSubprocesos(selectedProcess.codigo);
      setIsProcessLoaded(true);

       // Fetch plantilla name
       const plantillaNombre = await fetchPlantillaNombre(selectedProcess.codigo);
       if (plantillaNombre) {
        await fetchPlantilla(plantillaNombre); 
        setIsProcessLoaded(true);


       } else {
         console.error('No se pudo obtener el nombre de la plantilla');
         setHasPlantilla(false);
         setIsProcessLoaded(false);
       }

    }
    
  }
};


const handleNavigateToEditPlantillas = () => {
  if (navigateToSubComponent) {
    navigateToSubComponent('ADMINISTRACIÓN', 'Editar plantillas');
  } else {
    console.error('navigateToSubComponent is not defined');
  }
};
const sectionVariants = {
  hidden: { opacity: 0, y: 10 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: { 
      type: "spring",
      stiffness: 100,
      damping: 15,
      mass: 0.3
    }
  },
  exit: { 
    opacity: 0, 
    y: -10, 
    transition: { 
      duration: 0.2
    }
  }
};

// 1. Animación de deslizamiento lateral
const slideVariants = {
  hidden: { x: '-100%' },
  visible: { 
    x: 0,
    transition: { type: 'spring', stiffness: 120, damping: 20 }
  },
  exit: { x: '100%' }
};

// 2. Animación de fundido con escala
const fadeScaleVariants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { 
    opacity: 1, 
    scale: 1,
    transition: { duration: 0.3 }
  },
  exit: { opacity: 0, scale: 1.2 }
};

// 3. Animación de aparición desde el centro
const expandVariants = {
  hidden: { opacity: 0, scale: 0 },
  visible: { 
    opacity: 1, 
    scale: 1,
    transition: { type: 'spring', stiffness: 200, damping: 20 }
  },
  exit: { opacity: 0, scale: 0 }
};

// 4. Animación de rotación y escala
const rotateScaleVariants = {
  hidden: { rotate: -180, scale: 0 },
  visible: { 
    rotate: 0, 
    scale: 1,
    transition: { type: 'spring', stiffness: 150, damping: 20 }
  },
  exit: { rotate: 180, scale: 0 }
};

// 5. Animación de rebote
const bounceVariants = {
  hidden: { y: '-100%', opacity: 0 },
  visible: { 
    y: 0,
    opacity: 1,
    transition: { 
      type: 'spring',
      stiffness: 300,
      damping: 25,
      mass: 0.5
    }
  },
  exit: { y: '100%', opacity: 0 }
};


const getSubprocessColor = (subprocess: string) => {
  const predefinedColors = [
    '#ADD8E6', '#90EE90', '#FFDAB9', '#E6E6FA', '#FFFACD', '#F5DEB3', '#D8BFD8', '#87CEFA', 
    '#D3FFCE', '#F0E68C', '#E0FFFF', '#FAFAD2', '#FFB6C1', '#FFE4B5', '#98FB98', '#E0B0FF', 
    '#FFCCCB', '#FFD700', '#B0E0E6', '#FFDEAD', '#FF69B4', '#FFE4E1', '#F0FFF0', '#E6E6FA'
  ];

  let hash = 0;
  for (let i = 0; i < subprocess.length; i++) {
    hash = subprocess.charCodeAt(i) + ((hash << 5) - hash);
  }

  return predefinedColors[Math.abs(hash) % predefinedColors.length];
};




const lightenColor = (color: string, amount: number): string => {
  const num = parseInt(color.replace("#", ""), 16);
  const r = Math.min(255, Math.max(0, (num >> 16) + amount));
  const g = Math.min(255, Math.max(0, ((num >> 8) & 0x00FF) + amount));
  const b = Math.min(255, Math.max(0, (num & 0x0000FF) + amount));
  return `#${(1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1)}`;
};

// Función para oscurecer colores
const darkenColor = (color: string, amount: number): string => {
  return lightenColor(color, -amount);
};

const filterEnabledTasksAndGroups = (tasksState: TasksState): TasksState => {
  const filteredGroups = tasksState.groups
    .filter(group => group.enabled)
    .map(group => {
      const subprocessColor = subprocessColors[group.subprocess] || getSubprocessColor(group.subprocess);
      return {
        ...group,
        color: subprocessColor,
        tasks: group.tasks
          .filter(task => task.enabled)
          .map(task => ({
            ...task,
            color: darkenColor(subprocessColor, 60)
          }))
      };
    })
    .filter(group => group.tasks.length > 0);

  // If there are no enabled groups or tasks, create a dummy task
  if (filteredGroups.length === 0) {
    const today = new Date().toISOString().split('T')[0];
    const defaultColor = "#CCCCCC";
    filteredGroups.push({
      id: 0, // Add this line
      nombre: "No hay tareas habilitadas",
      expanded: true,
      subprocess: "",
      agrupador: "Sin agrupador",
      enabled: true,
      descriptor: 'GESTIÓN',
      organismo: '',
      color: defaultColor,
      tasks: [{
        id: 0,
        name: "Sin tareas",
        description: "Sin tareas",
        responsible: "",
        progress: 0,
        start: today,
        end: today,
        followUp: false,
        status: TaskStatus.None,
        descriptor: 'GESTIÓN',
        followUpDate: today,
        semaphoreStatus: SemaphoreStatus.Gray,
        dependencia: null,
        organism: "",
        dependsOn: [],
        enabled: true,
        color: darkenColor(defaultColor, 60)
      }],
      tareas: [] // Add this line
    });
  }

  return {
    ...tasksState,
    groups: filteredGroups
  };
};

const convertToGanttTasksState = (sections: ProjectSectionProps[]): GanttTasksState => {
  return {
    name: selectedNombreProyecto,
    groups: sections.map(section => ({
      name: section.title,
      expanded: !section.isCollapsed,
      subprocess: subprocesos[activeSubprocess]?.subproceso || "",
      agrupador: section.title,
      tasks: section.tasks.map(task => ({
        id: task.id,
        name: task.description,
        responsible: task.responsible,
        progress: task.progress,
        start: task.start,
        end: task.end,
        descriptor: task.descriptor,
        organism: task.organism,
        dependsOn: task.dependsOn,
        enabled: task.enabled,
        color: task.color || section.subprocessColor,
        // Añadir cualquier otro campo que pueda ser necesario para la gráfica Gantt
        followUp: task.followUp,
        status: task.status,
        followUpDate: task.followUpDate,
        semaphoreStatus: task.semaphoreStatus,
        dependencia: task.dependencia
      })),
      descriptor: section.sectionType,
      organismo: section.processingBody,
      enabled: true,
      color: section.subprocessColor
    }))
  };
};
const isValidGanttData = (data: GanttTasksState): boolean => {
  return data.groups.length > 0 && data.groups.some(group => group.tasks.length > 0);
};

return (
  <motion.div 
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.3 }}
    className="w-full bg-white rounded-lg p-3 mx-auto"
  >
    {isProcessLoaded && (
      <AnimatePresence mode="wait">
        {hasPlantilla ? (
          <motion.div
            key="plantilla-content"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
              <div className="sticky top-0 z-10 bg-white">
                <div className="p-2 mx-auto">
                  <span className="font-bold ">Subprocesos:</span>
                  <div className="flex justify-between items-center border-b border-none py-2">
                    <div className="flex flex-wrap items-center flex-grow">
                      {subprocesos.map((subprocess, index) => (
                          <SubprocessTab
                          key={index}
                          label={subprocess.subproceso}
                          isActive={activeSubprocess === index}
                          onClick={() => handleSubprocessChange(index)}
                          color={subprocess.color}
                          index={index}
                          totalTabs={subprocesos.length}
                          activeIndex={activeSubprocess}
                        />
                      ))}
                    </div>
                  
                  </div>
                  <GlobalProgressSlider tasks={tasks} onTaskProgressChange={handleTaskProgressChange} />
                  </div>
              

              </div>
              <motion.div 
                className="pr-2 pl-2 mx-auto"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.2 }}
              >
                <AnimatePresence>
                {!hasSubprocessData || !isValidGanttData(convertToGanttTasksState(projectSections)) ? (
                    <motion.div
                      key="no-data-message"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      className="text-center py-4"
                    >
                      <p>Este subproceso no tiene datos disponibles.</p>
                    </motion.div>
                  ) : (
                    <GanttChartView tasks={convertToGanttTasksState(projectSections)} />
                    
                  )}
                </AnimatePresence>
              </motion.div>
            </motion.div>
          ) : (
            <motion.div
              key="no-plantilla-message"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              <NoPlantillaMessage navigateToSubComponent={handleNavigateToEditPlantillas} />
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </motion.div>
  );
};

export default GanttGeneral;