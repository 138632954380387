import React, { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const ConstructionDashboard = () => {
  const [selectedDocument, setSelectedDocument] = useState('Seleccione...');

  const additionalWorks = [
    { id: 1, dias: 4 },
    { id: 2, dias: 2 },
    { id: 3, dias: 1 },
    { id: 4, dias: 8 },
    { id: 5, dias: 10 },
    { id: 6, dias: 12 },
    { id: 7, dias: 5 },
  ];

  const chartData = additionalWorks.map(work => ({
    name: `Adicional ${work.id}`,
    dias: work.dias,
  }));

  const documents = [
    'Seleccione...',
    'Carta Solicitud empresa constructora',
    'Presupuesto detallado de las obras',
    'Instrucción que da origen al requerimiento',
    'Detalle del plano contratado y modificado',
    'Cubicaciones propias y revisión de los precios unitarios',
    'Otros',
    'Carátula Obras Adicionales',
  ];

  return (
    <div className="max-w-6xl mx-auto p-6 bg-white rounded-lg">
      <div className="flex justify-between mb-4">
        <ChevronLeft className="w-10 h-10 text-gray-500 cursor-pointer" />
        <ChevronRight className="w-10 h-10 text-gray-500 cursor-pointer" />
      </div>

      <div className="grid grid-cols-2 gap-6">
        {/* Left column */}
        <div>
          <table className="w-full mb-4">
            <thead>
              <tr className="bg-blue-500 text-white">
                <th className="p-2 text-left">Adicional N°</th>
                <th className="p-2 text-left">Ver documentos</th>
              </tr>
            </thead>
            <tbody>
              {additionalWorks.map((work) => (
                <tr key={work.id} className="border-b">
                  <td className="p-2 bg-blue-500 text-white">Adicional N°{work.id}</td>
                  <td className="p-2 bg-blue-500 text-white">
                    <button className="underline">Ver documentos</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="mb-4">
            <p className="font-bold mb-2">Documento Pago Adicional</p>
            <select 
              value={selectedDocument} 
              onChange={(e) => setSelectedDocument(e.target.value)}
              className="w-full p-2 border rounded"
            >
              {documents.map((doc, index) => (
                <option key={index} value={doc}>{doc}</option>
              ))}
            </select>
          </div>
        </div>

        {/* Right column */}
        <div>
          <table className="w-full mb-4">
            <thead>
              <tr>
                <th className="p-2 text-left">Adicionales</th>
                <th className="p-2 text-left">Días de Respuesta</th>
              </tr>
            </thead>
            <tbody>
              {additionalWorks.map((work) => (
                <tr key={work.id} className="border-b">
                  <td className="p-2">Adicional {work.id}</td>
                  <td className="p-2">{work.dias}</td>
                </tr>
              ))}
              <tr className="bg-yellow-200">
                <td className="p-2 font-bold">Promedio</td>
                <td className="p-2 font-bold">6</td>
              </tr>
            </tbody>
          </table>

          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="dias" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConstructionDashboard;
