import React from 'react';

const PrioritizationMatrix = () => {
  const quadrants = [
    { title: 'Hacer', color: '#ffd699', urgency: 'Urgente', importance: 'Importante' },
    { title: 'Decidir', color: '#ff9999', urgency: 'No Urgente', importance: 'Importante' },
    { title: 'Delegar', color: '#99ccff', urgency: 'Urgente', importance: 'No Importante' },
    { title: 'Postergar o Reagendar', color: '#66cccc', urgency: 'No Urgente', importance: 'No Importante' },
  ];

  return (
    <div className="flex flex-col items-center p-4  rounded-lg shadow-md">
      <div className="text-xl font-bold mb-4">Matriz de Priorización</div>
      <div className="relative w-96 h-96">
        {/* Axes labels */}
        <div className="absolute -left-20 top-1/2 -translate-y-1/2 transform -rotate-90 text-lg font-semibold">
          Importante
        </div>
        <div className="absolute left-0 -top-8 text-lg font-semibold">
          Urgente
        </div>
        <div className="absolute right-0 -top-8 text-lg font-semibold">
          No Urgente
        </div>
        <div className="absolute -left-24 bottom-0 text-lg font-semibold">
          No Importante
        </div>

        {/* Quadrants */}
        <div className="grid grid-cols-2 gap-2 w-full h-full">
          {quadrants.map((quadrant, index) => (
            <div
              key={index}
              className="flex items-center justify-center rounded-lg shadow-md text-center p-2"
              style={{ backgroundColor: quadrant.color }}
            >
              <span className="text-lg font-semibold">{quadrant.title}</span>
            </div>
          ))}
        </div>

        {/* Axes */}
        <div className="absolute top-0 left-1/2 w-0.5 h-full bg-cyan-400 -translate-x-1/2 transform"></div>
        <div className="absolute top-1/2 left-0 w-full h-0.5 bg-cyan-400 -translate-y-1/2 transform"></div>
      </div>
    </div>
  );
};

export default PrioritizationMatrix;
