import React from 'react';

const estilosTabla: React.CSSProperties = {
  fontFamily: 'Arial, sans-serif',
  borderCollapse: 'separate' as const,
  borderSpacing: '0 10px',
  width: '100%',
  maxWidth: '800px',
  margin: '0 auto',
  backgroundColor: '#f8f9fa',
  padding: '20px',
  borderRadius: '8px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
};

const estilosCelda: React.CSSProperties = {
  padding: '12px 15px',
  textAlign: 'center' as const,
  borderBottom: '1px solid #e0e0e0',
};

const estilosEncabezado: React.CSSProperties = {
  ...estilosCelda,
  fontWeight: 'bold',
  borderBottom: '2px solid #3498db',
  color: '#2c3e50',
};

const estilosFilaEtiqueta: React.CSSProperties = {
  backgroundColor: '#ecf0f1',
  fontWeight: 'bold',
  color: '#34495e',
};

const datos = {
  encabezados: ['ITEM', 'PROGRAMA 1', 'PROGRAMA 2', 'PROGRAMA 3', 'PROGRAMA 4', 'PROGRAMA 5'],
  subEncabezados: ['DS19', 'DS19', 'DS19', 'DS01', 'DS01'],
  filas: [
    { etiqueta: 'TIR', valores: [3.5, 2.6, 3.2, -5.8, -6.1] },
    { etiqueta: 'VAN', valores: [-2975, -4339, -4111, -31729, -40572] },
    { etiqueta: 'EXCED.', valores: [5207, 4027, 5700, -17723, -24140] },
    { etiqueta: 'INGRESOS', valores: [183630, 195150, 249140, 470000, 455730], etiquetaFila: 'A' },
    { etiqueta: 'TERRENO', valores: [20343, 22874, 40342, 53851, 50391], etiquetaFila: 'B' },
    { etiqueta: 'TIERR - %', valores: [11.1, 11.7, 16.2, 11.5, 11.1] },
    { etiqueta: 'COSTO CONST', valores: [105868, 112475, 152375, 292702, 274723], etiquetaFila: 'C' },
    { etiqueta: 'C_CONST - %', valores: [57.7, 57.6, 61.2, 62.3, 60.3] },
    { etiqueta: 'OBRAS EXTRAS', valores: [14742, 17475, 6134, 47828, 67714], etiquetaFila: 'D' },
    { etiqueta: 'ADIC - %', valores: [13.9, 15.5, 4.0, 16.3, 24.6] },
    { etiqueta: 'INTERESES CONST', valores: [7030, 6759, 8335, 18581, 11807], etiquetaFila: 'E' },
    { etiqueta: 'INT - %', valores: [3.8, 3.5, 3.3, 4.0, 2.6] },
    { etiqueta: 'UTILIDAD', valores: [25652, 27348, 26700, 65508, 64865], etiquetaFila: 'F' },
    { etiqueta: 'CS - %', valores: [14.0, 14.0, 10.7, 13.9, 14.2] },
    { etiqueta: 'MESES PROYECTO', valores: [42, 42, 39, 42, 47], etiquetaFila: 'G' }
  ]
};

const TablaRentabilidadModerna = () => {
  return (
    <table style={estilosTabla}>
      <thead>
        <tr>
          <th colSpan={6} style={{...estilosEncabezado, fontSize: '1.2em', padding: '20px', backgroundColor: '#3498db', color: 'white', borderRadius: '8px 8px 0 0' }}>
            Rentabilidad 2023
          </th>
        </tr>
        <tr>
          {datos.encabezados.map((encabezado, index) => (
            <th key={index} style={estilosEncabezado}>{encabezado}</th>
          ))}
        </tr>
        <tr>
          <th style={estilosEncabezado}></th>
          {datos.subEncabezados.map((subEncabezado, index) => (
            <th key={index} style={{...estilosEncabezado, fontWeight: 'normal', fontSize: '0.9em'}}>{subEncabezado}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {datos.filas.map((fila, indiceFila) => (
          <tr key={indiceFila}>
            <td style={{
              ...estilosCelda,
              ...(fila.etiquetaFila ? estilosFilaEtiqueta : {}),
              textAlign: 'left',
            }}>
              {fila.etiquetaFila && <span style={{marginRight: '5px', color: '#3498db'}}>{fila.etiquetaFila}</span>}
              {fila.etiqueta}
            </td>
            {fila.valores.map((valor, indiceCelda) => (
              <td key={indiceCelda} style={{
                ...estilosCelda,
                color: valor > 0 ? '#27ae60' : valor < 0 ? '#e74c3c' : 'inherit',
              }}>
                {typeof valor === 'number' ? 
                  (Number.isInteger(valor) ? valor.toLocaleString() : valor.toFixed(1)) : valor}
                {typeof valor === 'number' && !Number.isInteger(valor) && valor !== 0 ? '%' : ''}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TablaRentabilidadModerna;
