import React, { useEffect, useState } from 'react';
import { Edit, Trash, CheckCircle, XCircle } from 'lucide-react';

interface User {
  id: number;
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  is_admin: boolean;
  departamento: string;
  is_active: boolean;
}

const UserList: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const departamentos = ['Finanzas', 'Arquitectura', 'Contabilidad', 'Recursos Humanos', 'IT'];


  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch('http://localhost:3000/php/pages/users/get_users.php');
        if (!response.ok) throw new Error('Error al obtener los usuarios.');
        
        const data: User[] = await response.json();
        setUsers(data);
      } catch (error) {
        setError((error as Error).message);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleDelete = async (id: number) => {
    try {
      const response = await fetch('http://localhost:3000/php/pages/users/delete_user.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id }),
      });
      const data = await response.json();

      if (data.success) {
        setUsers(users.filter(user => user.id !== id));
      } else {
        setError(data.error || 'Error al eliminar el usuario.');
      }
    } catch (error) {
      setError('Ocurrió un error al eliminar el usuario.');
    }
  };

  const handleEdit = (user: User) => {
    setSelectedUser(user);
    setIsEditing(true);
  };

  const handleSave = async () => {
    if (selectedUser) {
      try {
        const response = await fetch('http://localhost:3000/php/pages/users/update_user.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(selectedUser),
        });
        const data = await response.json();

        if (data.success) {
          setUsers(users.map(user => (user.id === selectedUser.id ? selectedUser : user)));
          setIsEditing(false);
          setSelectedUser(null);
        } else {
          setError(data.error || 'Error al actualizar el usuario.');
        }
      } catch (error) {
        setError('Ocurrió un error al actualizar el usuario.');
      }
    }
  };

  const handleToggleActive = async (id: number, isActive: boolean) => {
    try {
      const response = await fetch('http://localhost:3000/php/pages/users/update_user_status.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id, is_active: !isActive }),
      });
      const data = await response.json();

      if (data.success) {
        setUsers(users.map(user => (user.id === id ? { ...user, is_active: !isActive } : user)));
      } else {
        setError(data.error || 'Error al actualizar el estado del usuario.');
      }
    } catch (error) {
      setError('Ocurrió un error al actualizar el estado del usuario.');
    }
  };

  const handleToggleAdmin = async (id: number, isAdmin: boolean) => {
    try {
      const response = await fetch('http://localhost:3000/php/pages/users/update_user_admin.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id, is_admin: !isAdmin }),
      });
      const data = await response.json();

      if (data.success) {
        setUsers(users.map(user => (user.id === id ? { ...user, is_admin: !isAdmin } : user)));
      } else {
        setError(data.error || 'Error al actualizar el estado de administrador.');
      }
    } catch (error) {
      setError('Ocurrió un error al actualizar el estado de administrador.');
    }
  };

  if (loading) {
    return <div className="text-center text-lg">Cargando...</div>;
  }

  if (error) {
    return <div className="text-center text-red-600">{error}</div>;
  }

  return (
    <div className="p-6">

      <table className="min-w-full bg-white border border-gray-200 rounded-md shadow-md">
        <thead>
          <tr className="bg-gray-100 text-gray-600">
            <th className="py-3 px-4 border-b">Usuario</th>
            <th className="py-3 px-4 border-b">Nombre</th>
            <th className="py-3 px-4 border-b">Apellido</th>
            <th className="py-3 px-4 border-b">Correo Electrónico</th>
            <th className="py-3 px-4 border-b">Administrador</th>
            <th className="py-3 px-4 border-b">Departamento</th>
            <th className="py-3 px-4 border-b">Activo</th>
            <th className="py-3 px-4 border-b">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.id} className="hover:bg-gray-50">
              <td className="py-2 px-4 border-b">{user.username}</td>
              <td className="py-2 px-4 border-b">{user.firstname}</td>
              <td className="py-2 px-4 border-b">{user.lastname}</td>
              <td className="py-2 px-4 border-b">{user.email}</td>
              <td className="py-2 px-4 border-b text-center">
                <button
                  onClick={() => handleToggleAdmin(user.id, user.is_admin)}
                  className={`w-5 h-5 ${user.is_admin ? 'text-green-600' : 'text-gray-400'}`}
                >
                  {user.is_admin ? <CheckCircle /> : <XCircle />}
                </button>
              </td>
              <td className="py-2 px-4 border-b">{user.departamento}</td>
              <td className="py-2 px-4 border-b text-center">
                <button
                  onClick={() => handleToggleActive(user.id, user.is_active)}
                  className={`w-5 h-5 ${user.is_active ? 'text-green-600' : 'text-gray-400'}`}
                >
                  {user.is_active ? <CheckCircle /> : <XCircle />}
                </button>
              </td>
              <td className="py-4 px-4 border-b flex space-x-2">
                <button
                  onClick={() => handleEdit(user)}
                  className="text-blue-600 hover:text-blue-800"
                >
                  <Edit className="w-5" />
                </button>
                <button
                  onClick={() => handleDelete(user.id)}
                  className="text-red-600 hover:text-red-800"
                >
                  <Trash className="w-5" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isEditing && selectedUser && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-md shadow-lg max-w-md w-full">
            <h3 className="text-xl font-semibold mb-4">Editar Usuario</h3>
            <form
              onSubmit={e => {
                e.preventDefault();
                handleSave();
              }}
            >
              <div className="mb-4">
                <label className="block text-gray-700">Nombre de Usuario:</label>
                <input
                  type="text"
                  value={selectedUser.username}
                  onChange={e => setSelectedUser({ ...selectedUser, username: e.target.value })}
                  className="w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Nombre:</label>
                <input
                  type="text"
                  value={selectedUser.firstname}
                  onChange={e => setSelectedUser({ ...selectedUser, firstname: e.target.value })}
                  className="w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Apellido:</label>
                <input
                  type="text"
                  value={selectedUser.lastname}
                  onChange={e => setSelectedUser({ ...selectedUser, lastname: e.target.value })}
                  className="w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Correo Electrónico:</label>
                <input
                  type="email"
                  value={selectedUser.email}
                  onChange={e => setSelectedUser({ ...selectedUser, email: e.target.value })}
                  className="w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div className="mb-4 flex items-center">
                <input
                  type="checkbox"
                  checked={selectedUser.is_admin}
                  onChange={e => setSelectedUser({ ...selectedUser, is_admin: e.target.checked })}
                  className="mr-2"
                />
                <label className="text-gray-700">Administrador</label>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Departamento:</label>
                <select
                  value={selectedUser.departamento}
                  onChange={e => setSelectedUser({ ...selectedUser, departamento: e.target.value })}
                  className="w-full p-2 border border-gray-300 rounded-md"
                >
                  <option value="" disabled>Seleccionar departamento</option>
                  {departamentos.map(departamento => (
                    <option key={departamento} value={departamento}>{departamento}</option>
                  ))}
                </select>
              </div>
              <div className="flex justify-end space-x-2">
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                >
                  Guardar
                </button>
                <button
                  type="button"
                  onClick={() => setIsEditing(false)}
                  className="px-4 py-2 bg-gray-400 text-white rounded-md hover:bg-gray-500"
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserList;
