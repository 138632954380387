// src/pages/PublicRoute.tsx
import React from 'react';
import { Navigate } from 'react-router-dom';

interface PublicRouteProps {
  children: React.ReactElement;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ children }) => {
  // Aquí debes comprobar si el usuario está autenticado
  const isAuthenticated = !!localStorage.getItem('authToken'); // O tu lógica de autenticación

  return !isAuthenticated ? children : <Navigate to="/ui" />; // Redirige a la página de inicio si ya está autenticado
};

export default PublicRoute;
