import React, { useState } from 'react';
import { ChevronLeft, ChevronRight, ChevronDown, ChevronUp } from 'lucide-react';

const Dashboard: React.FC = () => {
  const [openSection, setOpenSection] = useState<string>('Generalidades');

  const buttons = [
    'Galería de Fotos',
    'Control de Hormigones',
    'Visita Mecánica Suelos',
    'Proveedores',
    'Calidad',
    'Seguridad',
    'Inspecciones'
  ];

  const sections: { [key: string]: string[] } = {
    Generalidades: [
      'Visita mecánica terreno ejecutada al 100%',
      'Viviendas pilotos se entregan a fin de mes'
    ],
    Plazo: [
      'Se prepara solicitud de aumento de plazo, ya que plazo contractual vence el día 16 de abril 2022.',
      'Se realiza solicitud de números municipales en la DOM Digital. DOM visita obra realiza corrección menor y se trabaja en la elaboración del plano.',
      'Arqueólogo termina trabajos de sondeo, estará trabajando en informe para presentarnos la próxima semana.',
      'Continúa la falta de mano de obra de, Ceramistas, Carpintería, pinturas, gasfitería y mano de obra no especializada para labores delimpieza de patios y colocación de hormigones, se estima un déficit de 50%, lo cual genera un avance lento de las actividades.',
      'Cuarentenas 1-2-3 totalizan 148 días, donde se trabajó con permisos únicos colectivos.'
    ],
    Calidad: [
      'Se mantienen revisiones de: instalación de paneles sip, estructuras metálicas, hermeticidad, cubiertas y revestimientos.'
    ],
    Costo: [
      'E.C. manifiesta dificultades para cerrar compras por alzas de costos de materiales y algunos subcontratos, en revisión.',
      'E.C. Analiza costos de muro de contención para talud borde quebrada.',
      'E.C. Analiza costos de sala multiuso en estructura metálica.'
    ]
  };

  // Especificamos el tipo del parámetro 'section'
  const toggleSection = (section: string) => {
    setOpenSection(openSection === section ? '' : section);
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg">
      <div className="flex justify-between items-center mb-6">
        <div>
          <h2 className="text-xl font-bold">Reporte N° 20</h2>
          <p className="text-sm text-gray-600">Fecha: abr-20</p>
        </div>
        <div className="flex space-x-2">
          <ChevronLeft className="w-6 h-6 text-blue-500 cursor-pointer" />
          <ChevronRight className="w-6 h-6 text-blue-500 cursor-pointer" />
        </div>
      </div>

      <div className="flex flex-wrap gap-2 mb-6">
        {buttons.map((button) => (
          <button
            key={button}
            className="px-4 py-2 bg-blue-500 text-white rounded-full text-sm hover:bg-blue-600 transition-colors"
          >
            {button}
          </button>
        ))}
      </div>

      <div className="space-y-4">
        {Object.entries(sections).map(([title, items]) => (
          <div key={title} className="border border-blue-200 rounded-lg overflow-hidden">
            <button
              className="w-full flex justify-between items-center p-4 bg-blue-100 text-teal-800 font-bold"
              onClick={() => toggleSection(title)}
            >
              {title}
              {openSection === title ? <ChevronUp /> : <ChevronDown />}
            </button>
            {openSection === title && (
              <div className="p-4 bg-white">
                <ul className="list-disc list-inside space-y-2">
                  {items.map((item, index) => (
                    <li key={index} className="text-sm">{item}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dashboard;

// Fix for '--isolatedModules' error
export {};
