import React from 'react';

const ProjectEvaluationTable = () => {
  const data = [
    { iteracion: 1, subsidio: 'DS19', cabida: 'Ver Plano', factoresLocalizacion: 'No Cumple', cantidadViviendas: 1822, costoTotalConstruccion: 182200, precioVentaPromedio: 214996, tir: 15, estado: 'Rechazado' },
    { iteracion: 2, subsidio: 'DS19', cabida: 'Ver Plano', factoresLocalizacion: 'No Cumple', cantidadViviendas: 1840, costoTotalConstruccion: 184000, precioVentaPromedio: 217120, tir: 8, estado: 'Rechazado' },
    { iteracion: 3, subsidio: 'DS19', cabida: 'Ver Plano', factoresLocalizacion: 'No Cumple', cantidadViviendas: 1846, costoTotalConstruccion: 184600, precioVentaPromedio: 217828, tir: 8, estado: 'Rechazado' },
    { iteracion: 4, subsidio: 'DS19', cabida: 'Ver Plano', factoresLocalizacion: 'Cumple', cantidadViviendas: 2043, costoTotalConstruccion: 204300, precioVentaPromedio: 241074, tir: 13, estado: 'Con Comentarios' },
    { iteracion: 8, subsidio: 'DS01', cabida: 'Ver Plano', factoresLocalizacion: 'Cumple', cantidadViviendas: 2010, costoTotalConstruccion: 201000, precioVentaPromedio: 237180, tir: 7, estado: 'Con Comentarios' },
    { iteracion: 9, subsidio: 'DS01', cabida: 'Ver Plano', factoresLocalizacion: 'Cumple', cantidadViviendas: 2007, costoTotalConstruccion: 200700, precioVentaPromedio: 236826, tir: 13, estado: 'Aprobado Para Permiso' },
  ];

  return (
    <div className="p-4 bg-white-100 font-sans">
      <div className="max-w-full mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="flex text-white text-sm font-semibold">
          <div className="flex-1 bg-yellow-300 p-2 text-black">Paso 1: Arquitectura</div>
          <div className="flex-1 bg-green-400 p-2">Paso 2: Estudio de Costos</div>
          <div className="w-1/6 bg-red-400 p-2">Paso 3: Comercial</div>
          <div className="w-1/6 bg-gray-400 p-2">Paso 4: V°B° Gerencia</div>
        </div>
        <table className="w-full text-sm">
          <thead>
            <tr className="bg-cyan-300">
              <th className="p-2">Iteración</th>
              <th className="p-2">Subsidio</th>
              <th className="p-2">Cabida</th>
              <th className="p-2">Factores Localización</th>
              <th className="p-2">Cantidad Viviendas</th>
              <th className="p-2">Costo Total Construcción UF</th>
              <th className="p-2">Precio Venta Promedio UF</th>
              <th className="p-2">TIR</th>
              <th className="p-2">Estado</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={index} className={index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}>
                <td className="p-2 text-center">{row.iteracion}</td>
                <td className="p-2 text-center">{row.subsidio}</td>
                <td className="p-2 text-center">{row.cabida}</td>
                <td className="p-2 text-center">{row.factoresLocalizacion}</td>
                <td className="p-2 text-center">{row.cantidadViviendas.toLocaleString()}</td>
                <td className="p-2 text-center">{row.costoTotalConstruccion.toLocaleString()}</td>
                <td className="p-2 text-center">{row.precioVentaPromedio.toLocaleString()}</td>
                <td className="p-2 text-center">{row.tir}%</td>
                <td className="p-2 text-center">{row.estado}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProjectEvaluationTable;
