import React from 'react';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, ResponsiveContainer } from 'recharts';

// Define el tipo para tus datos
interface DataItem {
  subject: string;
  A: number;
}

const data: DataItem[] = [
  { subject: 'ESTUDIO DE TERRENO', A: 100 },
  { subject: 'CONDICIONES DE PROMESA', A: 100 },
  { subject: 'GESTIÓN DOM', A: 83 },
  { subject: 'PROYECTOS INGENIERÍA', A: 57 },
  { subject: 'ESTUDIOS DE INGENIERÍA', A: 38 },
  { subject: 'LICITACIÓN Y CONTRATO', A: 36 },
  { subject: 'REQUERIMIENTO INFORMACIÓN', A: 0 },
  { subject: 'ADICIONALES', A: 0 },
  { subject: 'RECEPCIÓN MUNICIPAL', A: 0 },
];

// Define los tipos para CustomTick
interface CustomTickProps {
  x: number;
  y: number;
  payload: { value: string };
  textAnchor: string;
}

const CustomTick = ({ x, y, payload, textAnchor }: CustomTickProps) => {
  const valueIndex = data.findIndex(item => item.subject === payload.value);
  const value = data[valueIndex]?.A || 0;

  return (
    <g>
      <text
        x={x}
        y={y}
        textAnchor={textAnchor}
        fill="#333"
        fontWeight="bold"
        fontSize="12"
      >
        {payload.value}
      </text>
      <text
        x={x}
        y={y + 20}
        textAnchor={textAnchor}
        fill="#333"
        fontWeight="bold"
        fontSize="12"
      >
        {`${value}%`}
      </text>
    </g>
  );
};

const RadarChartComponent: React.FC = () => {
  return (
    <ResponsiveContainer width="100%" height={500}>
      <RadarChart cx="50%" cy="50%" outerRadius="80%" data={data}>
        <PolarGrid />
        <PolarAngleAxis dataKey="subject" tick={CustomTick as any} />
        <Radar 
          name="Progress" 
          dataKey="A" 
          stroke="#2196F3" 
          fill="#2196F3" 
          fillOpacity={0.6} 
        />
      </RadarChart>
    </ResponsiveContainer>
  );
};

export default RadarChartComponent;
