import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { Gantt, Task, ViewMode, EventOption, StylingOption, DisplayOption } from 'gantt-task-react-lite';
import "gantt-task-react-lite/dist/index.css";
import { Button } from '../../components/ui/button';
import { ZoomIn, ZoomOut, Maximize, Minimize, Layers, ChevronRight, ChevronDown, Shrink, Expand } from 'lucide-react';






interface TaskInput {
  id: number;
  name: string;
  responsible: string;
  progress: number;
  start: string;
  end: string;
  dependsOn: { groupId: number; taskId: number }[];
  color?: string; // Añadido para el color de la tarea

}

interface Group {
  agrupador: string;
  tasks: TaskInput[];
  subprocess: string; // Añadido para el nombre del subproceso
  color?: string; // Añadido para el color del grupo/subproceso
}

interface TasksState {
  groups: Group[];
  
}

interface GanttChartViewProps {
  tasks: TasksState;
  colors?: { [key: string]: string };  // Nuevo prop para colores personalizados
}

const CustomHeader: React.FC<{
  headerHeight: number;
  rowWidth: string;
  fontFamily: string;
  fontSize: string;
}> = ({ headerHeight, rowWidth, fontFamily, fontSize }) => {
  return (
    <div
      className="gantt-task-list-header"
      style={{
        height: headerHeight,
        fontFamily: fontFamily,
        fontSize: fontSize,
        display: 'flex',
        borderBottom: '2px solid #e0e0e0',
        fontWeight: 'bold',
        alignItems: 'flex-end', // Alinear el contenido hacia abajo
        paddingBottom: '5px',  // Espacio adicional debajo
      }}
    >
      <div style={{ width: rowWidth, paddingLeft: '5px' }}>Agrupador/Tarea</div>
      <div style={{ width: '120px' }}>Desde</div>
      <div style={{ width: '120px' }}>Hasta</div>
    </div>
  );
};


interface DateSetup {
  dates: Date[];
  // Añade otras propiedades si son necesarias
}

const CustomTaskListTable: React.FC<{
  rowHeight: number;
  rowWidth: string;
  fontFamily: string;
  fontSize: string;
  locale: string;
  tasks: Task[];
  selectedTaskId: string;
  setSelectedTask: (taskId: string) => void;
  onExpanderClick: (task: Task) => void;
}> = ({
  rowHeight,
  rowWidth,
  fontFamily,
  fontSize,
  locale,
  tasks,
  selectedTaskId,
  setSelectedTask,
  onExpanderClick
}) => {
  const [hoveredTaskId, setHoveredTaskId] = useState<string | null>(null);


  const formatDate = (date: Date): string => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // Función para generar colores para los agrupadores
  const getGroupColor = (index: number): string => {
    const colors = ['#FFD700', '#98FB98', '#87CEFA', '#FFA07A', '#DDA0DD'];
    return colors[index % colors.length];
  };

  const darkenColor = (color: string, amount: number = 0.2): string => {
    // Convertir el color hexadecimal a RGB
    let [r, g, b] = color.match(/\w\w/g)?.map((c) => parseInt(c, 16)) || [0, 0, 0];
    
    // Oscurecer los valores RGB
    r = Math.max(0, Math.min(255, r - Math.round(255 * amount)));
    g = Math.max(0, Math.min(255, g - Math.round(255 * amount)));
    b = Math.max(0, Math.min(255, b - Math.round(255 * amount)));
    
    // Convertir de vuelta a hexadecimal
    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  };


  const lightenColor = (color: string, amount: number = 0.2): string => {
    // Convertir el color hexadecimal a RGB
    let [r, g, b] = color.match(/\w\w/g)?.map((c) => parseInt(c, 16)) || [0, 0, 0];
    
    // Aumentar los valores RGB para aclarar el color
    r = Math.max(0, Math.min(255, r + Math.round(255 * amount)));
    g = Math.max(0, Math.min(255, g + Math.round(255 * amount)));
    b = Math.max(0, Math.min(255, b + Math.round(255 * amount)));
    
    // Convertir de vuelta a hexadecimal
    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  };
  
  

  const removeAlpha = (color: string): string => {
    // Si el color tiene 8 caracteres (incluyendo alpha), recortar los últimos 2.
    return color.length === 9 ? color.slice(0, 7) : color;
  };
  
  
  // Función para obtener el color de fondo de una tarea
  const getTaskBackgroundColor = (task: Task, isHovered: boolean, isSelected: boolean): string => {
    let color = task.type === 'project' 
      ? (task.styles?.backgroundColor || getGroupColor(parseInt(task.id.split('-')[1])))
      : (task.styles?.backgroundColor || '#FFFFFF');
    
      //color = removeAlpha(color); // Eliminar canal alfa si existe

      if (task.type !== 'project') {
      if (isSelected) {
     
        return darkenColor(lightenColor(color,0.2), 0.1);
      } else if (isHovered) {
        return darkenColor(lightenColor(color,0.3), 0.1);
      }
    }
    return color;
  };

  let currentGroupIndex = -1;
  let taskIndexInGroup = 0;

  return (
    <div
      className="gantt-task-list-wrapper"
      style={{
        fontFamily,
        fontSize,
      }}
    >
      {tasks.map((task, index) => {
        if (task.type === 'project') {
          currentGroupIndex++;
          taskIndexInGroup = 0;
        } else {
          taskIndexInGroup++;
        }

        const isHovered = hoveredTaskId === task.id;
        const isSelected = selectedTaskId === task.id;
        
        return (
          <div
            key={task.id}
            className="gantt-task-list-row"
            style={{
              height: rowHeight,
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #e0e0e0',
              backgroundColor: getTaskBackgroundColor(task, isHovered, isSelected),
              transition: 'background-color 0.3s ease',
              cursor: 'pointer',
            }}
            onClick={() => {
              setSelectedTask(task.id);
              if (task.type === 'project') {
                onExpanderClick(task);
              }
            }}
            onMouseEnter={() => setHoveredTaskId(task.id)}
            onMouseLeave={() => setHoveredTaskId(null)}
          >
            <div
              className="gantt-task-list-cell"
              style={{
                width: rowWidth,
                display: 'flex',
                alignItems: 'center',
                paddingLeft: task.type === 'project' ? '5px' : '25px',
                paddingRight: '20px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {task.type === 'project' && (
                <div
                  className="gantt-task-list-expander"
                  style={{ marginRight: '5px', cursor: 'pointer' }}
                >
                  {task.hideChildren ? <ChevronRight size={16} /> : <ChevronDown size={16} />}
                </div>
              )}
              <div style={{ fontWeight: task.type === 'project' ? 'bold' : 'normal' }}>
                {task.name}
              </div>
            </div>
            <div className="gantt-task-list-cell" style={{ width: '120px' }}>
              {formatDate(task.start)}
            </div>
            <div className="gantt-task-list-cell" style={{ width: '120px' }}>
              {formatDate(task.end)}
            </div>
          </div>
        );
      })}
    </div>
  );
};


const CustomTimelineHeader: React.FC<{
  headerHeight: number;
  rowWidth: string;
  fontFamily: string;
  fontSize: string;
  tickWidth: number;
  startDate: Date;
  endDate: Date;
  dateSetup: DateSetup;
  locale: string;
  headerColor: string;
}> = ({
  headerHeight,
  fontFamily,
  fontSize,
  tickWidth,
  startDate,
  endDate,
  dateSetup,
  locale,
}) => {

  const getWeekNumber = (date: Date): number => {
    const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    const dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    return Math.ceil((((d.getTime() - yearStart.getTime()) / 86400000) + 1)/7);
  };
  
  const getWeekNumberLabel = (date: Date) => {
    const weekNumber = getWeekNumber(date);
    return `${weekNumber}`;
  };

  return (
    <div
      className="gantt-timeline-header"
      style={{
        fontFamily,
        fontSize,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#f5f5f5',
        borderBottom: '1px solid #e0e0e0',
      }}
    >
      {/* Semanas */}
      <div style={{ display: 'flex', height: headerHeight / 2 }}>
        {dateSetup.dates.map((date: Date, i: number) => (
          <div
            key={date.getTime()}
            style={{
              width: tickWidth,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRight: '1px solid #e0e0e0',
              fontWeight: 'bold',
            }}
          >
            {i === 0 || date.getDay() === 1 ? getWeekNumberLabel(date) : ''}
          </div>
        ))}
      </div>
      {/* Días */}
      <div style={{ display: 'flex', height: headerHeight / 2 }}>
        {dateSetup.dates.map((date: Date) => (
          <div
            key={date.getTime()}
            style={{
              width: tickWidth,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRight: '1px solid #e0e0e0',
              color: date.getDay() === 0 || date.getDay() === 6 ? '#999' : '#000',
            }}
          >
            {date.getDate()}
          </div>
        ))}
      </div>
    </div>
  );
};


const getTodayStyle = () => {
  const todayStart = new Date();
  todayStart.setHours(0, 0, 0, 0);
  const todayEnd = new Date(todayStart);
  todayEnd.setDate(todayEnd.getDate() + 1);

  return {
    backgroundColor: 'rgba(255, 0, 0, 0.3)',
    width: '2px',
    height: '100%',
    position: 'absolute' as 'absolute',
    zIndex: 1,
    left: 'calc(50% - 1px)',
  };
};


const GanttChartView: React.FC<GanttChartViewProps> = ({ tasks, colors = {} }) => {
  const [zoom, setZoom] = useState(100);
    const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.Week);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
    const [isGrouped, setIsGrouped] = useState(true);
    const [expandedGroups, setExpandedGroups] = useState<Set<string>>(new Set());
    const ganttRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const [selectedTaskId, setSelectedTaskId] = useState<string>('');

    const chartData: Task[] = useMemo(() => {
      if (isGrouped) {
        return tasks.groups.flatMap((group, groupIndex): Task[] => {
          const groupTasks = group.tasks.map((task): Task => ({
            id: `${groupIndex}-${task.id}`,
            name: task.name,
            start: new Date(task.start),
            end: new Date(task.end),
            progress: task.progress,
            type: 'task',
            project: `group-${groupIndex}`,
            dependencies: task.dependsOn.map(dep => `${groupIndex}-${dep.taskId}`),
            isDisabled: true,
            styles: { 
              progressColor: task.color || '#ff9e0d', 
              progressSelectedColor: task.color || '#ff9e0d',
              backgroundColor: task.color ? `${task.color}66` : undefined,
              backgroundSelectedColor: task.color ? `${task.color}aa` : undefined,
            }
          }));

         // alert(JSON.stringify(groupTasks))
          const groupStart = new Date(Math.min(...groupTasks.map(task => task.start.getTime())));
          const groupEnd = new Date(Math.max(...groupTasks.map(task => task.end.getTime())));
          const groupProgress = groupTasks.reduce((sum, task) => sum + task.progress, 0) / groupTasks.length;
          


          const darkenColor = (color: string, amount: number = 0.2): string => {
            return color
              .replace(/^#/, '')
              .replace(/.{2}/g, (c) =>
                Math.max(0, Math.min(255, parseInt(c, 16) - Math.round(255 * amount)))
                  .toString(16)
                  .padStart(2, '0')
              );
          };

          const isExpanded = expandedGroups.has(`group-${groupIndex}`);

          const baseColor = group.color || '#0044ff';
          const darkColor = `#${darkenColor(baseColor, 0.2)}`;


          
          const groupTask: Task = {
            id: `group-${groupIndex}`,
            name: group.agrupador,
            start: groupStart,
            end: groupEnd,
            progress: groupProgress,
            type: 'project',
            hideChildren: !isExpanded,
            isDisabled: false,
            project: undefined,
            styles: { 
              progressColor: baseColor,
              progressSelectedColor: darkColor,
              backgroundColor: baseColor,
              backgroundSelectedColor: darkColor,
             },
            dependencies: [],
          };
  
          return isExpanded ? [groupTask, ...groupTasks] : [groupTask];
        });
      } else {
        return tasks.groups.flatMap((group, groupIndex) =>
          group.tasks.map((task): Task => ({
            id: `${groupIndex}-${task.id}`,
            name: task.name,
            start: new Date(task.start),
            end: new Date(task.end),
            progress: task.progress,
            type: 'task',
            project: group.agrupador,
            dependencies: task.dependsOn.map(dep => `${dep.groupId}-${dep.taskId}`),
            isDisabled: false,
            styles: { progressColor: '#ff9e0d', progressSelectedColor: '#ff9e0d' }
          }))
        );
      }
    }, [tasks, isGrouped, expandedGroups]);

    const handleZoomChange = useCallback((delta: number) => {
      setZoom(prevZoom => Math.max(50, Math.min(prevZoom + delta, 200)));
    }, []);

    const handleViewModeChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
      setViewMode(e.target.value as ViewMode);
    }, []);


    const toggleAllGroups = () => {
      if (areAllGroupsExpanded) {
        setExpandedGroups(new Set());
      } else {
        const allGroupIds = tasks.groups.map((_, index) => `group-${index}`);
        setExpandedGroups(new Set(allGroupIds));
      }
      setAreAllGroupsExpanded(!areAllGroupsExpanded);
    };

    const toggleFullScreen = () => {
      if (!document.fullscreenElement) {
        containerRef.current?.requestFullscreen();
      } else {
        document.exitFullscreen();
      }
    };

    
    const handleTaskSelect = (task: Task) => {
      setSelectedTaskId(task.id);
    };
  
    const handleExpanderClick = (task: Task) => {
      if (task.type === 'project') {
        toggleGroup(task.id);
      }
    };


    const toggleGrouping = () => {
      setIsGrouped(prev => !prev);
      setExpandedGroups(new Set());
    };

    const toggleGroup = (groupId: string) => {
      setExpandedGroups(prev => {
        const newSet = new Set(prev);
        if (newSet.has(groupId)) {
          newSet.delete(groupId);
        } else {
          newSet.add(groupId);
        }
        return newSet;
      });
    };

    // We no longer need handleExpanderClick as the expansion is handled by the chevron click

    useEffect(() => {
      const handleFullScreenChange = () => {
        setIsFullScreen(!!document.fullscreenElement);
      };

      document.addEventListener('fullscreenchange', handleFullScreenChange);

      return () => {
        document.removeEventListener('fullscreenchange', handleFullScreenChange);
      };
    }, []);

    
    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
      setIsDragging(true);
      setDragStart({ x: e.clientX, y: e.clientY });
    };

    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
      if (isDragging && ganttRef.current) {
        const dx = e.clientX - dragStart.x;
        const dy = e.clientY - dragStart.y;
        ganttRef.current.scrollLeft -= dx;
        ganttRef.current.scrollTop -= dy;
        setDragStart({ x: e.clientX, y: e.clientY });
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    const handleWheel = (e: WheelEvent) => {
      if (e.ctrlKey) {
        e.preventDefault();
        handleZoomChange(e.deltaY > 0 ? -10 : 10);
      }
    };

    useEffect(() => {
      const ganttElement = ganttRef.current;
      if (ganttElement) {
        ganttElement.addEventListener('wheel', handleWheel, { passive: false });
      }
      return () => {
        if (ganttElement) {
          ganttElement.removeEventListener('wheel', handleWheel);
        }
      };
    }, [handleZoomChange]);

    const formatDate = (date: Date): string => {
      return date.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' });
    };

    const getWeekNumber = (date: Date): number => {
      const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
      const dayNum = d.getUTCDay() || 7;
      d.setUTCDate(d.getUTCDate() + 4 - dayNum);
      const yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
      return Math.ceil((((d.getTime() - yearStart.getTime()) / 86400000) + 1)/7);
    };


    const columnWidth = useMemo(() => {
      return viewMode === ViewMode.Month ? 300 * (zoom / 100) : 65 * (zoom / 100);
  }, [zoom, viewMode]);

    const formatTimeTick = (date: Date): string => {
      const formatted = formatDate(date);
      if (viewMode === ViewMode.Week) {
        const weekNumber = getWeekNumber(date);
        return `S${String(weekNumber).padStart(2, '0')} - ${formatted}`;
      }
      return formatted;
    };

    useEffect(() => {
      const style = document.createElement('style');
      style.textContent = `
        .gantt-today-highlight {
          position: absolute;
          top: 0;
          bottom: 0;
          width: 2px !important;
          background-color: rgba(255, 0, 0, 0.5) !important;
          z-index: 1;
        }
      `;
      document.head.appendChild(style);
    
      return () => {
        document.head.removeChild(style);
      };
    }, []);

    const [areAllGroupsExpanded, setAreAllGroupsExpanded] = useState(false);
    const totalTasks = useMemo(() => {
      return tasks.groups.reduce((total, group) => total + group.tasks.length + 1, 0);
  }, [tasks]);

    return (
      <div ref={containerRef} className={`gantt-container flex flex-col ${isFullScreen ? 'fixed inset-0 z-50 bg-white' : ''}`}>
        <div className="flex justify-between items-center mb-4 p-2">
          <div>
            <Button onClick={() => handleZoomChange(-10)} variant="outline" size="sm" className="mr-2">
              <ZoomOut className="w-4 h-4 mr-2" /> Alejar
            </Button>
            <Button onClick={() => handleZoomChange(10)} variant="outline" size="sm" className="mr-2">
              <ZoomIn className="w-4 h-4 mr-2" /> Acercar
            </Button>
            <Button onClick={toggleAllGroups} variant="outline" size="sm" className="ml-6 mr-2">
            {areAllGroupsExpanded ? <Shrink className="w-4 h-4 mr-2" /> : <Expand className="w-4 h-4 mr-2" />}
            {areAllGroupsExpanded ? 'Colapsar Todos' : 'Expandir Todos'}
          </Button>
            <Button onClick={toggleFullScreen} variant="outline" size="sm" className="mr-2">
              {isFullScreen ? <Minimize className="w-4 h-4 mr-2" /> : <Maximize className="w-4 h-4 mr-2" />}
              {isFullScreen ? 'Salir de Pantalla Completa' : 'Pantalla Completa'}
            </Button>

           {/* <Button onClick={toggleGrouping} variant="outline" size="sm">
              <Layers className="w-4 h-4 mr-2" /> {isGrouped ? 'Desagrupar' : 'Agrupar'}
            </Button>*/}
          </div>
          <select
            value={viewMode}
            onChange={handleViewModeChange}
            className="border p-2 rounded"
          >
            <option value={ViewMode.Day}>Día</option>
            <option value={ViewMode.Week}>Semana</option>
            <option value={ViewMode.Month}>Mes</option>
          </select>
        </div>
        <div 
          ref={ganttRef}
          className="flex-grow overflow-auto"
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
                <div style={{ width: '100%', height: '460px' }}>
                <Gantt
            tasks={chartData}
            viewMode={viewMode}
            locale="es"
            listCellWidth="250px"
            columnWidth={columnWidth}
            ganttHeight={Math.max(isFullScreen ? window.innerHeight - 100 : 450, totalTasks * 40 + 50)}
            headerHeight={50}
            rowHeight={40}
            barCornerRadius={5}
            projectProgressColor="#0044ff"
            barProgressColor="#ff9e0d"
            barProgressSelectedColor="#ff9e0d"
            barBackgroundColor="#b8c2cc"
            barBackgroundSelectedColor="#aeb8c2"
            handleWidth={0}
            timeStep={86400000}
            arrowColor="#888"
            fontFamily="Arial, sans-serif"
            fontSize="14px"
            weekPrefix=""
            todayColor="rgba(255, 0, 0, 0.1)"
            todayLineColor="rgba(255, 0, 0, 0.9)"
            TaskListHeader={CustomHeader}
            TaskListTable={CustomTaskListTable}
            TooltipContent={({ task }) => (
              <div className="bg-white p-2 rounded shadow">
                <h3 className="font-bold">{task.name}</h3>
                <p>Inicio: {formatDate(task.start)}</p>
                <p>Fin: {formatDate(task.end)}</p>
                <p>Progreso: {task.progress.toFixed(2)}%</p>
                {task.project && task.project !== task.id}
              </div>
            )}
            onDateChange={() => {}}
            onProgressChange={() => {}}
            onDoubleClick={() => {}}
            onSelect={handleTaskSelect}
            onExpanderClick={handleExpanderClick}
          />
            
          </div>
        </div>
      </div>
    );
};

export default GanttChartView;

