import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, TooltipProps } from 'recharts';

// Definición de los datos
const data = [
  { date: '22/04/2024', vencidas: 30, mayor90: 8, mayor80: 2, iniciadas: 10, noIniciadas: 50 },
  { date: '23/04/2024', vencidas: 31, mayor90: 8, mayor80: 2, iniciadas: 11, noIniciadas: 48 },
  { date: '24/04/2024', vencidas: 32, mayor90: 7, mayor80: 3, iniciadas: 12, noIniciadas: 46 },
  { date: '25/04/2024', vencidas: 33, mayor90: 7, mayor80: 3, iniciadas: 13, noIniciadas: 44 },
  { date: '26/04/2024', vencidas: 34, mayor90: 6, mayor80: 4, iniciadas: 14, noIniciadas: 42 },
  { date: '29/04/2024', vencidas: 35, mayor90: 6, mayor80: 4, iniciadas: 15, noIniciadas: 40 },
  { date: '30/04/2024', vencidas: 36, mayor90: 6, mayor80: 4, iniciadas: 16, noIniciadas: 38 },
  { date: '01/05/2024', vencidas: 20, mayor90: 6, mayor80: 4, iniciadas: 17, noIniciadas: 53 },
  { date: '02/05/2024', vencidas: 15, mayor90: 6, mayor80: 4, iniciadas: 18, noIniciadas: 57 },
  { date: '03/05/2024', vencidas: 10, mayor90: 6, mayor80: 4, iniciadas: 19, noIniciadas: 61 },
  { date: '06/05/2024', vencidas: 7, mayor90: 6, mayor80: 4, iniciadas: 20, noIniciadas: 63 },
  { date: '07/05/2024', vencidas: 6, mayor90: 6, mayor80: 4, iniciadas: 21, noIniciadas: 63 },
  { date: '08/05/2024', vencidas: 5, mayor90: 6, mayor80: 4, iniciadas: 22, noIniciadas: 63 },
  { date: '09/05/2024', vencidas: 5, mayor90: 6, mayor80: 4, iniciadas: 23, noIniciadas: 62 },
];

// Definición de los tipos para el tooltip personalizado
interface CustomTooltipProps extends TooltipProps<number, string> {
  active?: boolean;
  payload?: Array<{ name: string; value: number; color: string }>;
  label?: string;
}

// Componente CustomTooltip con tipos definidos
const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white p-2 border border-gray-300 rounded shadow">
        <p className="font-bold">{label}</p>
        {payload.reverse().map((entry, index) => (
          <p key={index} style={{ color: entry.color }}>
            {entry.name}: {entry.value}%
          </p>
        ))}
      </div>
    );
  }
  return null;
};

// Definición de los tipos para el componente Legend
interface LegendProps {
  data: { [key: string]: number };
}

// Componente Legend con tipos definidos
const Legend: React.FC<LegendProps> = ({ data }) => (
  <div className="flex flex-col space-y-1 absolute top-0 right-0 bg-white p-2 rounded shadow">
    {Object.entries(data).map(([key, value], index) => (
      <div key={index} className="flex items-center">
        <div className={`w-4 h-4 mr-2 ${getLegendColor(key)}`}></div>
        <span>{key}</span>
        <span className="ml-auto">{value}%</span>
      </div>
    ))}
  </div>
);

// Función para obtener el color del legend
const getLegendColor = (key: string): string => {
  switch (key) {
    case 'No Iniciadas': return 'bg-gray-300';
    case 'Iniciadas': return 'bg-green-400';
    case 'Mayor a 80%': return 'bg-yellow-300';
    case 'Mayor a 90%': return 'bg-orange-400';
    case 'Vencidas': return 'bg-red-500';
    default: return 'bg-gray-500';
  }
};

// Componente principal TaskProgressChart
const TaskProgressChart: React.FC = () => {
  const legendData = {
    'No Iniciadas': 62,
    'Iniciadas': 23,
    'Mayor a 80%': 4,
    'Mayor a 90%': 6,
    'Vencidas': 5
  };

  return (
    <div className="relative w-full h-96">
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Area type="monotone" dataKey="vencidas" stackId="1" stroke="#EF4444" fill="#EF4444" />
          <Area type="monotone" dataKey="mayor90" stackId="1" stroke="#F59E0B" fill="#F59E0B" />
          <Area type="monotone" dataKey="mayor80" stackId="1" stroke="#FBBF24" fill="#FBBF24" />
          <Area type="monotone" dataKey="iniciadas" stackId="1" stroke="#34D399" fill="#34D399" />
          <Area type="monotone" dataKey="noIniciadas" stackId="1" stroke="#9CA3AF" fill="#9CA3AF" />
        </AreaChart>
      </ResponsiveContainer>
      <Legend data={legendData} />
    </div>
  );
};

export default TaskProgressChart;
