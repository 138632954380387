import React, { useState } from 'react';
import { ChevronDown, ChevronUp, Filter } from 'lucide-react';

// Define el tipo para los datos
interface Datos {
  proyecto: string;
  nombreArchivo: string;
  circular1: string;
  circular2: string;
  circular3: string;
  ultimaVersion: string;
  modificaciones: string;
}

const datos: Datos[] = [
  // Aquí van los datos como en tu ejemplo
];

const TablaControlVersiones: React.FC = () => {
  const [sortConfig, setSortConfig] = useState<{ key: keyof Datos | null; direction: 'ascending' | 'descending' }>({ key: null, direction: 'ascending' });
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const [filtroProyecto, setFiltroProyecto] = useState("");
  const [filtroVersion, setFiltroVersion] = useState("");

  const sortData = (key: keyof Datos) => {
    let direction: 'ascending' | 'descending' = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedData = React.useMemo(() => {
    let sortableItems = [...datos];
    if (sortConfig.key) {
      sortableItems.sort((a, b) => {
        // Realiza una verificación adicional para asegurarte de que sortConfig.key es una clave válida
        if (sortConfig.key) {
          const aKey = a[sortConfig.key as keyof Datos];
          const bKey = b[sortConfig.key as keyof Datos];
          
          if (aKey < bKey) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }
          if (aKey > bKey) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }
        }
        return 0;
      });
    }
    return sortableItems;
  }, [sortConfig]);

  const filteredData = sortedData.filter(item =>
    item.proyecto.toLowerCase().includes(filtroProyecto.toLowerCase()) &&
    item.ultimaVersion.toLowerCase().includes(filtroVersion.toLowerCase())
  );

  return (
    <div className="font-sans p-4 max-w-full mx-auto overflow-x-auto">
      <h2 className="text-2xl font-bold mb-4 text-center bg-blue-200 py-2">Control de Versiones de Proyecto</h2>
      <div className="mb-4 flex space-x-4">
        <div>
          <label className="mr-2">Filtrar por Proyecto:</label>
          <input
            type="text"
            value={filtroProyecto}
            onChange={(e) => setFiltroProyecto(e.target.value)}
            className="border p-1"
          />
        </div>
        <div>
          <label className="mr-2">Filtrar por Última Versión:</label>
          <input
            type="text"
            value={filtroVersion}
            onChange={(e) => setFiltroVersion(e.target.value)}
            className="border p-1"
          />
        </div>
      </div>
      <table className="w-full border-collapse">
        <thead>
          <tr className="bg-gray-200">
            <th className="p-2 cursor-pointer" onClick={() => sortData('proyecto')}>
              Proyecto <Filter size={16} className="inline" />
            </th>
            <th className="p-2 cursor-pointer" onClick={() => sortData('nombreArchivo')}>
              Nombre de Archivos <Filter size={16} className="inline" />
            </th>
            <th className="p-2">Circular 1</th>
            <th className="p-2">Circular 2</th>
            <th className="p-2">Circular 3</th>
            <th className="p-2 cursor-pointer" onClick={() => sortData('ultimaVersion')}>
              Última Versión <Filter size={16} className="inline" />
            </th>
            <th className="p-2">Modificaciones</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((item, index) => (
            <React.Fragment key={index}>
              <tr className={`${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'} hover:bg-yellow-100`}>
                <td className="border p-2">{item.proyecto}</td>
                <td className="border p-2">{item.nombreArchivo}</td>
                <td className="border p-2">{item.circular1}</td>
                <td className="border p-2">{item.circular2}</td>
                <td className="border p-2">{item.circular3}</td>
                <td className="border p-2 bg-green-100">{item.ultimaVersion}</td>
                <td className="border p-2 cursor-pointer" onClick={() => setExpandedRow(expandedRow === index ? null : index)}>
                  {expandedRow === index ? <ChevronUp size={16} className="inline mr-2" /> : <ChevronDown size={16} className="inline mr-2" />}
                  Ver detalles
                </td>
              </tr>
              {expandedRow === index && (
                <tr>
                  <td colSpan={7} className="border p-2 bg-gray-50">
                    <strong>Modificaciones:</strong> {item.modificaciones}
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TablaControlVersiones;
