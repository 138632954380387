import React, { useState, useEffect, useCallback, useRef } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import axios from 'axios';

// Define la interfaz para los datos
interface DataItem {
  concepto: string;
  nomenclatura: string;
  cantidad: number;
}

const ResumenControlDocumental: React.FC = () => {
  const [datos, setDatos] = useState<DataItem[]>([]);
  const [niveles, setNiveles] = useState<any[]>([]);
  const [sortConfig, setSortConfig] = useState<{ key: keyof DataItem | null, direction: 'ascending' | 'descending' }>({ key: null, direction: 'ascending' });
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);

  // Ref for the component
  const componentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchNiveles = async () => {
      try {
        const response = await axios.get<any[]>('http://localhost:3000/php/pages/controldocument/glosario/get_levels.php');
        setNiveles(response.data);
      } catch (error) {
        console.error('Error fetching niveles:', error);
      }
    };

    const fetchData = async () => {
      try {
        const response = await axios.get<DataItem[]>('http://localhost:3000/php/pages/controldocument/glosario/get_levels_data.php?type=type2');
        setDatos(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchNiveles();
    fetchData();
  }, []);

  const sortData = useCallback((key: keyof DataItem) => {
    let direction: 'ascending' | 'descending' = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    const sortedData = [...datos].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
      return 0;
    });
    setDatos(sortedData);
    setSortConfig({ key, direction });
  }, [datos, sortConfig]);

  const handleRowClick = useCallback((index: number) => {
    setSelectedRowIndex(index);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (componentRef.current && !componentRef.current.contains(event.target as Node)) {
        setSelectedRowIndex(null);  // Reset selection when clicking outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={componentRef} className="font-sans p-4 max-w-4xl mx-auto">
      <h2 className="text-2xl font-bold mb-4 text-center bg-cyan-300 py-2">Resumen Control Documental</h2>
      <div className="flex flex-col md:flex-row gap-4">
        <div className="md:w-1/2">
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-orange-200">
                <th className="p-2 cursor-pointer" onClick={() => sortData('concepto')}>Nombre</th>
                <th className="p-2 cursor-pointer" onClick={() => sortData('nomenclatura')}>Código</th>
                <th className="p-2 cursor-pointer" onClick={() => sortData('cantidad')}>Cantidad</th>
              </tr>
            </thead>
            <tbody>
              {datos.map((item, index) => (
                <tr 
                  key={index} 
                  className={`${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'} ${selectedRowIndex === index ? 'bg-yellow-200' : 'hover:bg-yellow-100'} cursor-pointer`}
                  onClick={() => handleRowClick(index)}
                >
                  <td className="border p-2">{item.concepto}</td>
                  <td className="border p-2">{item.nomenclatura}</td>
                  <td className="border p-2">{item.cantidad}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="md:w-1/2 h-64">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={datos}>
              <XAxis dataKey="nomenclatura" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="cantidad">
                {datos.map((entry, index) => (
                  <Cell 
                    key={`cell-${index}`}
                    fill={selectedRowIndex === index ? '#ffa726' : '#8884d8'}
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default ResumenControlDocumental;
