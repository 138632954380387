import React from 'react';
import imgCabecera from '../img/cabecera.jpeg'; // Ajusta la ruta según la ubicación de tu imagen
import imgAuthor from '../img/author.jpg'; 
import imgExp from '../img/imgExp.svg'; 
import imgFirma from '../img/firma.png'; 
// index.tsx o index.css
import '@fortawesome/fontawesome-free/css/all.min.css';


const PerfilGuillermoCanales: React.FC = () => {
  return (
    <div className="p-6 max-w-4xl mx-auto bg-white rounded-lg">
      {/* Banner de cabecera */}
      <div className="relative h-auto bg-blue-800 rounded-t-lg z--1">
        <img
          src={imgCabecera} // Reemplaza esto con la URL real del banner
          alt="Banner de cabecera"
          className="w-full h-auto object-cover rounded-t-lg"
        />
      </div>
      
      {/* Información de perfil */}
      <div className="flex items-center -mt-16 mb-6 px-6 z-0">
        <img
          src={imgAuthor} // Reemplaza esto con la URL real de la imagen de perfil
          alt="Guillermo Canales Pozo"
          className="w-24 z-0 h-24 rounded-full border-4 border-white shadow-lg"
        />
        <div className="ml-4 mt-20">
          <h1 className="text-3xl font-bold">Guillermo Canales Pozo</h1>
          <p className="text-xl text-gray-700">
            Ingeniero Civil UTFSM; Estudiante Diplomado en Permisología en Proyectos Inmobiliarios UANDES
          </p>
          <p className="text-gray-600">SIMAGI.cl</p>
          <p className="text-gray-600">Universidad Técnica Federico Santa María</p>
          <p className="text-gray-600">Chile</p>
        </div>
      </div>

      {/* Información de contacto */}
      <div className="mb-6 px-6">
        <h2 className="text-xl font-semibold mb-2">Acerca de</h2>
        <p className="text-gray-700">
          Ingeniero Civil con experiencia en el rubro inmobiliario. Coordinación de Proyectos de Urbanización, Estudios de tránsito, Declaraciones Impacto Ambiental, etc. Con experiencia en Proyectos DS19.
        </p>
      </div>

      {/* Experiencia */}
      <div className="mb-6 px-6">
        <h2 className="text-xl font-semibold mb-2">Experiencia</h2>
        
        {/* Experiencia 1 */}
        <div className="mb-4 flex items-start">
          <img
            src={imgExp} // Reemplaza esto con la URL de una imagen de cuadrado (puede ser un ícono genérico)
            alt="Imagen de trabajo"
            className="w-12 h-12 object-cover rounded-lg mr-4"
          />
          <div>
            <h3 className="text-lg font-semibold">Director de Desarrollo e Innovación en SIMAGI</h3>
            <p className="text-gray-600">
              SIMAGI.cl · Profesional independiente<br />
              abr. 2020 - actualidad · 4 años 4 meses<br />
              Región Metropolitana de Santiago, Chile · En remoto
            </p>
            <p className="text-gray-700">
              Desarrollo de Metodología para superar la Problemática de la Permisología a nivel nacional mediante la integración de tecnologías avanzadas e integración de inteligencia artificial.
              <br />
              Desarrollo del Sistema Maestro de Gestión Integrado.
            </p>
          </div>
        </div>

        {/* Experiencia 2 */}
        <div className="mb-4 flex items-start">
          <img
            src={imgExp} // Reemplaza esto con la URL de una imagen de cuadrado (puede ser un ícono genérico)
            alt="Imagen de trabajo"
            className="w-12 h-12 object-cover rounded-lg mr-4"
          />
          <div>
            <h3 className="text-lg font-semibold">Secretario Técnico e Ingeniero de Proyectos de Urbanización</h3>
            <p className="text-gray-600">
              Conavicoop · Jornada completa<br />
              ene. 2018 - actualidad · 6 años 7 meses<br />
              Gran Santiago, Región Metropolitana de Santiago, Chile
            </p>
            <p className="text-gray-700">
              Experiencia relacionada a proyectos inmobiliarios de carácter social, con foco en los Programas de Integración Social y Territorial DS19 y DS01.
              <br />
              Como secretario técnico: Desarrollo de un Sistema de Gestión (ERP) para la gestión de la Planificación, Control de documentos y gestión inmobiliaria.
              <br />
              Como Ingeniero de Proyectos: Coordinación de proyectos de Agua Potable, Aguas Servidas, Pavimentación y Aguas Lluvias, DGA, Canalistas, etc. Estudios de Tránsito, Declaraciones de Impacto Ambiental, manejo de documentación de Licitación y Contrato relacionada a Urbanización.
            </p>
          </div>
        </div>

        {/* Experiencia 3 */}
        <div className="mb-4 flex items-start">
          <img
            src={imgExp} // Reemplaza esto con la URL de una imagen de cuadrado (puede ser un ícono genérico)
            alt="Imagen de trabajo"
            className="w-12 h-12 object-cover rounded-lg mr-4"
          />
          <div>
            <h3 className="text-lg font-semibold">Ingeniero de Oficina Técnica</h3>
            <p className="text-gray-600">
              Constructora e Inmobiliaria Class · Jornada completa<br />
              jul. 2017 - ene. 2018 · 7 meses<br />
              Chile
            </p>
            <p className="text-gray-700">
              Apoyo del Administrador de Obra: Control Presupuestario; Revisión y Control de Planos y EETT de Arquitectura y OOCC; Control del Programa de Trabajo semanal (Curva S); Control de Hormigones; Cubicaciones y Cotizaciones con Proveedores; Órdenes de Compra; Requerimientos de Información hacia ITO; Confección de Adicionales; APUs.
            </p>
          </div>
        </div>

        {/* Experiencia 4 */}
        <div className="mb-4 flex items-start">
          <img
            src={imgExp} // Reemplaza esto con la URL de una imagen de cuadrado (puede ser un ícono genérico)
            alt="Imagen de trabajo"
            className="w-12 h-12 object-cover rounded-lg mr-4"
          />
          <div>
            <h3 className="text-lg font-semibold">Ingeniero de Proyecto</h3>
            <p className="text-gray-600">
              Proyersa Energía<br />
              jul. 2013 - sept. 2016 · 3 años 3 meses<br />
              Las Condes, Santiago
            </p>
            <p className="text-gray-700">
              Ingeniero de Proyecto / Coordinador Técnico / Control de documentación en los siguientes ámbitos:
              <br />
              - Caracterizaciones Técnicas Ambientales de Centrales Térmicas (Capítulo 1 EIA)
              <br />
              - Estudios de Selección de sitio e Infraestructura portuaria para combustibles tales como carbón, gas natural y petróleo.
              <br />
              - Coordinación con empresas colaboradoras externas: GAC, ChileRegistros, BAIRD, S Y S, Rodríguez y Goldsack, Geoexploraciones, INGEROC, Geomar, GHMD, Calbuco.
              <br />
              - Participación en estudios relacionados a Costos de Inversión de diversas Centrales Térmicas, incluidas tecnologías renovables no convencionales, tales como: fotovoltaica, concentración solar de potencia, eólica, biomasa y geotermia.
              <br />
              - Tramitación de solicitud de Concesión de Exploración Geotérmica
              <br />
              - Tramitación de solicitud de terrenos de Concesión de Bienes Nacionales para Proyectos ERNC.
              <br />
              - Participación en la elaboración de las Bases de Licitación para un Proyecto en Base a Biomasa y otro para termoeléctrica en base a carbón.
            </p>
          </div>
        </div>

        {/* Experiencia 5 */}
        <div className="mb-4 flex items-start">
          <img
            src={imgExp} // Reemplaza esto con la URL de una imagen de cuadrado (puede ser un ícono genérico)
            alt="Imagen de trabajo"
            className="w-12 h-12 object-cover rounded-lg mr-4"
          />
          <div>
            <h3 className="text-lg font-semibold">Práctica de Modelador de Redes de Aguas Servidas (SewerCAD)</h3>
            <p className="text-gray-600">
              Sanitaria Esval<br />
              ene. 2012 - jun. 2012 · 6 meses<br />
              Valparaíso, Chile
            </p>
            <p className="text-gray-700">
              Práctica de Modelador de Redes de Aguas Servidas con el software SewerCAD.
            </p>
          </div>
        </div>
      </div>

      {/* Firma */}
      <div className="flex justify-center mt-6">
        <img
          src={imgFirma} // Reemplaza esto con la URL real de la firma
          alt="Firma"
          className="w-full"
        />
      </div>

   {/* Botón de LinkedIn */}
   {/*<div className="flex justify-center mt-6">
        <a
          href="https://www.linkedin.com/in/guillermo-canales-pozo/"
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex items-center px-4 py-2 bg-blue-600 text-white font-bold rounded-full hover:bg-blue-700"
        >
          <i className="fab fa-linkedin w-5 h-5 mr-2"></i>
          Ver perfil en LinkedIn
        </a>
      </div>*/}
    </div>
  );
};

export default PerfilGuillermoCanales;
