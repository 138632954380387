import React from 'react';

interface SeparatorProps extends React.HTMLAttributes<HTMLHRElement> {
  orientation?: 'horizontal' | 'vertical';
}

export const Separator: React.FC<SeparatorProps> = ({ 
  className = '', 
  orientation = 'horizontal', 
  ...props 
}) => {
  const orientationClass = orientation === 'horizontal' ? 'h-[1px] w-full' : 'h-full w-[1px]';
  return <hr className={`shrink-0 bg-border ${orientationClass} ${className}`} {...props} />;
};