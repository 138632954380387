import React, { useState, useEffect } from 'react';

// Definición de tipos para el estado 'data'
interface Viviendas {
  tipo1: number;
  tipo2: number;
}

interface Costos {
  tipo1: number;
  tipo2: number;
}

interface DataState {
  nViviendas: Viviendas;
  costoUnitarioVIV: Viviendas;
  superficieVivienda: Viviendas;
  terrenoViviendas: Viviendas;
  terrenoUFM2: Viviendas;
  costoFinanciero: number;
  comercial: number;
  marketing: number;
  gastosGenerales: number;
  utilidad: number;
  imprevistos: number;
}

interface ResultsState {
  terreno?: Viviendas;
  costoConstruccion?: Viviendas;
  vivienda?: Viviendas;
  urbanizacion?: Viviendas;
  habilitacion?: Viviendas;
  costoFinanciero?: Viviendas;
  comercial?: Viviendas;
  marketing?: Viviendas;
  gastosGenerales?: Viviendas;
  utilidad?: Viviendas;
  imprevistos?: Viviendas;
  costoProduccionVivienda?: Viviendas;
  precioVenta?: Viviendas;
  deficit?: Viviendas;
  margen?: number;
}

const RealEstateScenario1 = () => {
  const [data, setData] = useState<DataState>({
    nViviendas: { tipo1: 50, tipo2: 50 },
    costoUnitarioVIV: { tipo1: 15, tipo2: 17.5 },
    superficieVivienda: { tipo1: 55, tipo2: 55 },
    terrenoViviendas: { tipo1: 90, tipo2: 110 },
    terrenoUFM2: { tipo1: 1.5, tipo2: 1.5 },
    costoFinanciero: 0.05,
    comercial: 0.005,
    marketing: 0.005,
    gastosGenerales: 0.14,
    utilidad: 0.04,
    imprevistos: 0.02,
  });

  const [results, setResults] = useState<ResultsState>({});

  useEffect(() => {
    calculateResults();
  }, [data]);

  const calculateResults = () => {
    const terreno: Viviendas = {
      tipo1: data.terrenoViviendas.tipo1 * data.terrenoUFM2.tipo1,
      tipo2: data.terrenoViviendas.tipo2 * data.terrenoUFM2.tipo2
    };

    const costoConstruccion: Viviendas = {
      tipo1: 1250,
      tipo2: 1475
    };

    const vivienda: Viviendas = {
      tipo1: costoConstruccion.tipo1 * 0.66,
      tipo2: costoConstruccion.tipo2 * 0.65
    };

    const urbanizacion: Viviendas = {
      tipo1: costoConstruccion.tipo1 * 0.24,
      tipo2: costoConstruccion.tipo2 * 0.24
    };

    const habilitacion: Viviendas = {
      tipo1: costoConstruccion.tipo1 * 0.16,
      tipo2: costoConstruccion.tipo2 * 0.17
    };

    const costoFinanciero: Viviendas = {
      tipo1: costoConstruccion.tipo1 * data.costoFinanciero,
      tipo2: costoConstruccion.tipo2 * data.costoFinanciero
    };

    const comercial: Viviendas = {
      tipo1: costoConstruccion.tipo1 * data.comercial,
      tipo2: costoConstruccion.tipo2 * data.comercial
    };

    const marketing: Viviendas = {
      tipo1: costoConstruccion.tipo1 * data.marketing,
      tipo2: costoConstruccion.tipo2 * data.marketing
    };

    const gastosGenerales: Viviendas = {
      tipo1: costoConstruccion.tipo1 * data.gastosGenerales,
      tipo2: costoConstruccion.tipo2 * data.gastosGenerales
    };

    const utilidad: Viviendas = {
      tipo1: costoConstruccion.tipo1 * data.utilidad,
      tipo2: costoConstruccion.tipo2 * data.utilidad
    };

    const imprevistos: Viviendas = {
      tipo1: costoConstruccion.tipo1 * data.imprevistos,
      tipo2: costoConstruccion.tipo2 * data.imprevistos
    };

    const costoProduccionVivienda: Viviendas = {
      tipo1: terreno.tipo1 + costoConstruccion.tipo1 + costoFinanciero.tipo1 + comercial.tipo1 + marketing.tipo1 + gastosGenerales.tipo1 + utilidad.tipo1 + imprevistos.tipo1,
      tipo2: terreno.tipo2 + costoConstruccion.tipo2 + costoFinanciero.tipo2 + comercial.tipo2 + marketing.tipo2 + gastosGenerales.tipo2 + utilidad.tipo2 + imprevistos.tipo2
    };

    const precioVenta: Viviendas = {
      tipo1: 1750,
      tipo2: 2000
    };

    const deficit: Viviendas = {
      tipo1: precioVenta.tipo1 - costoProduccionVivienda.tipo1,
      tipo2: precioVenta.tipo2 - costoProduccionVivienda.tipo2
    };

    const totalPrecioVenta = precioVenta.tipo1 * data.nViviendas.tipo1 + precioVenta.tipo2 * data.nViviendas.tipo2;
    const totalCostoProduccion = costoProduccionVivienda.tipo1 * data.nViviendas.tipo1 + costoProduccionVivienda.tipo2 * data.nViviendas.tipo2;
    const margen = (totalPrecioVenta - totalCostoProduccion) / totalPrecioVenta;

    setResults({
      terreno,
      costoConstruccion,
      vivienda,
      urbanizacion,
      habilitacion,
      costoFinanciero,
      comercial,
      marketing,
      gastosGenerales,
      utilidad,
      imprevistos,
      costoProduccionVivienda,
      precioVenta,
      deficit,
      margen
    });
  };

  const handleInputChange = (category: keyof DataState, type: keyof Viviendas, value: string) => {
    setData(prevData => {
      // Verifica si prevData[category] es un objeto
      const categoryData = prevData[category];
      if (typeof categoryData === 'object' && categoryData !== null) {
        return {
          ...prevData,
          [category]: {
            ...categoryData,
            [type]: parseFloat(value) || 0
          }
        };
      }
      return prevData;
    });
  };

  const formatNumber = (num: number) => num.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

  return (
    <div className="p-4 bg-white-100 font-sans">
      <div className="max-w-7xl mx-auto bg-white rounded-lg overflow-hidden">
        <div className="bg-blue-500 text-white p-3 text-lg font-bold">
          ESCENARIO 1
        </div>
        <table className="w-full text-sm">
          <thead className="bg-blue-100">
            <tr>
              <th className="p-2 text-left">TIPOLOGÍA</th>
              <th className="p-2 text-center">cant</th>
              <th className="p-2 text-center">TIPO 1</th>
              <th className="p-2 text-center">TIPO 2</th>
              <th className="p-2 text-center">TOTAL</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(data).map(([key, value]) => (
              <tr key={key} className={parseInt(key) % 2 === 0 ? "bg-gray-50" : ""}>
                <td className="p-2 font-semibold">{key.toUpperCase()}</td>
                <td className="p-2 text-center">{typeof value === 'object' ? 'cant' : (value * 100).toFixed(2) + '%'}</td>
                <td className="p-2 text-center">
                  <input
                    type="number"
                    value={typeof value === 'object' ? value.tipo1 : value}
                    onChange={(e) => handleInputChange(key as keyof DataState, 'tipo1', e.target.value)}
                    className="w-full text-center"
                  />
                </td>
                <td className="p-2 text-center">
                  {typeof value === 'object' && (
                    <input
                      type="number"
                      value={value.tipo2}
                      onChange={(e) => handleInputChange(key as keyof DataState, 'tipo2', e.target.value)}
                      className="w-full text-center"
                    />
                  )}
                </td>
                <td className="p-2 text-center font-bold">
                  {typeof value === 'object' ? formatNumber(value.tipo1 + value.tipo2) : '-'}
                </td>
              </tr>
            ))}
            {results.costoProduccionVivienda && (
              <>
                <tr className="bg-gray-50">
                  <td className="p-2 font-semibold">D COSTO PRODUCCIÓN</td>
                  <td className="p-2 text-center">uf</td>
                  <td className="p-2 text-center">{results.costoProduccionVivienda.tipo1 ? formatNumber(results.costoProduccionVivienda.tipo1) : '-'}</td>
                  <td className="p-2 text-center">{results.costoProduccionVivienda.tipo2 ? formatNumber(results.costoProduccionVivienda.tipo2) : '-'}</td>
                  <td className="p-2 text-center font-bold">
                    {results.costoProduccionVivienda.tipo1 && results.costoProduccionVivienda.tipo2
                      ? formatNumber(results.costoProduccionVivienda.tipo1 * data.nViviendas.tipo1 + results.costoProduccionVivienda.tipo2 * data.nViviendas.tipo2)
                      : '-'}
                  </td>
                </tr>
                <tr className="bg-teal-100">
                  <td className="p-2 font-semibold">A+B+C COSTO PRODUCCIÓN VIVIENDA</td>
                  <td className="p-2 text-center">uf</td>
                  <td className="p-2 text-center">{formatNumber(results.costoProduccionVivienda.tipo1)}</td>
                  <td className="p-2 text-center">{formatNumber(results.costoProduccionVivienda.tipo2)}</td>
                  <td className="p-2 text-center font-bold">
                    {formatNumber(results.costoProduccionVivienda.tipo1 * data.nViviendas.tipo1 + results.costoProduccionVivienda.tipo2 * data.nViviendas.tipo2)}
                  </td>
                </tr>
                <tr>
                  <td className="p-2 font-semibold">D PRECIO VENTA</td>
                  <td className="p-2 text-center">uf</td>
                  <td className="p-2 text-center">{results.precioVenta?.tipo1 ? formatNumber(results.precioVenta.tipo1) : '-'}</td>
                  <td className="p-2 text-center">{results.precioVenta?.tipo2 ? formatNumber(results.precioVenta.tipo2) : '-'}</td>
                  <td className="p-2 text-center font-bold">
                    {results.precioVenta?.tipo1 && results.precioVenta?.tipo2
                      ? formatNumber(results.precioVenta.tipo1 * data.nViviendas.tipo1 + results.precioVenta.tipo2 * data.nViviendas.tipo2)
                      : '-'}
                  </td>
                </tr>
                <tr className="bg-gray-50">
                  <td className="p-2 font-semibold">DÉFICIT</td>
                  <td className="p-2 text-center">uf</td>
                  <td className="p-2 text-center">{results.deficit?.tipo1 ? formatNumber(results.deficit.tipo1) : '-'}</td>
                  <td className="p-2 text-center">{results.deficit?.tipo2 ? formatNumber(results.deficit.tipo2) : '-'}</td>
                  <td className="p-2 text-center font-bold text-red-500">
                    {results.deficit?.tipo1 && results.deficit?.tipo2
                      ? formatNumber(results.deficit.tipo1 * data.nViviendas.tipo1 + results.deficit.tipo2 * data.nViviendas.tipo2)
                      : '-'}
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
        <div className="bg-blue-500 text-white p-2 flex justify-between">
          <span>MARGEN: {results.margen ? (results.margen * 100).toFixed(2) + '%' : '-'}</span>
          <span>TIR: 5%</span>
          <span>VAN: 5000</span>
        </div>
      </div>
    </div>
  );
};

export default RealEstateScenario1;
