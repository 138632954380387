import React from 'react';

// Definir los tipos para las variantes y tamaños del botón
interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'default' | 'destructive' | 'outline' | 'secondary' | 'ghost' | 'link';
  size?: 'default' | 'sm' | 'lg';
}

// Componente Button
export const Button: React.FC<ButtonProps> = ({
  className = '', 
  variant = 'default', 
  size = 'default', 
  ...props 
}) => {
  // Estilos base para el botón
  const baseStyle = 'inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none';
  
  // Estilos específicos para cada variante del botón
  const variantStyles: Record<string, string> = {
    default: 'bg-primary text-primary-foreground hover:bg-primary/90',
    destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
    outline: 'border border-input hover:bg-accent hover:text-accent-foreground',
    secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80',
    ghost: 'hover:bg-accent hover:text-accent-foreground',
    link: 'underline-offset-4 hover:underline text-primary',
  };

  // Estilos específicos para cada tamaño del botón
  const sizeStyles: Record<string, string> = {
    default: 'h-10 py-2 px-4',
    sm: 'h-9 px-3 rounded-md',
    lg: 'h-11 px-8 rounded-md',
  };

  // Combina los estilos base, variante y tamaño con las clases adicionales
  const classes = `${baseStyle} ${variantStyles[variant] || variantStyles['default']} ${sizeStyles[size] || sizeStyles['default']} ${className}`;

  return <button className={classes} {...props} />;
};
