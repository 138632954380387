import React, { useState } from 'react';
import { Button } from '../components/ui/button';

interface ContactFormProps {
  onClose: () => void;
}

const ContactForm: React.FC<ContactFormProps> = ({ onClose }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState<'idle' | 'sending' | 'success' | 'error'>('idle');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setStatus('sending');
//https://simagi.cl/php/mail/send_email.php', { // Cambiado aquí
    try {
      const response = await fetch('http://localhost:3000/php/mail/send_email.php', { // Cambiado aquí
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, message }),
      });

      if (response.ok) {
        setStatus('success');
        setTimeout(() => {
          onClose();
        }, 2000);
      } else {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Error al enviar el formulario');
      }
    } catch (error) {
      console.error('Error detallado:', error);
      setStatus('error');
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-xl max-w-md w-full relative">
        <button onClick={onClose} className="absolute top-4 right-4 text-gray-600 hover:text-gray-800 focus:outline-none">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <h2 className="text-3xl font-bold mb-6 text-center text-gray-700">Contáctanos</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">Nombre</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="mt-1 block w-full rounded-md border-2 border-gray-200 shadow-sm focus:border-teal-500 focus:ring-teal-500 focus:ring-opacity-50"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="mt-1 block w-full rounded-md border-2 border-gray-200 shadow-sm focus:border-teal-500 focus:ring-teal-500 focus:ring-opacity-50"
              required
            />
          </div>
          <div className="mb-6">
            <label htmlFor="message" className="block text-sm font-medium text-gray-700">Mensaje</label>
            <textarea
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="mt-1 block w-full rounded-md border-2 border-gray-200 shadow-sm focus:border-teal-500 focus:ring-teal-500 focus:ring-opacity-50"
              rows={4}
              required
            ></textarea>
          </div>
          <div className="flex justify-end space-x-3">
            <Button onClick={onClose} variant="outline" className="text-gray-600 hover:text-gray-800 border-gray-300 hover:border-gray-500">Cancelar</Button>
            <Button type="submit" className="bg-teal-500 hover:bg-teal-600 text-white" disabled={status === 'sending'}>
              {status === 'sending' ? 'Enviando...' : 'Enviar'}
            </Button>
          </div>
        </form>
        {status === 'success' && (
          <p className="mt-4 text-green-600 text-center">¡Mensaje enviado con éxito!</p>
        )}
        {status === 'error' && (
          <p className="mt-4 text-red-600 text-center">Error al enviar el mensaje. Por favor, inténtalo de nuevo.</p>
        )}
      </div>
    </div>
  );
};

export default ContactForm;
