import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const data = [
  { mes: 'M1', programado: 0, real: 0 },
  { mes: 'M2', programado: 2, real: 1 },
  { mes: 'M3', programado: 5, real: 3 },
  { mes: 'M4', programado: 10, real: 7 },
  { mes: 'M5', programado: 18, real: 12 },
  { mes: 'M6', programado: 28, real: 20 },
  { mes: 'M7', programado: 40, real: 30 },
  { mes: 'M8', programado: 55, real: 42 },
  { mes: 'M9', programado: 70, real: 55 },
  { mes: 'M10', programado: 82, real: 65, proyeccion: 65 },
  { mes: 'M11', programado: 90, proyeccion: 73 },
  { mes: 'M12', programado: 95, proyeccion: 81 },
  { mes: 'M13', programado: 98, proyeccion: 88 },
  { mes: 'M14', programado: 99, proyeccion: 93 },
  { mes: 'M15', programado: 100, proyeccion: 97 },
  { mes: 'M16', proyeccion: 99 },
  { mes: 'M17', proyeccion: 100 },
  { mes: 'M18', proyeccion: 100 },
];

const CurvaSModerna = () => {
  return (
    <div style={{ width: '100%', maxWidth: 800, margin: 'auto', paddingBottom: 20 }}>
      <h2 style={{ textAlign: 'center', fontFamily: 'Arial, sans-serif', color: '#333' }}>Curva S de Avance de Proyecto</h2>
      <ResponsiveContainer width="100%" aspect={2}>
        <LineChart
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
          <XAxis dataKey="mes" stroke="#888" />
          <YAxis stroke="#888" tickFormatter={(value) => `${value}%`} />
          <Tooltip formatter={(value) => `${value}%`} />
          <Line type="monotone" dataKey="programado" stroke="#ff6b6b" strokeWidth={2} dot={{ r: 4 }} name="Programado" />
          <Line type="monotone" dataKey="real" stroke="#4ecdc4" strokeWidth={2} dot={{ r: 4 }} name="Real" />
          <Line type="monotone" dataKey="proyeccion" stroke="#333" strokeWidth={2} strokeDasharray="5 5" dot={{ r: 4 }} name="Proyección" />
        </LineChart>
      </ResponsiveContainer>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20, fontFamily: 'Arial, sans-serif' }}>
        <div style={{ marginRight: 20, display: 'flex', alignItems: 'center' }}>
          <div style={{ width: 20, height: 3, backgroundColor: '#ff6b6b', marginRight: 5 }}></div>
          <span>Programado</span>
        </div>
        <div style={{ marginRight: 20, display: 'flex', alignItems: 'center' }}>
          <div style={{ width: 20, height: 3, backgroundColor: '#4ecdc4', marginRight: 5 }}></div>
          <span>Real</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ width: 20, height: 3, backgroundColor: '#333', marginRight: 5, borderTop: '1px dashed #333' }}></div>
          <span>Proyección a término</span>
        </div>
      </div>
    </div>
  );
};

export default CurvaSModerna;
