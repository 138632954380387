import React from 'react';

interface Task {
  name: string;
  status: 'inProgress' | 'pending' | 'warning' | 'success' | 'danger';
}

interface TaskItemProps {
  task: string;
  status: 'inProgress' | 'pending' | 'warning' | 'success' | 'danger';
}

const TaskItem: React.FC<TaskItemProps> = ({ task, status }) => {
  const getStatusColor = () => {
    switch (status) {
      case 'inProgress': return 'bg-blue-200 text-blue-800';
      case 'pending': return 'bg-gray-200 text-gray-800';
      case 'warning': return 'bg-yellow-200 text-yellow-800';
      case 'success': return 'bg-green-200 text-green-800';
      case 'danger': return 'bg-red-200 text-red-800';
      default: return 'bg-gray-200 text-gray-800';
    }
  };

  return (
    <div className={`p-2 mb-2 rounded ${getStatusColor()}`}>
      {task}
    </div>
  );
};

interface TaskColumnProps {
  title: string;
  tasks: Task[];
}

const TaskColumn: React.FC<TaskColumnProps> = ({ title, tasks }) => (
  <div className="w-full md:w-64 bg-white rounded-lg shadow-md p-4 mb-4 md:mb-0 md:mr-4">
    <h3 className="text-lg font-semibold mb-4">{title}</h3>
    <button className="w-full mb-4 p-2 border border-blue-500 text-blue-500 rounded hover:bg-blue-100 transition-colors duration-200">
      + Agregar Tarea
    </button>
    {tasks.map((task, index) => (
      <TaskItem key={index} task={task.name} status={task.status} />
    ))}
  </div>
);

const PlanningPanel: React.FC = () => {
  const columns: TaskColumnProps[] = [
    {
      title: 'SERVIU',
      tasks: [
        { name: 'DESARROLLO PAV Y AII', status: 'inProgress' },
        { name: 'PRIMER INGRESO', status: 'inProgress' },
        { name: 'OBSERVACIONES 1', status: 'warning' },
        { name: 'SEGUNDO INGRESO', status: 'pending' },
        { name: 'OBSERVACIONES 2', status: 'pending' },
        { name: 'APROBACIÓN', status: 'pending' },
      ]
    },
    {
      title: 'SANITARIO',
      tasks: [
        { name: 'DESARROLLO AP - AS', status: 'inProgress' },
        { name: 'PRIMER INGRESO', status: 'inProgress' },
        { name: 'OBSERVACIONES 1', status: 'success' },
        { name: 'SEGUNDO INGRESO', status: 'pending' },
        { name: 'OBSERVACIONES 2', status: 'pending' },
        { name: 'APROBACIÓN', status: 'pending' },
      ]
    },
    {
      title: 'DGA',
      tasks: [
        { name: 'COTIZACIÓN', status: 'inProgress' },
        { name: 'CONTRATACIÓN', status: 'inProgress' },
        { name: 'DESARROLLO PROYECTO', status: 'success' },
        { name: 'PRIMER INGRESO DGA (O DELEGACIÓN)', status: 'pending' },
        { name: 'ADMISIBILIDAD', status: 'pending' },
        { name: 'PUBLICACIONES', status: 'pending' },
        { name: 'OBSERVACIONES 1', status: 'pending' },
        { name: 'SEGUNDO INGRESO', status: 'pending' },
        { name: 'APROBACIÓN', status: 'pending' },
      ]
    },
    {
      title: 'IMIV',
      tasks: [
        { name: 'COTIZACIÓN', status: 'inProgress' },
        { name: 'CONTRATACIÓN', status: 'inProgress' },
        { name: 'MEDICIONES', status: 'danger' },
        { name: 'DESARROLLO INFORME', status: 'pending' },
        { name: 'PRIMER INGRESO', status: 'pending' },
        { name: 'OBSERVACIONES 1', status: 'pending' },
        { name: 'SEGUNDO INGRESO', status: 'pending' },
        { name: 'APROBACIÓN', status: 'pending' },
      ]
    },
    {
      title: 'HABILITACIÓN',
      tasks: [
        { name: 'DESARROLLO HABILITACIÓN', status: 'inProgress' },
        { name: 'REVISIÓN HABILITACIÓN', status: 'inProgress' },
        { name: 'APROBACIÓN HABILITACIÓN', status: 'warning' },
      ]
    },
    {
      title: 'DECLARACIÓN IMPACTO AMBIENTAL',
      tasks: [
        { name: 'COTIZACIÓN', status: 'inProgress' },
        { name: 'CONTRATACIÓN', status: 'inProgress' },
        { name: 'DESARROLLO LÍNEA BASE', status: 'success' },
        { name: 'DESARROLLO DIA', status: 'success' },
        { name: 'INGRESO SEA', status: 'pending' },
        { name: 'ADMISIBILIDAD', status: 'pending' },
        { name: 'ICSARA 1', status: 'pending' },
        { name: 'ADENDA 1', status: 'pending' },
        { name: 'ICSARA 2', status: 'pending' },
        { name: 'ADENDA 2', status: 'pending' },
        { name: 'ICE', status: 'pending' },
        { name: 'RCA', status: 'pending' },
      ]
    },
  ];
  return (
    <div className="bg-white-100 min-h-screen p-4">
      <div className="flex flex-col md:flex-row md:flex-nowrap md:overflow-x-auto">
        {columns.map((column, index) => (
          <TaskColumn key={index} title={column.title} tasks={column.tasks} />
        ))}
      </div>
    </div>
  );
};  

export default PlanningPanel;