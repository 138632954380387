import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ResetPassword: React.FC = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState<string>('');
    const [success, setSuccess] = useState<string>('');
    const [token, setToken] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false); // Estado de carga
    
    const navigate = useNavigate();

    useEffect(() => {
        // Obtener el token desde la URL
        const urlParams = new URLSearchParams(window.location.search);
        const tokenParam = urlParams.get('token');
        if (tokenParam) {
            setToken(tokenParam);
        } else {
            setError('Token no proporcionado.');
        }
    }, []);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        // Validar que las contraseñas coinciden
        if (password !== confirmPassword) {
            setError('Las contraseñas no coinciden.');
            setSuccess('');
            return;
        }

        try {
            const response = await fetch('http://localhost:3000/php/pswd/reset_password.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token, password }),
            });

            const responseText = await response.text();
            if (responseText.trim() === '') {
                setError('La respuesta del servidor está vacía.');
                setSuccess('');
                return;
            }

            try {
                const data = JSON.parse(responseText);

                if (data.success) {
                    setSuccess(data.message || 'Contraseña restablecida con éxito.');
                    setError('');
                    setTimeout(() => navigate('/mvp'), 2000); // Redirige después de 2 segundos
                } else {
                    setError(data.error || 'Error al restablecer la contraseña.');
                    setSuccess('');
                }
            } catch (jsonError) {
                console.error('Error al parsear JSON:', jsonError);
                setError('Error en la respuesta del servidor: ' + responseText);
                setSuccess('');
            }
        } catch (error) {
            console.error('Restablecimiento de contraseña fallido:', error);
            setError(`Ocurrió un error durante el restablecimiento. ${error}`);
            setSuccess('');
        }
    };

    return (
        <div className="login-container">
            <div className="login-box">
                <div className="login-logo"></div>
                <form onSubmit={handleSubmit}>
                    <div className="input-container">
                        <i className="input-icon fas fa-lock"></i>
                        <input
                            type="password"
                            placeholder="Nueva Contraseña"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="login-input"
                            required
                        />
                    </div>
                    <div className="input-container">
                        <i className="input-icon fas fa-lock"></i>
                        <input
                            type="password"
                            placeholder="Confirmar Contraseña"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            className="login-input"
                            required
                        />
                    </div>
                    <button type="submit" className='submit-button mb-5' disabled={loading}>
                        {loading ? 'Actualizando datos...' : 'Restablecer contraseña'}
                    </button>
                    {success && <p className="success-message">{success}</p>}
                    {error && <p className="error-message">{error}</p>}
                </form>
            </div>
            <div className="triangle"></div>
        </div>
    );
};

export default ResetPassword;
