import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip, Legend, TooltipProps } from 'recharts';

// Datos de ejemplo
const userData = [
  { name: 'Usuario 1', atrasadas: 20, pendientes: 0 },
  { name: 'Usuario 2', atrasadas: 40, pendientes: 30 },
  { name: 'Usuario 3', atrasadas: 0, pendientes: 10 },
  { name: 'Usuario 4', atrasadas: 0, pendientes: 15 },
  { name: 'Usuario 5', atrasadas: 5, pendientes: 0 },
  { name: 'Usuario 6', atrasadas: 20, pendientes: 15 },
  { name: 'Usuario 7', atrasadas: 15, pendientes: 5 },
  { name: 'Usuario 8', atrasadas: 0, pendientes: 20 },
  { name: 'Usuario 9', atrasadas: 10, pendientes: 10 },
  { name: 'Usuario 10', atrasadas: 0, pendientes: 25 },
];

const departmentData = [
  { name: 'Departamento 1', atrasadas: 80, pendientes: 20 },
  { name: 'Departamento 2', atrasadas: 60, pendientes: 80 },
  { name: 'Departamento 3', atrasadas: 0, pendientes: 40 },
  { name: 'Departamento 4', atrasadas: 0, pendientes: 40 },
  { name: 'Departamento 5', atrasadas: 0, pendientes: 0 },
];

// Define los tipos para CustomTooltip
interface CustomTooltipProps extends TooltipProps<any, any> {
  payload?: Array<{ value?: number; color?: string; name?: string }>;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const total = payload.reduce((sum, entry) => sum + (entry.value || 0), 0);

    return (
      <div className="bg-white p-2 border border-gray-300 rounded shadow text-sm">
        <p className="font-bold">{label}</p>
        {payload.map((entry, index) => (
          <p key={index} style={{ color: entry.color }}>
            {entry.name}: {entry.value}
          </p>
        ))}
        <p className="font-bold mt-1">Total: {total}</p>
      </div>
    );
  }
  return null;
};

const Dashboard = () => {
  return (
    <div className="flex flex-col w-full h-screen bg-white font-sans">
      <div className="w-full h-1/2 p-4">
        <h2 className="text-xl font-bold mb-4 text-center">Reporte Por Usuario</h2>
        <ResponsiveContainer width="100%" height="90%">
          <BarChart
            layout="vertical"
            data={userData}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            barSize={20}
          >
            <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
            <XAxis type="number" domain={[0, 100]} />
            <YAxis dataKey="name" type="category" width={80} />
            <Tooltip content={<CustomTooltip />} />
            <Legend verticalAlign="bottom" height={36} />
            <Bar dataKey="atrasadas" stackId="a" fill="#ff9999" name="Tareas Atrasadas" />
            <Bar dataKey="pendientes" stackId="a" fill="#66cccc" name="Seguimiento Pendiente" />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div className="w-full h-1/2 p-4">
        <h2 className="text-xl font-bold mb-4 text-center">Reporte Por Departamento</h2>
        <ResponsiveContainer width="100%" height="90%">
          <BarChart
            layout="vertical"
            data={departmentData}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            barSize={20}
          >
            <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
            <XAxis type="number" domain={[0, 150]} />
            <YAxis dataKey="name" type="category" width={120} />
            <Tooltip content={<CustomTooltip />} />
            <Legend verticalAlign="bottom" height={36} />
            <Bar dataKey="atrasadas" stackId="a" fill="#ff9999" name="Tareas Atrasadas" />
            <Bar dataKey="pendientes" stackId="a" fill="#66cccc" name="Seguimiento Pendiente" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default Dashboard;
