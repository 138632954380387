import React from 'react';

interface DialogProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  className?: string;
  width?: string;
  height?: string;
}

interface DialogContentProps {
  children: React.ReactNode;
  className?: string;
}

interface DialogTriggerProps {
  children: React.ReactNode;
  onClick: () => void;
}

export const DialogTrigger: React.FC<DialogTriggerProps> = ({ children, onClick }) => {
  return (
    <div onClick={onClick} style={{ display: 'inline-block', cursor: 'pointer' }}>
      {children}
    </div>
  );
};

export const Dialog: React.FC<DialogProps> = ({ isOpen, onClose, children, width = '100%', height = 'auto' }) => {
  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      style={{ margin: 0 }}
      onClick={onClose}
    >
      <div
        className="bg-white rounded shadow-lg overflow-hidden"
        style={{ width, height, maxWidth: '100vw', maxHeight: '100vh' }}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="w-full h-full overflow-auto">
          {children}
        </div>
      </div>
    </div>
  );
};

export const DialogHeader: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className="flex justify-between rounded items-center p-4 border-b">
    {children}
  </div>
);

export const DialogContent: React.FC<DialogContentProps> = ({ children, className }) => (
  <div className={`p-4 ${className}`}>
    {children}
  </div>
);

export const DialogTitle: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <h2 className="text-lg font-medium">{children}</h2>
);

export const DialogFooter: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className="flex justify-end p-4 border-t">
    {children}
  </div>
);